import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
  jsonHeaders,
  makeRequest,
} from './base'

export const getVendorsSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
}

export const getVendors = async () => {
  const url = `/api/vendors/`
  return await getApiRequest(url)
}

export const getVendor = async (id) => {
  const url = `/api/vendors/${id}/`
  return await getApiRequest(url)
}

export const updateVendor = async (id, data) => {
  const url = `/api/vendors/${id}/`
  return await putApiRequest(url, data)
}

export const createVendor = async (data) => {
  const url = `/api/vendors/`
  return await postApiRequest(url, data)
}

export const getVendorRoles = async () => {
  const url = `/api/vendors/people/roles/`
  return await getApiRequest(url)
}

export const getVendorPeople = async (id) => {
  const url = `/api/vendors/people/${id}/`
  return await getApiRequest(url)
}

export const createVendorPeople = async (id, data) => {
  const url = `/api/vendors/people/add/${id}/`
  return await postApiRequest(url, data)
}

export const toggleVendorMainPOC = async (id) => {
  const url = `/api/vendors/people/toggle-main-contact/${id}/`
  return await postApiRequest(url)
}

export const getVendorEvents = async (id) => {
  const url = `/api/vendors/events/${id}/`
  return await getApiRequest(url)
}

export const getVendorPastEvents = async (id) => {
  const url = `/api/vendors/events/past/${id}/`
  return await getApiRequest(url)
}

export const getVendorLinkSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
}

export const getVendorLinks = async (id) => {
  const url = `/api/vendors/links/${id}/`
  return await getApiRequest(url)
}

export const createVendorLink = async (id, data) => {
  const url = `/api/vendors/links/${id}/`
  return await postApiRequest(url, data)
}

export const deleteVendorLink = async (id) => {
  const url = `/api/vendors/link/${id}/`
  return await deleteApiRequest(url)
}

export const getVendorCommentSettings = {
  cacheTime: 10 * 60 * 1000,
  staleTime: 2 * 60 * 1000,
}

export const getVendorComments = async (id) => {
  const url = `/api/vendors/comments/${id}/`
  return await getApiRequest(url)
}

export const createVendorComment = async (id, data) => {
  const url = `/api/vendors/comments/${id}/`
  return await postApiRequest(url, data)
}

export const getVendorFileSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
}

export const getVendorFiles = async (id) => {
  const url = `/api/vendors/files/${id}/`
  return await getApiRequest(url)
}

export const createVendorFile = async (id, data) => {
  const url = `/api/vendors/files/${id}/`
  const headers = await jsonHeaders(true)
  headers.delete('Content-Type')
  const resp = await makeRequest(url, "POST", headers, data)
  return resp.json()
}

export const deleteVendorFile = async (id) => {
  const url = `/api/vendors/file/${id}/`
  return await deleteApiRequest(url)
}

export const getVendorWireInfoSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
}

export const getVendorWireInfo = async (id) => {
  const url = `/api/vendors/wireinfos/${id}/`
  return await getApiRequest(url)
}

export const createVendorWireInfo = async (id, data) => {
  const url = `/api/vendors/wireinfos/${id}/`
  return await postApiRequest(url, data)
}

export const deleteVendorWireInfo = async (id, data) => {
  const url = `/api/vendors/wireinfos/${id}/`
  return await deleteApiRequest(url, data)
}

export const getVendorArtistSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
}
export const getVendorArtists = async (id) => {
  const url = `/api/vendors/artists/${id}/`
  return await getApiRequest(url)
}

export const createVendorArtist = async (id, data) => {
  const url = `/api/vendors/artists/add/${id}/`
  return await postApiRequest(url, data)
}

export const deleteVendorArtist = async (id, data) => {
  const url = `/api/vendors/artists/delete/${id}/`
  return await deleteApiRequest(url, data)
}

