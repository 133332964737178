export const TwitterHandle = (props) => {
  // Given a Twitter URL create the display `@handle` but link to the URL
  const { url } = props

  if (!url) {
    return <></>
  }

  let handle = url.replace("https://twitter.com/", "")
  handle = "@" + handle

  console.log("TwitterHandle: ", handle)
  console.log("URL: ", url)

  return (
    <a href={url} target="_blank" className="text-gray-500 underline hover:text-blue-500">
      {handle}
    </a>
  )
}
