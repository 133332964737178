import React, { useEffect, useRef, useState } from "react"
import { amountDisplay} from "@/utils/money"
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';
import { FIELD_UPDATE_ANIMATION_TIME } from "../views/events/EventEditOffer";

export const WalkoutPot = ({walkout_potential, showHeader, toggleHeader}) => {

  const walkoutPotentialRef = useRef(walkout_potential)
  const [isChanged, setIsChanged] = useState(false)

//  useEffect(() => {
//    if(walkoutPotentialRef.current !== walkout_potential) {
//      setIsChanged(true)
//      walkoutPotentialRef.current = walkout_potential
//    }
//  }, [walkout_potential])
//
//useEffect(() => {
//  if(isChanged) {
//    setTimeout(() => {
//      setIsChanged(false)
//    }, FIELD_UPDATE_ANIMATION_TIME)
//  }
//}, [isChanged])

  return (
    <div className={`rounded overflow-hidden z-[10001] w-88 fixed right-4 ${showHeader ? 'top-48' : ''} ${!isChanged ? 'ring ring-ku-red/50' : ''}`}>
      <div className={`bg-ku-blue p-2 shadow-md ${isChanged ? 'glow-text-gradient-border' : ''}`}>
        <div className="flex items-center">
        <button
          className="p-2 rounded-full bg-gray-200 focus:outline-none"
          onClick={toggleHeader}
        >
        {showHeader ? (
            <FiMinimize2 className="text-gray-600" size={12} />
          ) : (
            <FiMaximize2 className="text-gray-600" size={12} />
          )}
        </button>
      <div className="pl-2 w-88">
        <p className={`pl-2 font-mono text-lg font-semibold text-ku-yellow shadow drop-shadow-ku-red whitespace-nowrap ${isChanged ? 'glow-text-mid' : ''}`}>Walkout Potential: {amountDisplay(walkout_potential)}</p>
      </div>
      </div>
        </div>   
    </div>
    
  );
};

