import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import { Alert } from "@/components/Alert";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getEventsList, getEventsSettings } from "@/queries/events";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import EventState from "@/components/EventState";
import Button from "@/components/Button";
import { usePerms } from "@/components/Auth";
import { usePageTitle } from "@/utils/pagetitle";
import { EventCard } from "@/components/Dashboard/EventCard/EventCard";
import { Carousel } from "@/components/Dashboard/Carousel/Carousel";

export const EventsHome = () => {
  const events = useQuery(
    ["events", "list"],
    () => getEventsList(),
    getEventsSettings
  );
  const navigate = useNavigate();
  const { perms } = usePerms();
  usePageTitle(
    events.isSuccess
      ? `/c${String.fromCharCode(92)}events`
      : `/c${String.fromCharCode(92)}ave_good...`
  );
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    if (events.isLoading) {
      setIsFadingOut(false);
    } else if (events.isSuccess) {
      setTimeout(() => {
        setIsFadingOut(true);
      }, 1000); // Adjust the delay as needed
    }
  }, [events.isLoading, events.isSuccess]);

  return (
    <>
      {events.isLoading && (
        <div
          className={`fixed inset-0 z-50 flex items-center justify-center bg-welcome bg-cover bg-center transition-opacity duration-1000 ${
            isFadingOut ? "opacity-0" : "opacity-100"
          }`}
          style={{
            backgroundImage:
              "url('/static/images/welcome_to_cave_loading.jpg')",
          }}
        >
          <div className="animate-pulse text-white text-sm mt-4">
            <br /> <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br /> <br />
            <br />
            /c\
          </div>
        </div>
      )}
      <div
        className={`transition-opacity duration-1000 ${
          events.isLoading && !isFadingOut ? "opacity-0" : "opacity-100"
        }`}
      ></div>

      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <div className="w-full flex justify-between xs:flex-col sm:flex-row md:flex-row items-center xs:gap-[1rem]">
          <BreadCrumbs links={[{ text: "Events", url: "/events" }]} />
          <Button onClick={() => navigate("/events/list")}>Event List</Button>
          <span>
            {perms.data.create_events && (
              <Button onClick={() => navigate("/events/add")}>Add Event</Button>
            )}
          </span>
        </div>

        <div className="my-2 h-auto w-auto shadow-md sm:rounded-lg ">
          {events.isSuccess && (
            <div className="flex flex-wrap">
              {events.data.map((event, index) => (
                <div className="bg-white dark:bg-[#181818] xs:mx-auto">
                  <EventCard
                    eventName={event.name}
                    venue={event.venue_name}
                    date={event.date}
                    status={event.state}
                    onClick={() => {}}
                    backgroundImage={
                      event.event_image_url
                        ? event.event_image_url
                        : "/static/images/cave_no_image_event.png"
                    }
                    url={`/events/${event.id}`}
                    key={`non-draft-${event.id}`}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
