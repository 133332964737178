import React, { useState } from "react"
import { Header } from "@/components/Header"
import * as yup from "yup"
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FormBuilder, Input, TextInput } from "@/components/Form"
import { useUser } from "@/components/Auth"
import FileUploader from "@/components/Files/FileUploader"
import { createReq, createReqFile } from '@/queries/reqs'
import { BreadCrumbs } from '@/components/BreadCrumbs'

export const ReqAdd = () => {
  const { user } = useUser()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [file, setFile] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const addSchema = yup.object().shape({
    title: yup.string().required(),
    description: yup.string().required(),
  })

  const createReqMutation = useMutation(createReq, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["reqs"])
      if (file) {
        createReqFileMutation.mutate({ id: data.id, file })
      } else {
        setIsSubmitting(false)
        navigate(`/reqs/${data.id}`)
      }
    },
    onError: (error) => {
      console.error("Error creating request:", error)
      setIsSubmitting(false)
    }
  })

  const createReqFileMutation = useMutation(
    ({ id, file }) => {
      const formData = new FormData()
      formData.append("file", file)
      return createReqFile(id, formData)
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["reqs", "files", data.req_id])
        setIsSubmitting(false)
        navigate(`/reqs/${data.req_id}`)
      },
      onError: (error) => {
        console.error("Error uploading file:", error)
        setIsSubmitting(false)
      }
    }
  )

  const handleSubmit = (formData) => {
    setIsSubmitting(true)
    createReqMutation.mutate(formData)
  }

  return (
    <>
      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0">
        <BreadCrumbs links={[{ text: "IT Requests", url: "/reqs" }, { text: "New Request" }]} />

        <div className="py-6 md:flex md:items-center md:justify-between">
          <div className="max-w-4xl mx-auto p-5 bg-white shadow-md rounded-lg p-4 mb-4">
            <h2 className="text-2xl font-bold mb-4">New Request</h2>
            <p>Probably some text will go here about what to request and what not to do</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <div className="">
              <FormBuilder onSubmit={handleSubmit} schema={addSchema}>
                <div className="bg-white shadow-md rounded-lg p-4 mb-4 cursor-text">
                  <Input 
                    name="title" 
                    autoFocus={true}
                    helpText="Please enter a short summary"
                  /> 
                  <TextInput
                    name="description" 
                    rows={9}
                  />
                  <button 
                    className="mt-2 w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" 
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : 'Make Request'}
                  </button>
                </div>
              </FormBuilder>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReqAdd
