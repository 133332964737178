import { useState } from 'react'
import Button from '@/components/Button'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { TrashIcon } from '@heroicons/react/24/outline'

const VendorArtists = (props) => {
  const { vendor, vendor_id, artist, deleteQuery, editable } = props
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const deleteMutation = useMutation({
    mutationFn: (id, data) => {
      console.log("---> delete artist association with vendor")
      console.dir(id, data)
      return deleteQuery(id, data)
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["vendor"])
      queryClient.invalidateQueries(["vendor", "artists", id])
      queryClient.invalidateQueries(["artist", "vendors"])
      setDeleteConfirm(false)
    }
  })
  let secondField = (
    <td className="p-4 border border-1">
      {artist.description}
      <TrashIcon
        className="h-6 w-6 float-right text-gray-400 hover:text-red-400"
        onClick={() => setDeleteConfirm(true)}
      />
    </td>
  )

  if (deleteConfirm) {
    secondField = (
      <td className="p-4 border border-1 text-gray-800 bg-red-300">
        <span>Are you sure you want to delete this artist?</span>
        <div className="float-right">
          <button
            className="mr-4 rounded bg-red-800 hover:bg-red-700 px-4 py-1 text-white"
            onClick={() => deleteMutation.mutate(artist.id)}
          >
            Yes
          </button>
          <button
            className="mr-4 rounded bg-gray-500 hover:bg-gray-400 px-4 py-1 text-white"
            onClick={() => setDeleteConfirm(false)}
          >
            No
          </button>
        </div>
      </td>
    )
  }

  const goToArtist = () => {
    navigate(`/artists/${artist.artists_id}`)
  }

  return (
    <tr>
      <td
        className="p-4 hover:bg-blue-100 border border-1 hover:border hover:border-1 hover:border-blue-200 w-1/2"
        onClick={goToArtist}
      >


        <span className="text-sm text-blue-500 hover:text-blue-700">
          {artist.name}
        </span>
      </td>
      {secondField}
    </tr>
  )
}

export const ArtistsDisplay = (props) => {
  const { vendor, vendor_id, artists, addURL, deleteQuery, editable } = props
  const navigate = useNavigate()

  return (
    <div className="mb-6">
      <div className="w-full flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold dark:text-gray-200">Artists</h3>
        <span>
          {editable && (
            <Button onClick={() => navigate(addURL)}>Add Artists</Button>
          )}
        </span>
      </div>
      <div className="my-2">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Artists
              </th>
              <th scope="col" className="px-6 py-3">
                Description
              </th>
            </tr>
          </thead>
          <tbody>
            {artists.isLoading && (
              <tr>
                <td colSpan="2" className="px-6 py-3 text-center text-sm text-gray-500 dark:text-gray-400">
                  Loading...
                </td>
              </tr>
            )}
            {artists.isSuccess && artists.data.map((artist, index) => (
              <VendorArtists
                key={index}
                artist={artist}
                deleteQuery={deleteQuery}
                editable={editable}
              />
            ))}
            {artists.isSuccess && !artists.data.length && (
              <tr>
                <td colSpan="2" className="px-6 py-3 text-center text-sm text-gray-500 dark:text-gray-400">
                  <em>No artists found</em>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ArtistsDisplay
