

export const stateChoices = [
  { value: 'draft', label: 'Draft' },
  { value: 'hold', label: 'Hold' },
  { value: 'offer', label: 'Offer' },
  { value: 'canceled', label: 'Canceled' },
  { value: 'postponed', label: 'Postponed' },
  { value: 'confirmed', label: 'Confirmed' },
]


export const holdChoices = [
  { value: 'draft', label: 'Draft' },
  { value: 'hold', label: 'Hold' },
]
