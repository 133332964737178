import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import { getEventsBBList, getEventsSettings } from "@/queries/events";
import { PeopleIcon } from "@/components/People/PeopleIcon";

const BulletinItem = ({
  dark,
  msg,
  event_id,
  msgdate,
  buyer,
  buyer_id,
  buyer_image_url,
}) => {
  return (
    <div
      className={` ${
        dark ? "bg-[#F4F2E9] dark:bg-gray-700" : "bg-white dark:bg-gray-800"
      } h-24 w-full p-5 flex flex-row justify-between items-center`}
    >
      <div className="flex flex-row gap-4 items-center">
        <div className="bg-[#B3B3B3] dark:bg-gray-600 rounded-[40px] h-12 w-12">
          <a target="_blank" href={`/people/${buyer_id}`}>
            <PeopleIcon imageUrl={buyer_image_url} />
          </a>
        </div>
        <a target="_blank" href={`/events/${event_id}`}>
          <p className="text-gray-500 dark:text-gray-300 font-bold text-sm">
            {msg}
          </p>
          <p className="text-gray-500 dark:text-gray-400 text-sm">{msgdate}</p>
        </a>
      </div>
    </div>
  );
};

const BulletinList = ({ data }) => {
  return (
    <div>
      {data.map((item, index) => (
        <BulletinItem
          key={item.id}
          dark={index % 2 === 0}
          msg={`${item.name}@${item.venue_name}`}
          msgdate={item.date}
          buyer={item.buyer}
          buyer_id={item.buyer_id}
          buyer_image_url={item.buyer_image_url}
          event_id={item.id}
        />
      ))}
    </div>
  );
};

export const BulletinBoard = () => {
  const { data, error, isLoading } = useQuery(["bb_events"], getEventsBBList, {
    retry: true,
    refetchOnWindowFocus: true,
  });

  if (isLoading) {
    return (
      <div className="justify-center items-center text-white">
        <div>(&#47;c&#92;)</div>
      </div>
    );
  }

  if (error) {
    console.error("Error fetching events: ", error);
    return <div>Error loading events</div>;
  }
  return (
    <div className="h-fit bg-[#F4F2E9] w-full rounded-xl flex flex-col justify-between overflow-hidden">
      <div className="h-10 dark:bg-cave-gray-600 bg-cave-gray-100 px-5 flex items-center justify-start">
        <p className="dark:text-gray-50 text-gray-500 font-bold">
          Bulletin Board
        </p>
      </div>
      <div className="max-h-96 overflow-y-auto">
        {data && data.length > 0 ? (
          <div>
            <BulletinList data={data} />
          </div>
        ) : (
          <div>No events</div>
        )}
      </div>
      <div className="h-10 dark:bg-cave-gray-600 bg-cave-gray-100 flex justify-between items-center w-100 pr-24 pl-24">
        <a
          href="https://www.facebook.com/mammothlive"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookF className="text-xl dark:text-gray-50 text-gray-500" />
        </a>

        {/* add .png here */}
        <a
          href="https://www.mammothlive.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
       <img
         src="/static/images/mammoth-logo-sm-trans-black.png"
         className="h-6 w-auto dark:hidden"
       />
       <img
         src="/static/images/mammoth-logo-sm-trans-white.png"
         className="hidden h-6 w-auto dark:block"
       />
        </a>
        <a
          href="https://www.instagram.com/mammothlive/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram className="text-xl dark:text-gray-50 text-gray-500" />
        </a>
        <a
          href="https://twitter.com/MammothLive"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter className="text-xl dark:text-gray-50 text-gray-500" />
        </a>
      </div>
    </div>
  );
};
