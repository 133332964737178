import React, { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

export const SearchBar = ({ width = "200px", handleSearch }) => {
  const [search, setSearch] = useState("");

  const handleLocalSearch = (e) => {
    setSearch(e.target.value);
    handleSearch && handleSearch(e.target.value);
  };

  return (
    <div
      style={{ width }}
      className="flex items-center border border-gray-300 rounded px-3 py-2 shadow-sm bg-white"
    >
      <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
      <input
        type="text"
        placeholder="Search Table..."
        className="ml-2 w-full bg-transparent outline-none"
        value={search}
        onChange={handleLocalSearch}
      />
    </div>
  );
};

/////////////////

// import React, { useState } from "react";
// import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

// export const SearchBar = ({ width = "200px", handleSearch }) => {
//   const [search, setSearch] = useState("");

//   const handleLocalSearch = (e) => {
//     setSearch(e.target.value);
//     handleSearch && handleSearch(e.target.value);
//   };

//   return (
//     <div
//       style={{ width: width }}
//       className={`flex items-center border border-gray-300 rounded px-3 py-2 shadow-sm bg-white`}
//     >
//       <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
//       <input
//         type="text"
//         placeholder="Search Table..."
//         className="ml-2 w-full bg-transparent outline-none"
//         value={search}
//         onChange={handleLocalSearch}
//       />
//     </div>
//   );
// };
