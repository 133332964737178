import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import * as yup from "yup"
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { FormBuilder, Input, TextInput } from "@/components/Form"
import { getPerson, updatePerson } from "@/queries/people"
import { BreadCrumbs } from '@/components/BreadCrumbs'
import { usePageTitle } from '@/utils/pagetitle';

export const PeopleEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const person = useQuery(["people-detail", id], () => getPerson(id))

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> updating person")
      console.dir(data)

      return updatePerson(id, data)
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["people"])
      queryClient.invalidateQueries(["people-detail"])
      navigate(`/people/${id}`)
    }
  })

  usePageTitle(person.isSuccess ? `/c${String.fromCharCode(92)}${person.data.first_name}` : `/c${String.fromCharCode(92)}ave_good...`);


  if (person.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }

  const updateSchema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    preferred_name: yup.string().required(),
    company_name: yup.string(),
    office: yup.string(),
    job_title: yup.string(),
    description: yup.string(),
    website_url: yup.string().url(),
    email: yup.string().email(),
    secondary_email: yup.string().email(),
    phone: yup.string(),
    address_line_1: yup.string(),
    address_line_2: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipcode: yup.string(),
  })

  let values = {
    first_name: person.data.first_name || "",
    last_name: person.data.last_name || "",
    preferred_name: person.data.preferred_name || "",
    company_name: person.data.company_name || "",
    office: person.data.office || "",
    job_title: person.data.job_title || "",
    description: person.data.description || "",
    website_url: person.data.website_url || "",
    email: person.data.email || "",
    secondary_email: person.data.secondary_email || "",
    phone: person.data.phone || "",
    address_line_1: person.data.address_line_1 || "",
    address_line_2: person.data.address_line_2 || "",
    city: person.data.city || "",
    state: person.data.state || "",
    zipcode: person.data.zipcode || "",
  }

  const handleSubmit = (data) => {
    console.log("In submit")
    console.dir(data)
    mutation.mutate(data)
  }

  let fullName = `${person.data.first_name} ${person.data.last_name}`
  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs links={[{ text: "People", url: "/people" }, { text: fullName }]} />

        <h3 className="text-xl font-bold mb-4 dark:text-white">Edit - {fullName}</h3>
        <div className="py-6 min-w-full">
          <FormBuilder defaultValues={values} onSubmit={(data) => handleSubmit(data)} schema={updateSchema}>
            <Input
              name="first_name"
              label="First Name"
              autoFocus={true}
            />
            <Input
              name="last_name"
              label="Last Name"
            />
            <Input
              name="preferred_name"
              label="Preferred Name"
            />
            <Input
              name="company_name"
              label="Company Name"
            />
            <Input
              name="job_title"
              label="Job Title"
            />
            <Input
              name="office"
              label="Office"
            />
            <Input
              name="email"
              label="Email"
            />
            <Input
              name="secondary_email"
              label="Secondary Email"
            />
            <Input
              name="phone"
              label="Phone"
            />
            <TextInput
              name="description"
              label="Description"
            />
            <Input
              name="website_url"
              label="Website URL"
            />
            <Input
              name="address_line_1"
              label="Address 1"
            />
            <Input
              name="address_line_2"
              label="Address 2"
            />
            <Input
              name="city"
              label="City"
            />
            <Input
              name="state"
              label="State"
            />
            <Input
              name="zipcode"
              label="Zipcode"
            />
            <div className="flex-inline float-right mb-12">
              <button
                type="button"
                onClick={() => navigate(`/people/${artist.data.id}`)}
                className="ml-4 mr-4 float-right rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                Cancel
              </button>
              <button type="submit" className="rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white">Save</button>
            </div>
          </FormBuilder>
        </div>
      </div>
    </>
  );
};
