import dayjs from "dayjs";
import React, { useState, useEffect, useRef } from "react";
import { Header } from "@/components/Header";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  getEventsList,
  getEventsSettings,
  getEventRoles,
  getEventPeople,
} from "@/queries/events";
import { getStaff, getPeopleSettings } from "@/queries/people";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import EventState from "@/components/EventState";
import Button from "@/components/Button";
import { usePerms } from "@/components/Auth";
import { usePageTitle } from "@/utils/pagetitle";
import { PeopleResult } from "@/views/events/EventPeopleAdd";

export const ExistingPeopleResults = ({ selected, setSelected, search }) => {
  const [results, setResults] = useState(null);

  useEffect(() => {
    if (!search) return;

    const url = new URL(`${window.location.origin}/api/search/people/`);
    url.searchParams.append("q", search);

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setResults(data.hits);
      });
  }, [search]);

  if (!results) {
    return (
      <div className="w-full">
        <span className="text-center dark:text-white">
          {/* No similar people found */}
        </span>
      </div>
    );
  }

  return (
    <div>
      {results.map((person, index) => (
        <PeopleResult
          key={index}
          person={person}
          selected={selected}
          setSelected={(personID) => setSelected(personID, person.title)}
        />
      ))}
    </div>
  );
};


const SearchBar = ({
  width,
  handleSearch,
  setShowSearchResults,
  placeholder,
}) => {
  const [term, setTerm] = useState("");
  const inputRef = useRef(null);

  const onSearchChange = (e) => {
    setTerm(e.target.value);
    handleSearch(e.target.value);
    setShowSearchResults(true);
  };

  const resetSearch = () => {
    setTerm("");
    handleSearch("");
    setShowSearchResults(false);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        resetSearch();
      }
    };

    // Attach event listener to the window
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <input
      type="text"
      value={term}
      onChange={onSearchChange}
      placeholder={placeholder}
      className={`border border-gray-300 rounded-md p-2 w-${width}`}
      ref={inputRef}
    />
  );
};


import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createEventPeople } from "@/queries/events";

const EventRow = ({ event, showSelectMenu, roles = [] }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [search, setSearch] = useState("");
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");
  const [showAddPersonButton, setShowAddPersonButton] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(true);
  const [showRoleSelect, setShowRoleSelect] = useState(false); // New state for showing/hiding Role select menu
  const [hovered, setHovered] = useState(false);
  const [selectedPersonName, setSelectedPersonName] = useState('');

  const {
    data: eventPeople,
    isLoading,
    error,
  } = useQuery(["eventPeople", event.id], () => getEventPeople(event.id), {
    enabled: !!event.id,
  });

  const mutation = useMutation({
    mutationFn: (data) => createEventPeople(event.id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["people"]);
      queryClient.invalidateQueries(["event", event.id]);
      resetView();
    },
  });

  let eventDate = dayjs(event.date).format("MMMM D, YYYY");
  if (event.date === null) {
    eventDate = "TBD";
  }

  const goToEvent = () => window.open(`/events/${event.id}`, "_blank");

  const handlePersonSelect = (personID, personName) => {
    setSelectedPerson(personID);
    setSelectedPersonName(personName);
    setShowSearchResults(false);
    setShowAddPersonButton(false);
    setShowRoleSelect(true); // Show Role select menu when a person is selected
  };

  const handleRoleSelect = (e) => {
    setSelectedRole(e.target.value);
    setShowAddPersonButton(true);
  };

  // const handleAddPerson = () => {
  //   if (selectedPerson && selectedRole) {
  //     mutation.mutate({ people_id: selectedPerson, role_id: selectedRole });
  //   }
  // };

  const handleAddPerson = () => {
    if (selectedPerson && selectedRole) {
      mutation.mutate(
        { people_id: selectedPerson, role_id: selectedRole },
        {
          onSuccess: () => {
            // Invalidate and refetch the relevant queries
            queryClient.invalidateQueries(["eventPeople", event.id]);
            queryClient.invalidateQueries(["people"]);
            resetView();
          },
        }
      );
    }
  };

  // Function to reset the state when Escape key is pressed
  const resetView = () => {
    setSelectedPerson(null);
    setSelectedPersonName("");
    setSelectedRole("");
    setSearch("");
    setShowSearchResults(true);
    setShowAddPersonButton(false);
    setShowRoleSelect(false); // Hide Role select menu
  };


  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [boxHeight, setBoxHeight] = useState('40px');
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      setBoxHeight(isOpen || isHovered ? `${contentHeight}px` : '40px');
    }
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        resetView(); // Reset the view when Escape is pressed
      }
    };

    // Attach event listener to the window
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };

  }, [isOpen, isHovered, eventPeople]);

  const handleMouseEnter = () => {
    if (!isOpen) setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (!isOpen) setIsHovered(false);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    setIsHovered(false);
  };

  const [searchResults, setSearchResults] = useState([]);

  return (
    <tr
      className="align-top bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
    >
      <th
        scope="row"
        className="cursor-pointer px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        onClick={goToEvent}
      >
        {event.name}
      </th>
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {eventDate}
      </th>
      <td className="px-6 py-4">
        {event.venue_name && (
          <>
            {event.venue_name} - {event.venue_city}, {event.venue_state}
          </>
        )}
      </td>
      <td className="inset-y-0">
        {showSelectMenu && (
          <div className="relative flex items-start gap-2">
            {isLoading && <div>Loading Staff...</div>}
            {error && <div>Error loading Staff</div>}
            {eventPeople && (
              <div
                className="bg-[#b8b8b8] p-2 rounded transition-all duration-500 ease-in-out cursor-pointer"
                onClick={toggleOpen}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                  padding: '2px',
                  margin: '2px',
                  maxHeight: boxHeight,
                  minHeight: '45px',
                  overflow: 'hidden',
                  transition: "max-height 0.5s ease-in-out",
                  width: "263px",
                  display: 'flex',
                  flexDirection: 'column',
                }}
                              >
                <div ref={contentRef} className="p-1">
                  <ul className="text-[black]">
                    {eventPeople.length > 0 ? (
                      eventPeople.map((person) => (
                        <li
                          key={person.id}
                          style={{
                            whiteSpace: "nowrap", 
                            overflow: "none",
                            textOverflow: "ellipsis",
                          }}
                        > 
                          {person.role_name}: {person.first_name} {person.last_name} 
                        </li>
                      ))
                    ) : (
                      <li>No Current Staffing</li>
                    )}
                  </ul>
                </div>
                </div>
                )}
            </div>
            )}
            </td>
            <td>
            <div className="flex-1">
              <SearchBar
                width="full"
                placeholder="Add Staff"
                handleSearch={(search) => setSearch(search)}
                setShowSearchResults={setShowSearchResults} // Pass the setShowSearchResults function to the SearchBar
              />
              {showSearchResults && (
                <ExistingPeopleResults
                  selected={selectedPerson}
                  setSelected={(personID, personName) =>
                    handlePersonSelect(personID, personName)
                  }
                  search={search}
                />
              )}
              {showRoleSelect && ( // Only show Role select if `showRoleSelect` is true
                <div className="mt-4">
                  <label className="block text-[black]">Select Role for {selectedPersonName}:</label>
                  <select
                    className="border border-gray-300 rounded-md p-2 w-full mt-2"
                    value={selectedRole}
                    onChange={handleRoleSelect}
                  >
                    <option value="" disabled>
                      Choose a role
                    </option>
                    {roles.length > 0 ? (
                      roles.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No roles available
                      </option>
                    )}
                  </select>
                </div>
              )}
              {showAddPersonButton && (
                <button
                  onClick={handleAddPerson}
                  className="mt-4 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
                >
                  Confirm Add Staff
                </button>
              )}
            </div>
      </td>
      <td className="px-6 py-4 text-center">
        <span className="text-gray-400">
          {event.tickets_sold} / {event.tickets_total}
        </span>
      </td>
      <td className="px-6 py-4">
        <EventState state={event.state} />
      </td>
    </tr>
  );
};

export const EventsList = (props) => {
  const events = useQuery(
    ["events", "list"],
    () => getEventsList(),
    getEventsSettings
  );
  const people = useQuery(["staff"], () => getStaff(), getPeopleSettings);
  const roles = useQuery(["event-roles"], () => getEventRoles());
  const navigate = useNavigate();
  const { perms } = usePerms();
  usePageTitle(events.isSuccess ? "/events" : "/");

  const authorizedRoles = ["admin", "event_manager"];
  const isAuthorized = perms.data && authorizedRoles.includes(perms.data.role);

  const [searchTerm, setSearchTerm] = useState("");

  if (events.isLoading || people.isLoading || roles.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  const filteredEvents = events.data.filter((event) =>
    event.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const eventRoleChoices = roles.data
    .filter((role) => role.active)
    .map((role) => ({ id: role.id, name: role.name }));

  return (
    <>
      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full flex justify-between items-center mt-[.5rem]">
          <BreadCrumbs links={[{ text: "Events", url: "/events" }]} />
          <SearchBar
            width="300px"
            handleSearch={handleSearch}
            placeholder="Search Events" // SEARCH EVENTS PLACEHOLDER
          />
          <Button onClick={() => navigate("/events")}>Event Cards</Button>
          <span>
            {perms.data.create_events && (
              <Button onClick={() => navigate("/events/add")}>Add Event</Button>
            )}
          </span>
        </div>
        <div className="my-2 w-full overflow-x-auto shadow-md sm:rounded-lg">
          {events.isSuccess && (
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Event Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Venue
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Staff
                  </th>
                  <th scope="col" className="px-6 py-3">
                    {isAuthorized ? "Select Option" : "Status"}
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Sold / Available
                  </th>
                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {filteredEvents.map((event) => (
                  <EventRow
                    key={event.id}
                    event={event}
                    showSelectMenu={!isAuthorized}
                    roles={eventRoleChoices}
                  />
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};
