import React, { useEffect, useState } from "react";
/* import classnames from "classnames"; */
/* import dayjs from "dayjs"; */
import { useParams /* useNavigate */ } from "react-router-dom";
import {
  useQuery /* useMutation, useQueryClient */,
} from "@tanstack/react-query";
/* import {
  amountDisplay,
  formatDollarsToCents,
  formatCentsToDollars,
} from "@/utils/money"; */
import {
  getEvent,
  getEventPeople,
  getEventSettlementInfo,
  updateEvent,
} from "@/queries/events";
import {
  getArtistEventRollups,
  /*  getExpenseGroups,
  getExpenseGroupSettings,
  getArtistEventExpenses,
  getEventExpensesSettings, */
} from "@/queries/accounting";
import { usePageTitle } from "@/utils/pagetitle";
import { Table as AppTable } from "../../components/AppTable2";
import ExpenseTable from "../../components/ExpensesTable";
export const getCSRFToken = async () => {
  const resp = await fetch("/api/csrf/", {
    method: "GET",
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const data = await resp.json();
  return data.token;
};
const getArtist = async (artistId) => {
  const token = await getCSRFToken();
  // THE / AT THE END OF THE URL IS NECESSARY!
  const res = await fetch(`http://localhost:8000/api/artists/${artistId}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": token,
    },
  });
  const data = await res.json();
  return data;
};

const updateManifestObject = async (newManifestObject: any) => {
  const token = await getCSRFToken();
  // THE / AT THE END OF THE URL IS NECESSARY!
  const res = await fetch(
    `http://localhost:8000/api/events/manifest/${newManifestObject?.id}/`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": token,
      },
      body: JSON.stringify({
        ...newManifestObject,
        on_sale: "",
      }),
    }
  );
  const data = await res.json();
  return data;
};

const FinalFiguresTable = ({
  className,
  flexClassNames,
  grid,
  textCenter,
  tableTitle,
  cols,
  totalsRow,
}: {
  pageId?: number | string;
  className?: string;
  flexClassNames?: string;
  flex?: boolean;
  grid?: boolean;
  textCenter?: boolean;
  tableTitle: string;
  cols: {
    title: string;
    textAlign?: "start" | "center" | "end";
    className?: string;
    values?: {
      editable?: boolean;
      list?: {
        onBlur?: (e: React.ReactEventHandler) => void;
        value?: string | number;
        custom?: React.ReactNode;
      }[];
    };
  }[];
  totalsRow?: {
    title: string;
    textAlign?: "start" | "center" | "end";
    values: (string | number)[];
  };
}) => {
  const [columns, setColumns] = React.useState(cols);
  const handleInputChange = (colIndex, itemIndex, event, item) => {
    const newColumns = [...columns];
    newColumns[colIndex].values.list[itemIndex].value = event.target.value;
    setColumns(newColumns);
  };
  const handleInputBlur = (
    colIndex: number,
    itemIndex: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newColumns = [...columns];
    newColumns[colIndex].values.list[itemIndex].value = event.target.value;

    setColumns(newColumns);
  };

  return (
    <div
      className={`${textCenter && "text-center"} border-2 border-black ${className}`}
    >
      <h2 className="text-center font-bold text-blue-600">{tableTitle}</h2>
      <div
        className={`${grid && "grid"} border-t-2 border-black `}
        style={
          grid
            ? {
                gridTemplateColumns: `repeat(${cols.length}, minmax(0, 1fr))`,
              }
            : {
                display: "flex",
              }
        }
      >
        {columns.map((col, colIndex) => (
          <div
            className={`${flexClassNames} ${col.className} text-${col.textAlign}`}
            key={colIndex}
          >
            <h3 className="bg-gray-200 font-bold border-b-2 border-black">
              {col.title}
            </h3>
            {col.values?.list?.map((item, itemIndex) => {
              if (item.custom) return item.custom;
              return col.values?.editable ? (
                <input
                  className={`bg-input-blue text-${col.textAlign} w-full`}
                  key={itemIndex}
                  value={item.value}
                  onBlur={(event) => {
                    handleInputBlur(colIndex, itemIndex, event, item);

                    item.onBlur(event);
                  }}
                  onChange={(event) =>
                    handleInputChange(colIndex, itemIndex, event, item)
                  }
                />
              ) : (
                <span className="block">{item.value}</span>
              );
            })}
          </div>
        ))}
      </div>
      {totalsRow && (
        <div
          className={`grid border-t-2 text-${totalsRow.textAlign} border-black`}
          style={{
            gridTemplateColumns: `repeat(${totalsRow.values.length + 1}, minmax(0, 1fr))`,
          }}
        >
          <span className="bg-gray-200 font-bold">{totalsRow.title}</span>
          {totalsRow.values.map((value, index) => (
            <span className="bg-gray-200 w-full" key={index}>
              {value}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
const TitleAndInputRow = ({
  title,
  initialValue,
  textCenter,
  onChange,
  onBlur,
  hideTitle = false,
}: {
  title: string;
  hideTitle?: boolean;
  initialValue: string;
  textCenter?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}) => {
  const [value, setValue] = React.useState(initialValue);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event);
    setValue(event.target.value);
  };
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(event);
    setValue(event.target.value);
  };

  return (
    <div className="flex justify-between">
      {title && !hideTitle && <h3 className="text-xs font-bold">{title}</h3>}
      <input
        className={
          "text-xs bg-input-blue text-red-600 " + (textCenter && "text-center")
        }
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
};

const HeaderGridCol4 = ({
  title,
  initialValues,
  onChange,
  onBlur,
}: {
  title: string;
  initialValues: string[];
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    phoneState: string,
    emailState: string
  ) => void;
  onBlur: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    phoneState: string,
    emailState: string
  ) => void;
}) => {
  const [values, setValues] = React.useState(initialValues);

  const handleChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValues = [...values];
    if (onChange) {
      onChange(event, index, newValues[1], newValues[2]);
    }
    //passing the phone and email state because partial updates makes the other fields null
    newValues[index] = event.target.value;

    setValues(newValues);
  };

  const handleBlur = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValues = [...values];
    //passing the phone and email state because partial updates makes the other fields null
    onBlur(event, index, newValues[1], newValues[2]);
    newValues[index] = event.target.value;

    setValues(newValues);
  };

  return (
    <div className="grid grid-cols-4">
      <span className="font-bold">{title}</span>
      {values.map((value, index) => (
        <input
          key={index}
          type="text"
          className="bg-input-blue text-center text-red-600"
          value={value}
          onChange={(event) => handleChange(index, event)}
          onBlur={(event) => handleBlur(index, event)}
        />
      ))}
    </div>
  );
};
type FinalFiguresAllowedValues = "all" | "number" | "yesno";

const FinalFiguresInput = ({
  initialValue,
  onEnter,
  onBlur,
  className,
  allowedValues = "all",
}: {
  initialValue?: string;
  onEnter?: () => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  className?: string;
  allowedValues?: FinalFiguresAllowedValues;
}) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        if (onEnter) onEnter();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onEnter]);

  const [value, setValue] = React.useState(initialValue ?? "");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (allowedValues === "number" && !/^\d*$/.test(inputValue)) {
      return; // only allow numbers
    }

    if (allowedValues === "yesno" && !/^(Yes|No)?$/.test(inputValue)) {
      return; // only allow 'Yes' or 'No'
    }

    setValue(inputValue);
  };

  return (
    <input
      value={value}
      onChange={handleChange}
      onBlur={(e) => {
        if (onBlur) onBlur(e);
      }}
      type="text"
      className={`w-full block focus:outline-none bg-input-blue focus:ring text-red-600 text-xs self-start${className ? " " + className : ""}`}
    />
  );
};

const FinalFiguresRow = ({
  title,
  titleClass,
  subtitle,
  subInput,
  subInputTitle,
  subInputTitleClass,
  includeTotal = true,
  filled = true,
  onBlur,
  allowedValues = "all",
  initialValue = "",
  subInputInitialValue = "",
}: {
  title: string;
  titleClass?: string;
  subtitle?: string;
  subInput?: boolean;
  subInputTitle?: string;
  subInputTitleClass?: string;
  includeTotal?: boolean;
  filled?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  allowedValues?: FinalFiguresAllowedValues;
  initialValue?: string;
  subInputInitialValue?: string;
}) => {
  const WithoutSubInput = () => {
    return (
      <div className="flex">
        <div className="flex-1">
          <div className={`font-bold${filled ? " bg-gray-200" : "bg-white"}`}>
            {title}
          </div>
        </div>
        <div className="w-48">
          <FinalFiguresInput
            initialValue={initialValue}
            allowedValues={allowedValues}
            onBlur={onBlur}
          />
        </div>
      </div>
    );
  };
  const WithSubInput = () => {
    const subInputClass = () => {
      if (subInputTitle) {
        return "w-80";
      }
      return "w-48";
    };
    return (
      <div className="flex">
        <div className={`flex-1`}>
          {title && (
            <div className={`font-bold${titleClass ? ` ${titleClass}` : ""}`}>
              {title}
            </div>
          )}
          {subtitle && <span className="text-red-600">{subtitle}</span>}
        </div>
        <div className={`flex bg-white ${subInputClass()}`}>
          {subInputTitle && (
            <div
              className={`font-bold flex-1${subInputTitle ? ` ${subInputTitleClass}` : ""}`}
            >
              {subInputTitle}
            </div>
          )}
          <FinalFiguresInput
            allowedValues={allowedValues}
            className="!w-48"
            onBlur={onBlur}
            initialValue={subInputInitialValue}
          />
        </div>
        {includeTotal && (
          <div className="w-48">
            <FinalFiguresInput
              initialValue={initialValue}
              allowedValues={allowedValues}
              onBlur={onBlur}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="bg-white">
      {subInput ? WithSubInput() : WithoutSubInput()}
    </div>
  );
};

const FinalFiguresHeader = ({
  leftTitle,
  rightTitle,
}: {
  leftTitle: string;
  rightTitle?: string;
}) => {
  return (
    <div className="flex bg-yellow-500 font-extrabold border-black border-b-2 border-t-2">
      <div className="flex-1">{leftTitle}</div>
      {rightTitle && (
        <div className="w-48 underline text-center">{rightTitle}</div>
      )}
    </div>
  );
};

const EnterYesOrNo = {
  subInputTitle: 'Enter "Yes" or "No"',
  subInputTitleClass: "text-red-600",
};

export const EventArtistSettlement = () => {
  const [pageIndex, setPageIndex] = useState<"artist" | "copro" | "final">(
    "artist"
  );
  const { id } = useParams();
  const event = useQuery(["event-detail", id], () => getEvent(id));
  const [city, setCity] = useState(event.data?.venue.city);
  const [state, setState] = useState(event.data?.venue.state);
  const [support1, setSupport1] = useState(event.data?.offer_support_1);
  const [support2, setSupport2] = useState(event.data?.offer_support_2);
  const [headliner, setHeadliner] = useState(event.data?.offer_headliner);
  const [talentBuyerFirstName, setTalentBuyerFirstName] = useState(
    event.data?.buyer.first_name
  );
  const [talentBuyerLastName, setTalentBuyerLastName] = useState(
    event.data?.buyer.first_name
  );
  type Artist = {
    id: string;
    name: string;
    phone: string;
    email: string;
    vendors: {
      id: string;
      name: string;
      city: string;
      state: string;
    }[];
  };
  const [artist, setArtist] = useState<Partial<Artist>>({});

  const handleUpdateEvent = async (
    id = event.data?.id,
    data: Record<string, unknown>
  ) => {
    await updateEvent(id, {
      ...event.data,
      artists: event.data.artists?.map((artist) => artist.id),
      buyer: event.data.buyer.id,
      manifests: event.data.manifests?.map((manifest) => manifest.id),
      venue: event.data.venue.id,
      ...data,
    });
  };

  useEffect(() => {
    if (event.data) {
      //Setting the city or state only in a JSON request makes the other one null. Thats why we keep it as a special state
      setCity(event.data.venue.city);
      setState(event.data.venue.state);
      setSupport1(event.data.offer_support_1);
      setSupport2(event.data.offer_support_2);
      setHeadliner(event.data?.offer_headliner);
      setTalentBuyerFirstName(event.data.buyer.first_name);
      setTalentBuyerLastName(event.data.buyer.last_name);
      getArtist(event.data.artists[0].id).then((data) => setArtist(data));
    }
  }, [event.data]);
  const settlement = useQuery(["event-settlement-info", id], () =>
    getEventSettlementInfo(id)
  );
  const people = useQuery(["event", "people", id], () => getEventPeople(id));
  const rollups = useQuery(["event-rollups", "artist", id], () =>
    getArtistEventRollups(id)
  );

  const manifests = event.data && event.data.manifests;
  usePageTitle(
    event.isSuccess
      ? `/c${String.fromCharCode(92)}Artist Settle`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  if (
    event.isLoading ||
    people.isLoading ||
    rollups.isLoading ||
    settlement.isLoading
  ) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  return (
    <div className="text-xs p-20">
      <div className="w-full fixed bottom-0 bg-white text-lg flex gap-3">
        <button
          className={`${pageIndex == "artist" ? "bg-blue-600 text-white" : "hover:bg-blue-400 duration-200"} rounded-md p-2`}
          onClick={() => setPageIndex("artist")}
        >
          ARTIST
        </button>
        <button
          className={`${pageIndex == "copro" ? "bg-blue-600 text-white" : "hover:bg-blue-400 duration-200"} rounded-md p-2`}
          onClick={() => setPageIndex("copro")}
        >
          COPRO
        </button>
        <button
          className={`${pageIndex == "final" ? "bg-blue-600 text-white" : "hover:bg-blue-400 duration-200"} rounded-md p-2`}
          onClick={() => setPageIndex("final")}
        >
          FINAL
        </button>
      </div>

      {/* ⬇⬇ ARTIST PAGE ⬇⬇ */}
      <div>
        <div className="w-full flex justify-between mb-5">
          <div className="w-2/5 flex flex-col gap-5">
            <h1 className="pl-2 text-center text-2xl font-bold">Mammoth</h1>
            <div className="w-2/3">
              <div className="flex flex-col ">
                {/*   <TitleAndInputRow
                    title="COMPANY DIVISION"
                    initialValue="Mammoth"
                  /> */}
                <TitleAndInputRow
                  title="HEADLINER"
                  initialValue={headliner ?? event.data.offer_headliner}
                  onBlur={async (e) => {
                    setHeadliner(e.target.value);
                    const token = await getCSRFToken();
                    const res = await fetch(
                      `http://localhost:8000/api/events/${event.data.id}/`,
                      {
                        method: "PUT",
                        headers: {
                          "Content-Type": "application/json",
                          "X-CSRFToken": token,
                        },
                        body: JSON.stringify({
                          offer_headliner: e.target.value,
                          offer_support_1: support1,
                          offer_support_2: support2,

                          name: event.data.name,
                          date: event.data.date,
                        }),
                      }
                    );
                    const data = await res.json();
                    return data;
                  }}
                />
                {/*    <TitleAndInputRow title="CO-HEADLINER" initialValue="" /> */}
                <TitleAndInputRow
                  title="SUPPORT 1"
                  initialValue={event.data.offer_support_1}
                  onBlur={async (e) => {
                    setSupport1(e.target.value);
                    const token = await getCSRFToken();
                    const res = await fetch(
                      `http://localhost:8000/api/events/${event.data.id}/`,
                      {
                        method: "PUT",
                        headers: {
                          "Content-Type": "application/json",
                          "X-CSRFToken": token,
                        },
                        body: JSON.stringify({
                          offer_support_1: e.target.value,
                          offer_support_2: support2,
                          offer_headliner: headliner,

                          name: event.data.name,
                          date: event.data.date,
                        }),
                      }
                    );
                    const data = await res.json();
                    return data;
                  }}
                />
                <TitleAndInputRow
                  title="SUPPORT 2"
                  initialValue={event.data.offer_support_2}
                  onBlur={async (e) => {
                    setSupport2(e.target.value);

                    const token = await getCSRFToken();
                    const res = await fetch(
                      `http://localhost:8000/api/events/${event.data.id}/`,
                      {
                        method: "PUT",
                        headers: {
                          "Content-Type": "application/json",
                          "X-CSRFToken": token,
                        },
                        body: JSON.stringify({
                          offer_support_2: e.target.value,
                          offer_support_1: support1,
                          offer_headliner: headliner,

                          name: event.data.name,
                          date: event.data.date,
                        }),
                      }
                    );
                    const data = await res.json();
                    return data;
                  }}
                />
                <TitleAndInputRow
                  title="DATE"
                  initialValue={event.data.date}
                  onBlur={async (e) => {
                    const token = await getCSRFToken();
                    const res = await fetch(
                      `http://localhost:8000/api/events/${event.data.id}/`,
                      {
                        method: "PUT",
                        headers: {
                          "Content-Type": "application/json",
                          "X-CSRFToken": token,
                        },
                        body: JSON.stringify({
                          date: e.target.value,
                          name: event.data.name,
                        }),
                      }
                    );
                    const data = await res.json();
                    return data;
                  }}
                />
                <TitleAndInputRow
                  title="VENUE"
                  initialValue={event.data.venue.name}
                  onBlur={async (e) => {
                    const token = await getCSRFToken();
                    const res = await fetch(
                      `http://localhost:8000/api/venues/${event.data.venue.id}/`,
                      {
                        method: "PUT",
                        headers: {
                          "Content-Type": "application/json",
                          "X-CSRFToken": token,
                        },
                        body: JSON.stringify({
                          name: e.target.value,
                        }),
                      }
                    );
                    const data = await res.json();
                    return data;
                  }}
                />
                <TitleAndInputRow
                  title="CITY"
                  initialValue={city ?? event.data.venue.city}
                  onBlur={async (e) => {
                    setCity(e.target.value);
                    const token = await getCSRFToken();
                    const res = await fetch(
                      `http://localhost:8000/api/venues/${event.data.venue.id}/`,
                      {
                        method: "PUT",
                        headers: {
                          "Content-Type": "application/json",
                          "X-CSRFToken": token,
                        },
                        body: JSON.stringify({
                          name: event.data.venue.name,

                          city: e.target.value,
                          state,
                        }),
                      }
                    );
                    const data = await res.json();
                    return data;
                  }}
                />
                <TitleAndInputRow
                  title="STATE"
                  initialValue={state ?? event.data.venue.state}
                  onBlur={async (e) => {
                    const token = await getCSRFToken();
                    setState(e.target.value);
                    const res = await fetch(
                      `http://localhost:8000/api/venues/${event.data.venue.id}/`,
                      {
                        method: "PUT",
                        headers: {
                          "Content-Type": "application/json",
                          "X-CSRFToken": token,
                        },
                        body: JSON.stringify({
                          name: event.data.venue.name,
                          state: e.target.value,
                          city,
                        }),
                      }
                    );
                    const data = await res.json();
                    return data;
                  }}
                />
                <TitleAndInputRow
                  title="TALENT BUYER FIRST NAME"
                  initialValue={
                    talentBuyerFirstName ?? event.data.buyer.first_name
                  }
                  onBlur={async (e) => {
                    setTalentBuyerFirstName(e.target.value);
                    const token = await getCSRFToken();
                    const res = await fetch(
                      `http://localhost:8000/api/events/${event.data.id}/`,
                      {
                        method: "PUT",
                        headers: {
                          "Content-Type": "application/json",
                          "X-CSRFToken": token,
                        },
                        body: JSON.stringify({
                          name: event.data.venue.name,
                          buyer: { first_name: e.target.value },
                        }),
                      }
                    );
                    const data = await res.json();
                    return data;
                  }}
                />
                <TitleAndInputRow
                  title="TALENT BUYER LAST NAME"
                  initialValue={
                    talentBuyerLastName ?? event.data.buyer.last_name
                  }
                  onBlur={async (e) => {
                    setTalentBuyerLastName(e.target.value);
                    const token = await getCSRFToken();
                    const res = await fetch(
                      `http://localhost:8000/api/events/${event.data.id}/`,
                      {
                        method: "PUT",
                        headers: {
                          "Content-Type": "application/json",
                          "X-CSRFToken": token,
                        },
                        body: JSON.stringify({
                          name: event.data.venue.name,
                          buyer: { last_name: e.target.value },
                        }),
                      }
                    );
                    const data = await res.json();
                    return data;
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-2/4 flex justify-end flex-col">
            <div className="flex">
              <TitleAndInputRow
                title="# OF SHOWS:"
                initialValue="1"
                textCenter
              />
            </div>
            <div className="my-4 grid grid-cols-4">
              <h3 className="font-bold">SETTLEMENT CONTACTS:</h3>
              <h3 className="underline text-center">NAME</h3>
              <h3 className="underline text-center">PHONE</h3>
              <h3 className="underline text-center">EMAIL</h3>
            </div>
            {/*  <HeaderGridCol4
                title="MAMMOTH:"
                initialValues={[
                  "Russ Martin",
                  "785.760.3002",
                  "rmartin@mammothlive.com",
                ]}
              /> */}
            <HeaderGridCol4
              title="ARTIST:"
              initialValues={[
                event.data.artists[0].name,
                event.data.artists[0].phone,
                event.data.artists[0].email,
              ]}
              onBlur={async (e, index, phoneState, emailState) => {
                const token = await getCSRFToken();
                const res = await fetch(
                  `http://localhost:8000/api/artists/${artist.id}/`,

                  {
                    method: "PUT",
                    headers: {
                      "Content-Type": "application/json",
                      "X-CSRFToken": token,
                    },
                    body: JSON.stringify(
                      index == 0
                        ? {
                            name: e.target.value,
                            email: emailState,
                            phone: phoneState,
                          }
                        : index == 1
                          ? {
                              phone: e.target.value,
                              name: artist.name,
                              email: emailState,
                            }
                          : {
                              email: e.target.value,
                              name: artist.name,
                              phone: phoneState,
                            }
                    ),
                  }
                );
                const data = await res.json();
                return data;
              }}
            />
            {/* <HeaderGridCol4
                title="BUILDING:"
                initialValues={[
                  "Juliet Trieloff",
                  "312.341.2378",
                  "jtrieloff@auditoriumtheatre.org",
                ]}
              /> */}

            <div className="mt-5">
              {artist && artist.vendors && artist.vendors.length > 0 && (
                <HeaderGridCol4
                  title="ARTIST AGENCY:"
                  onBlur={async (e) => {
                    const token = await getCSRFToken();
                    const res = await fetch(
                      `http://localhost:8000/api/vendors/${artist.vendors[0].id}/`,
                      {
                        method: "PUT",
                        headers: {
                          "Content-Type": "application/json",
                          "X-CSRFToken": token,
                        },
                        body: JSON.stringify({ name: e.target.value }),
                      }
                    );
                    const data = await res.json();
                    return data;
                  }}
                  initialValues={[artist.vendors[0].name]}
                />
              )}
            </div>
          </div>
        </div>
        <AppTable
          grid
          tableTitle="SHOW 1"
          onCellBlur={async (rowIndex, accessor, value) => {
            const manifestItem = manifests[rowIndex];
            console.log("runs");
            await updateManifestObject({
              ...manifestItem,
              [accessor]: value,
            });
          }}
          columns={[
            {
              title: "TICKET SCALING",
              accessor: "name",
              editable: true,
            },
            {
              title: "CAPACITY",
              accessor: "qty",
              textAlign: "center",
              editable: true,
            },
            {
              title: "KILLS",
              accessor: "kills",
              textAlign: "center",
              editable: true,
            },
            {
              title: "COMPS",
              accessor: "comps",
              textAlign: "center",
              editable: true,
            },
            {
              title: "SOLD",
              accessor: "paid",
              textAlign: "center",
              editable: true,
            },
            {
              title: "UNSOLD",
              accessor: "available",
              textAlign: "center",
              editable: true,
            },
            {
              title: "PRICE",
              accessor: "price",
              textAlign: "center",
              editable: true,
            },
            {
              title: "GROSS",
              accessor: "gross",
              textAlign: "center",
              editable: false,
            },
          ]}
          data={manifests.map((manifest) => ({
            name: manifest.name,
            qty: manifest.qty,
            kills: manifest.kills,
            comps: manifest.comps,
            paid: manifest.paid,
            available: manifest.available,
            price: manifest.price,
            gross: manifest.price * manifest.paid,
          }))}
        />

        <div className="text-xs mt-8 border-black border-2">
          <h2 className="text-center font-bold text-blue-600">FINAL FIGURES</h2>
          <FinalFiguresHeader leftTitle="REVENUES" rightTitle="TOTAL" />
          {/* GROSS */}
          <FinalFiguresRow
            title="GROSS"
            initialValue={event.data.gross || "0"}
            onBlur={async (e) => {
              await handleUpdateEvent(event.data.id, { gross: e.target.value });
            }}
          />

          {/* PLATINUM LIFT */}
          <FinalFiguresRow
            title="PLATINUM LIFT"
            initialValue={event.data.platinum_lift || "0"}
            filled={false}
            onBlur={async (e) => {
              await handleUpdateEvent(event.data.id, {
                platinum_lift: e.target.value,
              });
            }}
          />

          {/* TOTAL GROSS REVENUE */}
          <FinalFiguresRow
            title="TOTAL GROSS REVENUE"
            initialValue={event.data.total_gross_revenue || "0"}
            onBlur={async (e) => {
              await handleUpdateEvent(event.data.id, {
                total_gross_revenue: e.target.value,
              });
            }}
          />
          <FinalFiguresHeader leftTitle="PER TICKET DEDUCTIONS:" />
          {/* FACILITY FEE */}
          <FinalFiguresRow
            title="FACILITY FEE"
            subInput
            subInputTitle="FACILITY FEE RATE:"
            includeTotal={false}
            initialValue={event.data.facility_fee || "0"}
            onBlur={async (e) => {
              await handleUpdateEvent(event.data.id, {
                facility_fee: e.target.value,
              });
            }}
          />
          <FinalFiguresRow
            title="a) Calculated on Per Txt Deduction"
            subInput
            {...EnterYesOrNo}
            includeTotal={false}
            initialValue={event.data.facility_fee || "0"}
            onBlur={async (e) => {
              await handleUpdateEvent(event.data.id, {
                facility_fee: e.target.value,
              });
            }}
          />

          {/* CHARITY RATE */}
          <FinalFiguresRow
            title="CHARITY"
            subInput
            subInputTitle="CHARITY RATE: "
            includeTotal={false}
            // initialValue={event.data.charity || "0"}
            subInputInitialValue={event.data.charity || "0"}
            onBlur={async (e) => {
              await handleUpdateEvent(event.data.id, {
                charity: e.target.value,
              });
            }}
          />

          <FinalFiguresHeader leftTitle="TOTAL PER TXT DEDUCTIONS:" />

          <FinalFiguresRow
            title="ADJ BOX OFFICE RECEIPTS"
            initialValue={event.data.tax1 || ""}
            onBlur={async (e) => {
              // await handleUpdateEvent(event.data.id, { tax1: e.target.value });
            }}
          />
          {/* TAX 1 */}
          <FinalFiguresRow
            title="TAX 1"
            subInputTitle="TAX RATE: "
            initialValue={event.data.tax_percent || ""}
            onBlur={async (e) => {
              await handleUpdateEvent(event.data.id, {
                tax_percent: e.target.value,
              });
            }}
          />

          <FinalFiguresRow
            title="a) Calculated on Revenue, after per ticket deductions (Primary Divider Tax)"
            subInput
            {...EnterYesOrNo}
            includeTotal={false}
            subInputInitialValue={event.data.calc || ""}
            allowedValues="yesno"
            onBlur={async (e) => {
              // await handleUpdateEvent(event.data.id, {
              //   facility_fee: e.target.value,
              // });
            }}
          />
          <FinalFiguresRow
            title="e) Multiplacative Tax, On Revenue, After per Ticket Deductions (Primary Multiplier Tax)"
            subInput
            {...EnterYesOrNo}
            includeTotal={false}
            subInputInitialValue={event.data.multi || ""}
            allowedValues="yesno"
            onBlur={async (e) => {
              // await handleUpdateEvent(event.data.id, {
              //   facility_fee: e.target.value,
              // });
            }}
          />

          <FinalFiguresRow
            title="TOTAL TAX 1"
            initialValue={event.data.tax1 || ""}
            onBlur={async (e) => {
              await handleUpdateEvent(event.data.id, { tax1: e.target.value });
            }}
          />
          {/* TAX 2 */}
          <FinalFiguresRow
            title="TAX 2"
            initialValue={event.data.tax2 || ""}
            onBlur={async (e) => {
              await handleUpdateEvent(event.data.id, { tax2: e.target.value });
            }}
          />
          <FinalFiguresRow
            title="Is Tax 2 calculated After (Net Of) Tax 1?"
            subInput
            {...EnterYesOrNo}
            includeTotal={false}
            titleClass="text-red-600"
            initialValue={event.data.tax2 || ""}
            onBlur={async (e) => {
              await handleUpdateEvent(event.data.id, { tax2: e.target.value });
            }}
          />

          {/* TOTAL TAX 2 */}
          <FinalFiguresRow
            title="TOTAL TAX 2"
            initialValue={event.data.total_tax2 || ""}
            onBlur={async (e) => {
              await handleUpdateEvent(event.data.id, {
                total_tax2: e.target.value,
              });
            }}
          />

          <FinalFiguresHeader leftTitle="VARIABLE DEDUCTIONS:" />

          {/* VARIABLE DEDUCTIONS */}
          <FinalFiguresRow
            title="LESS TM PLATINUM FEE 5%"
            initialValue={event.data.variable_deductions || "293771"}
            onBlur={async (e) => {
              await handleUpdateEvent(event.data.id, {
                variable_deductions: e.target.value,
              });
            }}
          />
          <FinalFiguresRow
            title="TOTAL VARIABLE DEDUCTIONS"
            initialValue={event.data.variable_deductions || "293771"}
            filled={false}
            onBlur={async (e) => {
              await handleUpdateEvent(event.data.id, {
                variable_deductions: e.target.value,
              });
            }}
          />

          {/* NET GROSS RECEIPTS */}
          <FinalFiguresRow
            title="NET GROSS RECEIPTS"
            initialValue={event.data.net_gross_receipts || ""}
            onBlur={async (e) => {
              await handleUpdateEvent(event.data.id, {
                net_gross_receipts: e.target.value,
              });
            }}
          />
        </div>

        <ExpenseTable id={event.data?.id} book={pageIndex} />
      </div>

      {/* ⬆⬆ ARTIST PAGE ⬆⬆ */}
    </div>
  );
};

export default EventArtistSettlement;
