import React from "react";
import { useNavigate } from 'react-router-dom'; 

export const EventCard = ({ eventName, venue, date, status, onClick, backgroundImage, url }) => {
  const navigate = useNavigate();

  const buttonColor = {
    confirmed: 'bg-green-500 hover:bg-green-600',
    offer: 'bg-orange-500 hover:bg-orange-600',
    draft: 'bg-blue-500 hover:bg-blue-600'
  }[status.toLowerCase()] || 'bg-gray-500 hover:bg-gray-600';

  const handleCardClick = () => {
    url && navigate(url);
  };

  return (
    <div onClick={handleCardClick} className="h-96 w-52 m-2 dark:bg-cave-gray-800 bg-cave-gray-100 dark:hover:ring-2 hover:ring-4 cursor-pointer flex flex-col relative text-white rounded-lg overflow-hidden shadow-lg p-2 ">
      <img src={backgroundImage || '/static/offer-mammoth-logo.png'} alt="Event Background" className="aspect-square	rounded w-full object-cover" />
      <div className="flex flex-col justify-between pt-3 p-1 flex-grow"> 
        <div>
          <h2 className="text-lg font-sans font-bold text-[#666666] dark:text-gray-200">{eventName}</h2>
          <p className="text-sm text-[#666666] dark:text-gray-400">{venue}</p>
          <p className="text-sm text-[#666666] dark:text-gray-400">{date}</p>
        </div>
        {status ? <button
          className={`w-full py-1 mt-3 text-white font-sans font-bold ${buttonColor} rounded`}
          onClick={onClick}
        >
          {status.toUpperCase()}
        </button> : null}
      </div>
    </div>
  );
};
