import dayjs from "dayjs";

import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import { TwitterHandle } from "@/components/TwitterHandle";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { EventTable } from "@/components/EventDisplay/EventTable";
import { useRecordVisit } from "@/queries/visit";
import LinkDisplay from "@/components/Links/LinkDisplay";
import WireInfo from "@/components/WireInfo/WireInfoDisplay";
import CommentDisplay from "@/components/Comments/CommentDisplay";
import FileDisplay from "@/components/Files/FileDisplay";

import {
  getVendor,
  getVendorPeople,
  toggleVendorMainPOC,
  getVendorEvents,
  getVendorPastEvents,
  getVendorLinks,
  getVendorLinkSettings,
  deleteVendorLink,
  getVendorComments,
  getVendorCommentSettings,
  createVendorComment,
  getVendorFiles,
  getVendorFileSettings,
  deleteVendorFile,
  getVendorWireInfo,
  createVendorWireInfo,
  deleteVendorWireInfo,
  getVendorArtists,
  deleteVendorArtist,
} from "@/queries/vendors";

import { useParams, useNavigate } from "react-router-dom";
import { usePerms } from "@/components/Auth";
import People from "@/components/People/People";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { usePageTitle } from "@/utils/pagetitle";
import ArtistsDisplay from "@/components/Artists/ArtistsDisplay";

export const VendorDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const vendor = useQuery(["vendor-detail", id], () => getVendor(id));
  const people = useQuery(["vendor", "people", id], () => getVendorPeople(id));
  const events = useQuery(["vendor", "events", id], () => getVendorEvents(id));
  // all artists -- const artists = useQuery(["artists"], getArtists, getArtistsSettings)
  const artists = useQuery(["vendor", "artists", id], () =>
    getVendorArtists(id)
  );
  const past_events = useQuery(["vendor", "events", "past", id], () =>
    getVendorPastEvents(id)
  );
  const links = useQuery(
    ["vendor", "links", id],
    () => getVendorLinks(id),
    getVendorLinkSettings
  );
  const wireinfos = useQuery(
    ["vendor", "wireinfos", id],
    () => getVendorWireInfo(id),
    getVendorLinkSettings
  );
  const comments = useQuery(
    ["vendor", "comments", id],
    () => getVendorComments(id),
    getVendorCommentSettings
  );
  const files = useQuery(
    ["vendor", "files", id],
    () => getVendorFiles(id),
    getVendorFileSettings
  );
  const { perms } = usePerms();
  const queryClient = useQueryClient();
  const visitMutation = useRecordVisit();

  usePageTitle(
    vendor.isSuccess
      ? `/c${String.fromCharCode(92)}${vendor.data.name}`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  // Comments
  const commentMutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating comment");
      console.dir(data);

      return createVendorComment(id, data);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["vendor", "comments", id]);
    },
  });

  const handleCommentSubmit = (data) => {
    commentMutation.mutate(data);
  };

  // Toggle main POC flag
  const mutation = useMutation({
    mutationFn: (id) => {
      console.log("---> toggling person", id);

      return toggleVendorMainPOC(id);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["vendor", "people"]);
      queryClient.invalidateQueries(["vendors"]);
    },
  });

  useEffect(() => {
    if (vendor.isSuccess) {
      visitMutation.mutate({
        itemID: vendor.data.id,
        itemType: "vendor",
        itemURL: `/vendors/${vendor.data.id}`,
      });
    }
  }, [vendor.isSuccess]);

  if (vendor.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        {vendor.data.is_agency ? (
          <BreadCrumbs
            links={[
              { text: "Agencies", url: "/vendors" },
              { text: vendor.data.name },
            ]}
          />
        ) : (
          <BreadCrumbs
            links={[
              { text: "Vendors", url: "/vendors" },
              { text: vendor.data.name },
            ]}
          />
        )}

        <div className="py-6 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">
              {vendor.data.name}
            </h2>
            <h3 className="my-2 text-gray-600">{vendor.data.address}</h3>
            <h3 className="my-2 text-gray-600">
              {vendor.data.city && <>{vendor.data.city},</>} {vendor.data.state}{" "}
              {vendor.data.zipcode}
            </h3>
            <TwitterHandle url={vendor.data.twitter} />
          </div>
          <div className="mt-4 flex md:ml-4 md:mt-0 justify-start align-top">
            {perms.data.edit_vendors && (
              <button
                type="button"
                onClick={() => {
                  navigate(`/vendors/edit/${vendor.data.id}`);
                }}
                className="ml-3 inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold dark:text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
              >
                Edit Vendor
              </button>
            )}
          </div>
        </div>

        <div className="py-6">
          {vendor.data.description && (
            <p className="mb-4 dark:text-white">{vendor.data.description}</p>
          )}

          <table className="table-auto w-3/4">
            <tbody>
              <tr className="">
                <th className="text-left dark:text-white mr-8 p-2 w-1/4">URL</th>
                <td className="pl-4">
                  <a
                    href="{vendor.data.url}"
                    className="text-blue-500 underline hover:text-blue-700 dark:text-white"
                  >
                    {vendor.data.url}
                  </a>
                </td>
              </tr>
              <tr className="">
                <th className="text-left dark:text-white mr-8 p-2 w-1/4">Email</th>
                <td className="pl-4  dark:text-white">{vendor.data.email}</td>
              </tr>
              <tr className="">
                <th className="text-left dark:text-white mr-8 p-2 w-1/4">Phone</th>
                <td className="pl-4 dark:text-white">{vendor.data.phone}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {vendor.data.is_agency && (
          <div className="py-6">
            <ArtistsDisplay
              artists={artists}
              addURL={`/vendors/artists/add/${id}`}
              deleteQuery={deleteVendorArtist}
              itemType="vendor"
              itemId={vendor.data.id}
              editable={perms.data.edit_vendors}
            />
          </div>
        )}

        <div className="py-6">
          <People
            people={people}
            toggleMainPOC={mutation}
            addURL={`/vendors/people/add/${id}`}
            itemType="vendor"
            itemId={vendor.data.id}
            editable={perms.data.edit_vendors}
          />
        </div>

        <div className="py-6">
          <WireInfo
            wireinfos={wireinfos}
            addURL={`/vendors/wireinfo/add/${id}`}
            deleteQuery={deleteVendorWireInfo}
            editable={perms.data.edit_vendors}
          />
        </div>

        <div className="py-6">
          <LinkDisplay
            links={links}
            addURL={`/vendors/link/add/${id}`}
            deleteQuery={deleteVendorLink}
            editable={perms.data.edit_vendors}
          />
        </div>

        <div className="py-6">
          <FileDisplay
            files={files}
            addURL={`/vendors/file/add/${id}`}
            deleteQuery={deleteVendorFile}
            editable={perms.data.edit_vendors}
          />
        </div>

        <div className="py-6">
          <CommentDisplay
            comments={comments}
            handleAddComment={handleCommentSubmit}
          />
        </div>

        <div className="py-6">
          <h3 className="text-xl font-semibold mb-6 dark:text-white">Events</h3>
          {events.isLoading && <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>}
          {events.isSuccess && <EventTable events={events.data} />}
        </div>

        <div className="py-6">
          <h3 className="text-xl font-semibold mb-6 dark:text-white">
            Past Events
          </h3>
          {past_events.isLoading && <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>}
          {past_events.isSuccess && (
            <EventTable events={past_events.data} past={true} />
          )}
        </div>
      </div>
    </>
  );
};
