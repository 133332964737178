import React, { useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Modal } from "flowbite-react";
import Button from "@/components/Button";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getEventsCalendar, createEventHolds } from "@/queries/events";
import { getVenues, getVenuesSettings } from "@/queries/venues";
import { getArtists, getArtistsSettings } from "@/queries/artists";
import { EVENT_MAPPING } from "@/components/EventState";
import {
  FormBuilder,
  Input,
  TextInput,
  FormMultiSelect,
} from "@/components/Form";
import { usePerms } from "@/components/Auth";
import * as yup from "yup";
import { usePageTitle } from "@/utils/pagetitle";
import "./calendar.css";

const HoldModal = (props) => {
  const { showModal, setShowModal, selectedDates } = props;
  const venues = useQuery(["venues"], () => getVenues(), getVenuesSettings);
  const artists = useQuery(["artists"], getArtists, getArtistsSettings);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating event holds");

      // Determine the dates in the range
      let dates = [];

      if (
        selectedDates.hasOwnProperty("start") &&
        selectedDates.hasOwnProperty("end")
      ) {
        // Multiple dates
        let startDate = dayjs(selectedDates.start);
        let endDate = dayjs(selectedDates.end);

        while (startDate.isBefore(endDate)) {
          dates.push(startDate.format("YYYY-MM-DD"));
          startDate = startDate.add(1, "day");
        }
      } else {
        // Single date
        dates.push(dayjs(selectedDates.date).format("YYYY-MM-DD"));
      }

      console.log("dates: " + dates);
      const holdData = {
        name: data.name,
        dates: dates,
        venue_ids: data.venues.map((venue) => venue.value),
        artist_ids: data.artists.map((artist) => artist.value),
        description: data.description,
      };

      console.dir(holdData);

      return createEventHolds(holdData);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["events"]);
      setShowModal(false);
      console.log("CLOSING!");
    },
  });

  // Wait for Venues and Artists to load
  if (venues.isLoading || artists.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }
  const venueOptions = venues.data.map((venue) => {
    return { value: venue.id, label: venue.name };
  });
  const artistOptions = artists.data.map((artist) => {
    return { value: artist.id, label: artist.name };
  });

  let startDate = null;
  let endDate = null;

  if (selectedDates) {
    startDate = dayjs(selectedDates.start).format("dddd MMM DD, YYYY");
    endDate = dayjs(selectedDates.end).format("dddd MMM DD, YYYY");
  }

  const addSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),
  });

  return (
    <Modal
      dismissible
      popup={true}
      show={showModal}
      onClose={() => setShowModal(false)}
    >
      <Modal.Header className="bg-gray-300">
        <span className="ml-4 text-xl font-semibold">Create Hold</span>
      </Modal.Header>
      <FormBuilder
        onSubmit={(data) => mutation.mutate(data)}
        schema={addSchema}
      >
        <Modal.Body>
          <p className="text-gray-600">
            From <span className="font-semibold">{startDate}</span> to{" "}
            <span className="font-semibold">{endDate}</span>.
          </p>
          <Input name="name" label="Name" autoFocus={true} />
          <FormMultiSelect
            name="venues"
            label="Venues"
            options={venueOptions}
          />
          <FormMultiSelect
            name="artists"
            label="Artists"
            options={artistOptions}
          />
          <TextInput name="description" label="Description" />
        </Modal.Body>
        <Modal.Footer className="bg-gray-100">
          <div className="w-full flex-inline float-right">
            <div className="float-right">
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Cancel
              </button>
              <Button type="submit">Create Hold(s)</Button>
            </div>
          </div>
        </Modal.Footer>
      </FormBuilder>
    </Modal>
  );
};

export const Calendar = () => {
  const [selectedDates, setSelectedDates] = useState(null);
  const [showAddHold, setShowAddHold] = useState(false);
  const navigate = useNavigate();
  const { perms } = usePerms();

  const setShowModal = (value) => {
    if (perms.data.create_events) {
      setShowAddHold(value);
    }
  };

  const getEvents = async (fetchInfo, successCallback, failureCallback) => {
    console.log("---> fetching events");

    const data = {
      start_year: fetchInfo.start.getFullYear(),
      start_month: fetchInfo.start.getMonth() + 1,
      start_day: fetchInfo.start.getDate(),
      end_year: fetchInfo.end.getFullYear(),
      end_month: fetchInfo.end.getMonth() + 1,
      end_day: fetchInfo.end.getDate(),
    };

    const response = await getEventsCalendar(data);

    const formattedEvents = response.map((event) => {
      return {
        title: `${event.name} @ ${event.venue_name}`,
        start: event.date,
        id: event.id,
        backgroundColor: EVENT_MAPPING[event.state]["rgb-background"],
        textColor: EVENT_MAPPING[event.state]["rgb-text"],
        extendedProps: {
          state: event.state,
          venue_name: event.venue_name,
        },
      };
    });

    return formattedEvents;
  };

  const handleDateSelect = (selectionInfo) => {
    console.log("handleDateSelect", selectionInfo);
    setSelectedDates(selectionInfo);
  };

  const handleDateClick = (info) => {
    console.log("handleDateClick", info);
    setSelectedDates(info);
  };

  const renderEvent = (event) => {
    return (
      <div className="cursor-pointer truncate p-1">{event.event.title}</div>
    );
  };

  return (
    <>
      <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full" style={{ height: "36px" }}>
          {selectedDates && perms.data.create_events && (
            <div className="float-right">
              <Button onClick={() => setShowAddHold(true)}>Create Hold</Button>
            </div>
          )}
        </div>
        <div className="my-6">
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            selectable={true}
            select={handleDateSelect}
            events={(fetchInfo, successCallback, failureCallback) =>
              getEvents(fetchInfo, successCallback, failureCallback)
            }
            selectMirror={true}
            dateClick={handleDateClick}
            eventClick={(info) => {
              // Take the user to the event
              if (perms.data.view) {
                navigate(`/events/${info.event.id}`);
              }
            }}
            eventContent={renderEvent}
            dayHeaderFormat={{ weekday: "long" }}
          />
        </div>
        <HoldModal
          showModal={showAddHold}
          setShowModal={setShowModal}
          selectedDates={selectedDates}
        />
      </div>
    </>
  );
};
