import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import * as yup from "yup"
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import {
  FormBuilder,
  Input,
  TextInput,
  Datepicker,
  FormSelect,
  FormMultiSelect,
  selectOptionToValue
} from "@/components/Form"
import { stateChoices } from "@/utils/states"
import { createEvent } from "@/queries/events"
import { getVenues, getVenuesSettings } from "@/queries/venues"
import { getArtists, getArtistsSettings } from "@/queries/artists"
import { BreadCrumbs } from '@/components/BreadCrumbs'
import Button from '@/components/Button'

const Errors = (props) => {
  const { errors, setErrors } = props
  if (errors.length === 0) {
    return null
  }
  return (
    <div>
      <div className="bg-red-100 border-l-4 border-red-500 text-red-700 px-4 py-3 mb-3" role="alert">
        {errors}
      </div>
      <Button onClick={() => setErrors([])}>Try Again</Button>
    </div>
  )
}

export const EventAdd = () => {
  const venues = useQuery(["venues"], () => getVenues(), getVenuesSettings)
  const artists = useQuery(["artists"], getArtists, getArtistsSettings)
  const [errors, setErrors] = useState([])
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating event")
      console.dir(data)
      data.seating_chart_id = null

      // If we don't have a date, set it to be null
      if (data.date === "") {
        data.date = null
      }

      return createEvent(data)
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["events"])
      navigate(`/events/edit/offer/${data.id}`)
    }
  })

  const addSchema = yup.object().shape({
    name: yup.string().required(),
    date: yup.string(),
    description: yup.string(),
  })

  const handleSubmit = (data) => {
    console.log("In submit")
    console.dir(data)
    data.artists = data.artists.map(artist => { return artist.value })
    data = selectOptionToValue("venue_id", data)
    data = selectOptionToValue("state", data)
    data.state = "draft"
    console.log("After option")
    console.log("data", data)

    // Handle errors
    if (data.date === "" && data.state !== "draft") {
      setErrors(["Events can only be in Draft state if they do not have a date"])
      return
    } else {
      setErrors([])
    }

    mutation.mutate(data)
  }

  // Wait for Venues and Artists to load
  if (venues.isLoading || artists.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }
  const venueOptions = venues.data.map(venue => {
    return { value: venue.id, label: venue.name }
  })
  const artistOptions = artists.data.map(artist => {
    return { value: artist.id, label: artist.name }
  })

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs links={[{ text: "Events", url: "/events" }, { text: 'Add Event' }]} />

        <h3 className="text-xl font-bold mb-4 dark:text-white">Add New Event</h3>
        {errors.length > 0 && (<Errors errors={errors} setErrors={setErrors} />)}
        {!errors.length > 0 && (
          <div className="py-6 min-w-full">
            <FormBuilder onSubmit={(data) => handleSubmit(data)} schema={addSchema}>
              <Input
                name="name"
                label="Name"
                autoFocus={true}
              />
              <Datepicker
                name="date"
                label="Date"
              />
              {/* <FormSelect
                name="state"
                label="Status"
                options={stateChoices}
                required={true}
              /> */}
              <FormSelect
                name="venue_id"
                label="Venue"
                options={venueOptions}
                required={true}
              />
              <FormMultiSelect
                name="artists"
                label="Headliner(s)"
                options={artistOptions}
                required={true}
              />
              <TextInput
                name="description"
                label="Description"
              />
              <div className="flex-inline float-right mb-12">
                <button
                  type="button"
                  onClick={() => navigate(`/events/`)}
                  className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                  Cancel
                </button>
                <Button type="submit">Add Event</Button>
              </div>
            </FormBuilder>
          </div>

        )}
      </div>
    </>
  );
};
