import dayjs from 'dayjs'
import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import classnames from 'classnames'
import * as yup from "yup"
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { FormBuilder, Input, Checkbox, Datepicker } from "@/components/Form"
import { getManifest, updateManifest, deleteEventManifest } from "@/queries/events"
import { BreadCrumbs } from '@/components/BreadCrumbs'
import Button from '@/components/Button'
import { FormProvider, useForm } from 'react-hook-form';
import { SectionTickets } from '@/views/tickets/TicketsHome'
import { usePerms } from "@/components/Auth"
import { usePageTitle } from '@/utils/pagetitle';
import { recordTickets } from '../../queries/events'

function getQueryParam() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const ManifestEdit = () => {
  const { id } = useParams()  // Manifest ID
  const manifest = useQuery(["event-manifest", id], () => getManifest(id))
  const { perms } = usePerms()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  usePageTitle(manifest.isSuccess ? `/c${String.fromCharCode(92)}${manifest.data.name}` : `/c${String.fromCharCode(92)}ave_good...`);

  const query = getQueryParam();
  const type = query.get("type"); 

  const updateMutation = useMutation({
    mutationFn: (data) => {
      if (!data.price.includes('.')) {
        data.price = data.price * 100
      }
      return type === "ticket" ? updateManifest(id, {
        ...data,
        is_offer: false,
      }) : recordTickets(manifest.data.event, id, data)
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["event-manifest", id])
      queryClient.invalidateQueries(["event-detail", manifest.data.event])
      navigate(`/events/${manifest.data.event}`)
    }
  })

  const deleteMutation = useMutation({
    mutationFn: () => {
      return deleteEventManifest(manifest.data.event, manifest.data.id)
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["event-manifest", manifest.data.event])
      queryClient.invalidateQueries(["event-detail", manifest.data.event])
      navigate(`/events/${manifest.data.event}`)
    }
  })

  // Wait for Event to load
  if (manifest.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }

  const editSchema = yup.object().shape({
    name: yup.string().required(),
    price: yup.string(),
    qty: yup.number(),
    comps: yup.number(),
    on_sale: yup.string(),
    etix: yup.number(),
    venue: yup.number(),
    fan_club: yup.number(),
    outlets: yup.number(),
    vip: yup.number(),
  })

  const handleSubmit = (data) => {
    console.dir(data)
    updateMutation.mutate(data)
  }

  let values = {
    name: manifest.data.name || "",
    price: manifest.data.price / 100 || "",
    qty: manifest.data.qty,
    comps: manifest.data.comps,
    platinum_lift: manifest.data.platinum_lift,
    etix: manifest.data.etix,
    venue: manifest.data.venue,
    fan_club: manifest.data.fan_club,
    outlets: manifest.data.outlets,
    vip: manifest.data.vip,
  }

  if (manifest.data.on_sale) {
    values.on_sale = dayjs(manifest.data.on_sale).format('YYYY-MM-DD')
  }

  // Whether or not to show the ticket form
  let showTicketForm = false;
  let ticketFormMessage = "Tickets are not on sale yet"

  if (manifest.data.on_sale && dayjs(manifest.data.on_sale) <= dayjs()) {
    showTicketForm = true
    ticketFormMessage = "Tickets are on sale"
  }

  const ticketFormDivClasses = classnames("py-6 min-w-full border",
    {
      "bg-red-200 text-center font-semibold border-red-400": !showTicketForm,
      "p-2 bg-blue-100": showTicketForm,
    })

  // Turn off ticket editing unless they are allowed by their role
  if (!perms.edit_tickets) {
    showTicketForm = false
  }


  const TicketInputRender = (
    <>
      <Input
        name="name"
        label="Name"
        autoFocus={true}
        autoComplete="one-time-code"
        placeholder="Name of ticket type: GA, Floor, Balcony 1"
      />
      <Input
        name="price"
        label="Price"
        placeholder="0.00"
      />
      <Input
        name="qty"
        label="Quantity"
        placeholder="0"
      />
      <Input
        name="comps"
        label="Comps"
        placeholder="0"
      />
      <Datepicker
        name="on_sale"
        label="On Sale"
      />
    </>
  ) 


  const SalesInputRender =  (
    <>
      <Input
        name="tm"
        label="TM"
        autoFocus={true}
        autoComplete="one-time-code"
        placeholder="0"
      />
      <Input
        name="etix"
        label="ETIX"
        placeholder="0"
      />
      <Input
        name="venue"
        label="Venue"
        placeholder="0"
      />
      <Input
        name="fan_club"
        label="Fan Club"
        placeholder="0"
      />
      <Input
        name="outlets"
        label="Outlets"
        placeholder="0"
      />
      <Input
        name="vip"
        label="VIP"
        placeholder="0"
      />
    </>
  )


  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 lg:mt-8 mx-auto md:h-screen lg:py-0
    "
      >

        <h3 className="text-xl font-bold dark:text-white">Edit Manifest {manifest.data.name}</h3>
        <div className="py-6 min-w-full">
          <FormBuilder
            defaultValues={values}
            onSubmit={(data) => handleSubmit(data)}
            schema={editSchema}
          >
            {type === "ticket" && TicketInputRender}
            {type === "sales" && manifest.data.on_sale && SalesInputRender}
            <div className="w-full flex justify-between mt-6 mb-12">
              <button
                type="button"
                className="ml-1 mr-4 rounded-md bg-red-400 px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm hover:bg-red-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                onClick={() => deleteMutation.mutate()}
              >
                Delete Manifest
              </button>
              <div className="flex-inline">
                <button
                  type="button"
                  onClick={() => navigate(`/events/${manifest.data.event}`)}
                  className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                  Cancel
                </button>
                <Button
                  disabled={type === "sales" && !manifest.data.on_sale}
                  type="submit"
                  className="bg-blue-600 hover:bg-blue-700 disabled:bg-gray-100 py-2 text-gray-100 disabled:text-gray-300 rounded-lg px-2 text-sm font-bold cursor-pointer"
                >
                  Save Manifest
                </Button>

              </div>
            </div>
          </FormBuilder>
        </div>
        {showTicketForm && (<h3 className="text-xl font-bold mb-4">Edit Ticket Counts {manifest.data.name}</h3>)}
        <div className={ticketFormDivClasses}>
          {!showTicketForm && (ticketFormMessage)}
          {showTicketForm && (
            <SectionTickets
              eventID={manifest.data.event}
              navigateOnSave={`/events/${manifest.data.event}`}
              {...manifest.data}
            />
          )}
        </div>
      </div>
    </>
  );
};
