import React, { useState, useEffect, useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Header } from "@/components/Header";
import { BreadCrumbs } from '@/components/BreadCrumbs';
import { SortedTable } from "@/components/SortedTable/SortedTable";
import { getAdminReqs, updateAdminReq, getReqAdminUsers } from '@/queries/reqs';

export const ReqAdmin = () => {
  const queryClient = useQueryClient();
  const [reqsData, setReqsData] = useState([]);

  const { data: reqs, isLoading: isReqsLoading, isError: isReqsError, error: reqsError } = useQuery(['reqs'], getAdminReqs, {
    onError: (error) => console.error('Error fetching reqs:', error)
  });

  const { data: adminUsers, isLoading: isAdminUsersLoading, isError: isAdminUsersError } = useQuery(['adminUsers'], getReqAdminUsers, {
    onError: (error) => console.error('Error fetching admin users:', error)
  });

  const formatReqData = useCallback((req) => ({
    ...req,
    id: String(req.id),
    title: String(req.title),
    created_by: req.created_by ? `${req.created_by.first_name} ${req.created_by.last_name}`.trim() : '',
    assigned_to: req.assigned_to ? String(req.assigned_to.id) : '',
    assigned_to_name: req.assigned_to ? `${req.assigned_to.first_name} ${req.assigned_to.last_name}`.trim() : '',
    status: String(req.status),
    created_at: String(req.created_at),
    active: String(req.active),
    admin_comments: String(req.admin_comments || '')
  }), []);

  useEffect(() => {
    if (reqs) {
      setReqsData(reqs.map(formatReqData));
    }
  }, [reqs, formatReqData]);

  const updateReqMutation = useMutation(updateAdminReq, {
    onSuccess: (updatedReq) => {
      setReqsData(prevData => 
        prevData.map(req => 
          req.id === updatedReq.id ? formatReqData(updatedReq) : req
        )
      );
      queryClient.invalidateQueries(['reqs']);
    },
  });

  const handleFieldChange = (req, field, value) => {
    let updatedValue = value;

    if (field === 'assigned_to') {
      updatedValue = value === '' ? null : parseInt(value, 10);
    }

    const updatedFields = { id: req.id, [field]: updatedValue };
    
    // Optimistically update the UI
    setReqsData(prevData => 
      prevData.map(item => {
        if (item.id === req.id) {
          if (field === 'assigned_to') {
            const selectedUser = adminUsers.find(user => user.id === updatedValue);
            return { 
              ...item, 
              [field]: value,
              assigned_to_name: selectedUser ? `${selectedUser.first_name} ${selectedUser.last_name}`.trim() : ''
            };
          }
          return { ...item, [field]: value };
        }
        return item;
      })
    );

    // Send the update to the server
    updateReqMutation.mutate(updatedFields);
  };

  const handleCommentsBlur = (req, value) => {
    handleFieldChange(req, 'admin_comments', value);
  };

  const formatCell = (propertyName, row) => {
    switch (propertyName) {
      case 'status':
        return (
          <select
            value={row[propertyName]}
            onChange={(e) => handleFieldChange(row, propertyName, e.target.value)}
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="open">Open</option>
            <option value="in_progress">In Progress</option>
            <option value="closed">Closed</option>
          </select>
        );
      case 'assigned_to':
        return (
          <select
            value={row[propertyName] || ''}
            onChange={(e) => handleFieldChange(row, propertyName, e.target.value)}
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="">Unassigned</option>
            {adminUsers && adminUsers.map(user => (
              <option key={user.id} value={user.id}>
                {`${user.first_name} ${user.last_name}`}
              </option>
            ))}
          </select>
        );
      case 'active':
        return (
          <select
            value={row[propertyName]}
            onChange={(e) => handleFieldChange(row, propertyName, e.target.value)}
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        );
      case 'admin_comments':
        return (
          <textarea
            value={row[propertyName] || ''}
            onChange={(e) => {
              const newValue = e.target.value;
              setReqsData(prevData => 
                prevData.map(item => 
                  item.id === row.id ? { ...item, [propertyName]: newValue } : item
                )
              );
            }}
            onBlur={(e) => handleCommentsBlur(row, e.target.value)}
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        );
      case 'created_at':
        return new Date(row.created_at).toLocaleString();
      default:
        return (
            <a className="hover:underline" href={`/reqs/${row.id}`}>
            {row[propertyName]}
            </a>
        )
    }
  };

  if (isReqsLoading || isAdminUsersLoading) return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  if (isReqsError) return <div>Error loading requests: {reqsError.message}</div>;
  if (isAdminUsersError) return <div>Error loading admin users</div>;

  const headers = [
    { header: 'ID', accessor: 'id', type: 'number' },
    { header: 'Title', accessor: 'title', type: 'string' },
    { header: 'Created By', accessor: 'created_by', type: 'string' },
    { header: 'Status', accessor: 'status', type: 'string' },
    { header: 'Created At', accessor: 'created_at', type: 'string' },
    { header: 'Assigned To', accessor: 'assigned_to', type: 'string' },
    { header: 'Active', accessor: 'active', type: 'string' },
    { header: 'Admin Comments', accessor: 'admin_comments', type: 'string' },
  ];

  return (
    <>
      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0">
        <BreadCrumbs links={[{ text: "Admin Requests", url: "/reqs/admin" }]} />
        <SortedTable
          data={reqsData}
          headers={headers}
          title=""
          formatCell={formatCell}
          hasFilter={true}
          hasHeader={true}
          hasSorting={true}
          initiallyOpen={true}
          initialSortKey="id"
          initialSortOrder="asc"
          customSort={(key, a) => {
            if (key === 'assigned_to') {
              return a.assigned_to_name.toLowerCase();
            }
            if (key === 'created_at') {
              return new Date(a.created_at).getTime();
            }
            return typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
          }}
        />
      </div>
    </>
  );
};
