import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
} from "./base";

export const getPeopleSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};

export const getPeople = async () => {
  const url = `/api/people/`;
  return await getApiRequest(url);
};

export const getStaff = async () => {
  const url = `/api/people/staff/`;
  return await getApiRequest(url);
};

export const getPerson = async (id) => {
  const url = `/api/people/${id}/`;
  return await getApiRequest(url);
};

export const updatePerson = async (id, data) => {
  const url = `/api/people/${id}/`;
  return await putApiRequest(url, data);
};

export const createPerson = async (data) => {
  const url = `/api/people/`;
  return await postApiRequest(url, data);
};

export const getPersonEventsRoles = async (id, isPast) => {
  const url = `/api/people/${id}/events-roles/`;
  return await getApiRequest(url);
};
