import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import * as yup from "yup"
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { FormBuilder, Input, TextInput, Datepicker, FormSelect } from "@/components/Form"
import { createVendorWireInfo } from "@/queries/vendors"
import { getVendor } from "@/queries/vendors"
import { BreadCrumbs } from '@/components/BreadCrumbs'
import Button from '@/components/Button'

export const VendorWireInfoAdd = () => {
  const { id } = useParams()  // Vendor ID
  const vendor = useQuery(["vendor-detail", id], () => getVendor(id))
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating wireinfo")
      console.dir(data)
      return createVendorWireInfo(id, data)
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["vendor", "wireinfos", id])
      navigate(`/vendors/${id}`)
    }
  })

  const addSchema = yup.object().shape({
    description: yup.string().required(),
    bank_name: yup.string().required(),
    bank_address: yup.string().required(),
    account_number: yup.string(),
    routing_number: yup.string(),
    swift: yup.string(),
  })

  const handleSubmit = (data) => {
    console.log("In submit")
    console.dir(data)
    mutation.mutate(data)
  }

  // Wait for Event to load
  if (vendor.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs links={[{ text: "Vendors", url: "/vendors" }, { text: 'Add Vendor WireInfo' }]} />

        <h3 className="text-xl font-bold dark:text-white">Add New WireInfo for {vendor.data.name}</h3>
        <div className="py-6 min-w-full">
          <FormBuilder onSubmit={(data) => handleSubmit(data)} schema={addSchema}>
            <Input
              name="description"
              label="Description"
              autoFocus={true}
            />
            <Input
              name="bank_name"
              label="Bank Name"
            />
            <Input
              name="bank_address"
              label="Bank Address"
            />
            <TextInput
              name="account_number"
              label="Account Number"
            />
            <TextInput
              name="routing_number"
              label="ABA Routing Number"
            />
            <TextInput
              name="swift"
              label="Swift"
            />
            <div className="flex-inline float-right mb-12">
              <button
                type="button"
                onClick={() => navigate(`/vendors/${id}`)}
                className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                Cancel
              </button>
              <Button type="submit">Add WireInfo</Button>
            </div>
          </FormBuilder>
        </div>
      </div>
    </>
  );
};
