import React, { useEffect, useState } from 'react';
import { Header } from "@/components/Header"
import { BreadCrumbs } from '@/components/BreadCrumbs'
import { useNavigate } from 'react-router-dom';
import { getReqs, getReqsSettings } from '@/queries/reqs';
import { useQuery } from '@tanstack/react-query';
import Button from '@/components/Button'
import ReqAdd from './ReqAdd.jsx';
import { usePageTitle } from '@/utils/pagetitle';

export const ReqHome = () => {
  const navigate = useNavigate();
  const { data: reqs, isLoading, error } = useQuery(['reqs'], getReqs, getReqsSettings);
  usePageTitle(`/c${String.fromCharCode(92)}reqs`);

  if (isLoading) return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0">
          <BreadCrumbs links={[{ text: "IT Requests", url: "/reqs" }]} />
        
        <div className="flex-grow flex flex-col items-center justify-center px-6">
          <Button
            className="bg-white shadow-md rounded-lg p-4 mb-8 hover:bg-gray-100 cursor-pointer"
            onClick={() => navigate('/reqs/add')}
          >
            Create IT Request
          </Button>
          
          <div className="w-full max-w-4xl">
            {isLoading ? (
              <p className="text-gray-500 text-center">Loading requests...</p>
            ) : error ? (
              <p className="text-red-500 text-center">Error getting requests: {error.message}</p>
            ) : (
              <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {reqs?.length > 0 ? (
                  reqs.map((req) => (
                    <li
                      key={req.id}
                      className="bg-white shadow-md rounded-lg p-4 hover:bg-gray-100 cursor-pointer"
                      onClick={() => navigate(`/reqs/${req.id}`)}
                    >
                      <div className="text-lg font-semibold">{req.title}</div>
                      <div className="text-gray-700 mt-2">{req.description}</div>
                      <div className="text-sm font-medium mt-3 italic">Status: {req.status}</div>
                    </li>
                  ))
                ) : (
                  <p className='dark:text-white text-center col-span-full'>No requests found.</p>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
