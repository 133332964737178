import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  StarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import { PPeopleIcon } from "@/components/People/PeopleIcon";

import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import Button from "@/components/Button";
import MammothIcon from "@/components/MammothIcon";

import styles from "./People.module.css";

const PeopleRow = (props) => {
  const { person, toggleMainPOC } = props;
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  let showAddress = false;
  if (person.address_line_1 || person.city || person.state || person.zipcode) {
    showAddress = true;
  }

  return (
    <>
      <div
        className={`${styles.peopleChevronAccordionDiv} bg-blue-500 dark:bg-[#374150] border border-gray-200 text-gray-900 dark:text-white`}
      >
        <h2
          id="accordion-collapse-heading-{person.id}"
          onClick={() => toggleOpen()}
        >
          {open ? (
            <ChevronDownIcon className={styles.chevronAccordionUp} />
          ) : (
            <ChevronUpIcon className={styles.chevronAccordionDown} />
          )}
          <button
            type="button"
            className={styles.customGridClass}
            data-accordion-target="#accordion-collapse-body-1"
            aria-expanded="true"
            aria-controls="accordion-collapse-body-1"
          >
            <div>
              {person.main_contact ? (
                <StarIcon className="w-6 h-6 text-yellow-300" />
              ) : (
                <></>
              )}
            </div>
            <div>
              {person.is_mammoth ? (
                  <>
                <MammothIcon className="w-8 h-8 text-indigo-300" />
                  </>
              ) : (
                <></>
              )}
            </div>
      <div>
                <a
                  target="_blank"
                  href={`/people/${person.people_id}`}
                  className="text-indigo-300 underline hover:text-blue-600 flex relative"
                >
                <PPeopleIcon person={person} />
                </a>
      </div>
            {/* White rounded pill box for Roles below here */}
            <div className={styles.peopleRoleBox}>{person.role_name}</div>
            {/* Role Names below here */}
            <div className={styles.peopleNames}>
              <div>{person.first_name}</div>
              <div>{person.last_name}</div>

              {person.email ? (
                <a
                  href={`mailto:${person.email}`}
                  className="text-blue-400 underline hover:text-blue-600"
                >
                  <EnvelopeIcon className="w-6 h-6" />
                </a>
              ) : (
                <span></span>
              )}
            </div>
            {person.phone ? (
              <a
                href={`tel:${person.phone}`}
                className="text-blue-400 underline hover:text-blue-600 flex relative"
              >
                <PhoneIcon className="w-6 h-6" />
              </a>
            ) : (
              <span></span>
            )}
          </button>
        </h2>
      </div>
      {open && (
        <div
          id="accordion-collapse-body-1"
          aria-labelledby="accordion-collapse-heading-{person.id}"
        >
          <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-[#181818]">
            <div className="flex">
            {person.role_name && (
              <p className="mb-2 text-gray-500 dark:text-gray-400">
                <strong>Role</strong> {person.role_name}{" "}
                {person.role_description}
              </p>  
            )}
            </div>

            {person.email && (
              <p className="mb-2 text-gray-500 dark:text-gray-400">
                <strong>Email</strong> {person.email}
              </p>
            )}
            {person.phone && (
              <p className="mb-2 text-gray-500 dark:text-gray-400">
                <strong>Phone</strong> {person.phone}
              </p>
            )}
            {person.company_name && (
              <p className="mb-2 text-gray-500 dark:text-gray-400">
                <strong>Company:</strong> {person.company_name}
              </p>
            )}
            {person.role_description && (
              <p className="mb-2 text-gray-500 dark:text-gray-400">
                <strong>Role Description:</strong> {person.role_description}
              </p>
            )}
            {person.description && (
              <p className="mb-2 text-gray-500 dark:text-gray-400">
                <strong>Description:</strong> {person.description}
              </p>
            )}
            {person.website_url && (
              <p className="mb-2 text-gray-500 dark:text-gray-400">
                <strong>Website:</strong>{" "}
                <a href="{person.website_url}" target="_blank" rel="noreferrer">
                  {person.website_url}
                </a>
              </p>
            )}
            <div className="my-6 w-full">
              <button
                type="button"
                onClick={() => {
                  toggleMainPOC.mutate(person.id);
                }}
                className="mb-4 float-right inline-flex items-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
              >
                {!person.main_contact && (
                  <span>Make Main Point of Contact</span>
                )}
                {person.main_contact && (
                  <span>Remove Main Point of Contact</span>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const People = (props) => {
  const { people, toggleMainPOC, itemType, addURL, editable } = props;
  const navigate = useNavigate();

  if (people.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }


  return (
    <>
      <div className="w-full flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold dark:text-gray-200">People</h3>
        <span>
          {editable && (
            <Button onClick={() => navigate(addURL)}>Add Person</Button>
          )}
        </span>
      </div>
      <div id="accordion-collapse">
        {people.data.map((person) => (
          <PeopleRow
            key={person.id}
            person={person}
            toggleMainPOC={toggleMainPOC}
          />
        ))}
        {people.data.length === 0 && (
          <div className="w-full p-5 font-medium text-left text-gray-500 border border-gray-200 text-center">
            <em>No people defined for this {itemType}</em>
          </div>
        )}
      </div>
    </>
  );
};

export default People;

