import React, { useState, useEffect } from "react";

interface Column {
  title: string;
  accessor: string;
  textAlign?: "start" | "center" | "end";
  className?: string;
  editable?: boolean;
  render?: (data: any, row: Record<string, any>) => JSX.Element;
  onBlur?: (value: any, rowIndex: number) => void;
}

interface CustomRow {
  render: () => JSX.Element;
}

interface TableProps {
  className?: string;
  flexClassNames?: string;
  flex?: boolean;
  grid?: boolean;
  textCenter?: boolean;
  tableTitle: string;
  columns: Column[];
  data: Record<string, any>[];
  totalsRow?: {
    title: string;
    textAlign?: "start" | "center" | "end";
    values: (string | number)[];
  };
  customRows?: CustomRow[];
  hideHeaders?: boolean;
  onChange?: (rowIndex: number, accessor: string, value: any, row: any) => void;
  onCellBlur?: (
    rowIndex: number,
    accessor: string,
    value: any,
    row: any
  ) => void;
}

interface TableRowProps {
  columns: Column[];
  rowData: Record<string, any>;
  onBlur?: (field: string, value: any) => void;
  onChange?: (field: string, value: any) => void;
}

const TableRow: React.FC<TableRowProps> = ({
  columns,
  rowData,
  onBlur,
  onChange,
}) => {
  const [localRowData, setLocalRowData] = useState(rowData);

  const handleInputChange = (field: string, value: string) => {
    setLocalRowData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    if (onChange) {
      onChange(field, value);
    }
  };

  return (
    <div className="grid grid-cols-5 border-b-2 border-black">
      {columns.map((col, colIndex) => (
        <div
          key={colIndex}
          className={`p-2 ${col.textAlign ? `text-${col.textAlign}` : ""} ${
            col.className || ""
          }`}
        >
          {col.editable ? (
            <input
              type="text"
              value={localRowData[col.accessor]}
              onBlur={(e) => {
                if (onBlur) {
                  onBlur(col.accessor, e.target.value);
                }
              }}
              onChange={(e) => handleInputChange(col.accessor, e.target.value)}
              className="w-full bg-input-blue text-black border border-gray-300 rounded"
            />
          ) : (
            <span className="block">{localRowData[col.accessor]}</span>
          )}
        </div>
      ))}
    </div>
  );
};

const Table: React.FC<TableProps> = ({
  className,
  flex,
  flexClassNames,
  grid,
  textCenter,
  tableTitle,
  columns,
  data,
  totalsRow,
  customRows,
  hideHeaders = false,
  onChange,
  onCellBlur,
}) => {
  const [localData, setLocalData] = useState(data);

  // Update localData whenever the data prop changes
  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const handleInputChange = (
    rowIndex: number,
    accessor: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newData = [...localData];
    newData[rowIndex][accessor] = event.target.value;
    setLocalData(newData);

    if (onChange) {
      onChange(rowIndex, accessor, event.target.value);
    }
  };

  const handleInputBlur = (
    rowIndex: number,
    accessor: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const column = columns.find((col) => col.accessor === accessor);
    if (column?.onBlur) {
      column.onBlur(event.target.value, rowIndex, data[rowIndex]);
    } else if (onCellBlur) {
      onCellBlur(rowIndex, accessor, event.target.value, data[rowIndex]);
    }
  };

  const renderCell = (
    rowIndex: number,
    col: Column,
    value: any,
    row: Record<string, any>
  ) => {
    if (col.render) {
      return col.render(value, row);
    }

    if (col.editable) {
      return (
        <input
          className={`bg-input-blue text-${col.textAlign || "left"} w-full`}
          value={value}
          onChange={(event) => handleInputChange(rowIndex, col.accessor, event)}
          onBlur={(event) => handleInputBlur(rowIndex, col.accessor, event)}
        />
      );
    }

    return <span className={`block ${col.className || ""}`}>{value}</span>;
  };

  return (
    <div
      className={`${textCenter ? "text-center" : ""} border-2 border-black ${className}`}
    >
      <h2 className="text-center font-bold text-blue-600">{tableTitle}</h2>
      <div
        className={`${grid ? "grid" : ""} ${flex ? "flex" : ""} border-t-2 border-black`}
        style={
          grid
            ? {
                gridTemplateColumns: `repeat(${columns.length}, minmax(0, 1fr))`,
              }
            : undefined
        }
      >
        {!hideHeaders &&
          columns.map((col, colIndex) => (
            <div
              key={colIndex}
              className={`${flexClassNames || ""} ${col.className || ""} text-${col.textAlign}`}
            >
              <h3 className="bg-gray-200 font-bold border-b-2 border-black">
                {col.title}
              </h3>
              {localData.map((row, rowIndex) => (
                <div key={`${colIndex}-${rowIndex}`}>
                  {renderCell(rowIndex, col, row[col.accessor], row)}
                </div>
              ))}
            </div>
          ))}
        {hideHeaders &&
          localData.map((row, rowIndex) => (
            <div key={`row-${rowIndex}`} className="w-full">
              {columns.map((col, colIndex) => (
                <div
                  key={`${colIndex}-${rowIndex}`}
                  className={`${flexClassNames || ""} ${col.className || ""} text-${col.textAlign}`}
                >
                  {renderCell(rowIndex, col, row[col.accessor], row)}
                </div>
              ))}
            </div>
          ))}
      </div>
      {customRows &&
        customRows.map((customRow, index) => (
          <div key={`custom-${index}`} className="w-full">
            {customRow.render()}
          </div>
        ))}
      {totalsRow && (
        <div
          className={`grid border-t-2 text-${totalsRow.textAlign} border-black`}
          style={{
            gridTemplateColumns: `repeat(${totalsRow.values.length + 1}, minmax(0, 1fr))`,
          }}
        >
          <span className="bg-gray-200 font-bold">{totalsRow.title}</span>
          {totalsRow.values.map((value, index) => (
            <span className="bg-gray-200 w-full" key={index}>
              {value}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export { Table, TableRow };
