import React from 'react';

export const PersonImageDisplay = ({ person }) => {
  const getImageUrl = () => {
    if (person?.data?.user?.photo) {
      return person.data.user.photo;
    }
    if (person?.data?.people_images && person.data.people_images.length > 0) {
      return person.data.people_images[0].image;
    }
    return null;
  };

  const imageUrl = getImageUrl();

  return (
    <div>
      {imageUrl ? (
        <img
          width="200"
          height="200"
          src={imageUrl}
          alt="Person photo"
          className="object-cover"
        />
      ) : (
        <div className="w-[200px] h-[200px] bg-gray-200 flex items-center justify-center text-gray-500">
          No photo available
        </div>
      )}
    </div>
  );
};

export default PersonImageDisplay;
