import React, { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query"
import { getMe, userQuerySettings } from "@/queries/auth"



export const LoginCheck = (props) => {
  const [ftue_user] = useState(null);
  const user = useQuery(["me"], getMe, userQuerySettings)
  const navigate = useNavigate()
  useEffect(() => {
    if (user.data.ftue === true ) {
        console.log("---> Sending user to FTUE")
        navigate("/ftue")
    } else {
      console.log("---> Sending logged in user to dashboard")
      navigate("/dashboard")
    }
  }, [user])
}

export default LoginCheck;
