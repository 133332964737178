import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)
dayjs.extend(customParseFormat);

export const appDayjs = dayjs

export const displayDateTime = (dt) => {
  return dayjs(dt).format('MMMM D, YYYY hh:MM A z')
}


export const localTime = (time) => {
  // This takes a 24 hour time in HH:MM::SS and returns 3:00 PM

  if (!time) return ''

  let [hours, minutes, seconds] = time.split(':')
  let amPm = 'AM'
  if (hours > 12) {
    hours = hours - 12
    amPm = 'PM'
  }
  return `${parseInt(hours)}:${minutes} ${amPm}`
}

/**
 * Function to format an inputted string into a valid HH:MM AM/PM time string
 * @param {*} input a string (in any format)
 * @returns Fomratted HH:MM AM/PM string, or "invalid input" if unable to format
 */
export function formatTimeString(input) {

  let normalizedInput = input.trim().toLowerCase();

  let format = 'H:mm';

  if (/^\d{1,2}pm$/.test(normalizedInput)) { // e.g., "6pm"
    normalizedInput = normalizedInput.replace('pm', ':00pm');
    format = 'h:mma';
  } else if (/^\d{1,2}am$/.test(normalizedInput)) { // e.g., "6am"
    normalizedInput = normalizedInput.replace('am', ':00am');
    format = 'h:mma';
  } else if (/^\d{1,2}$/.test(normalizedInput)) { // e.g., "6"
    normalizedInput += ':00';
    format = 'H:mm';
  } else if (/^\d{1,2}:\d{2}$/.test(normalizedInput)) { // e.g., "6:03"
    format = 'H:mm';
  } else if (/^\d{1,2}:\d{2} (am|pm)$/.test(normalizedInput)) { // e.g., "6:03 am"
    format = 'h:mm a';
  } 

  return input === "" ? null : dayjs(normalizedInput, format).format('h:mm A');
}

/**
 * Converts a time string from "HH:MM AM/PM" format to "HH:MM:SS" format in 24-hour time.
 * 
 * @param {string} input - The time string in "HH:MM AM/PM" format.
 * @returns {string} The time string converted to "HH:MM:SS" format in 24-hour time.
 */
export function formatToHHMMSS(input) {
  const [time, modifier] = input.split(' ');
  let [hours, minutes] = time.split(':');

  if (modifier === 'PM' && hours !== '12') {
    hours = parseInt(hours, 10) + 12;
  } else if (modifier === 'AM' && hours === '12') {
    hours = '00';
  }

  return `${hours}:${minutes}:00`;
}

export const unUTC = (date) => {
  const toDate = new Date(date)
  const invalidDate = toDate?.toString() === "Invalid Date"
  if (invalidDate || !date) return date
  return date.toString().replace('Z', '')
}

export default displayDateTime
