import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import Button from "@/components/Button";
import { getVendors, getVendorsSettings } from "@/queries/vendors";
import { usePageTitle } from "@/utils/pagetitle";
import { ToggleSwitch } from "@/components/Button/ToggleSwitch";
import { SortedTable } from "../../components/SortedTable/SortedTable";

const VendorRow = (props) => {
  const { vendor } = props;
  const navigate = useNavigate();

  return (
    <tr
      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      onClick={() => navigate(`/vendors/${vendor.id}`)}
    >
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {vendor.name}
      </th>
      <td className="px-6 py-4">{vendor.poc_name}</td>
      <td className="px-6 py-4">{vendor.poc_email}</td>
      <td className="px-6 py-4">{vendor.poc_phone}</td>
      <td className="px-6 py-4">{vendor.future_events}</td>
    </tr>
  );
};

const FilteredVendorsTable = ({
  vendors,
  headers,
  total,
  canEdit,
  title,
  handleEditClick,
}) => {
  const navigate = useNavigate();

  const formatData = (vendors) => {
    // calculate total for each manifest
    return (
      vendors &&
      vendors.map((manifest) =>
        Object.assign(manifest, { total: manifest.price * manifest.qty })
      )
    );
  };

  const handleFormatCell = (accessor, rowData, rowIndex) => {
    if (accessor === "on_sale") {
      return rowData["on_sale"]
        ? dayjs(rowData["on_sale"]).format("MMM D, YYYY")
        : "No Date";
    }
    // return nothing for all other values
  };

  const handleSort = (key, item) => {
    if (key === "total") {
      return item["price"] * item["qty"];
    } else {
      return item[key];
    }
  };

  return (
    <SortedTable
      total={total}
      data={formatData(vendors)}
      headers={headers}
      customSort={handleSort}
      formatCell={handleFormatCell}
      onEditClick={canEdit ? handleEditClick : null}
      handleRowClick={(row, index) => navigate(`/vendors/${row.id}`)}
      hasSorting
      hasFilter
    />
  );
};

export const VendorsHome = () => {
  //const vendors = useQuery(["vendors"], () => getVendors(), getVendorsSettings)
  const {
    data: vendors,
    isLoading,
    isSuccess,
  } = useQuery(["vendors"], getVendors, getVendorsSettings);

  const [isAgency, setIsAgency] = useState(false);
  const navigate = useNavigate();
  const [isFadingOut, setIsFadingOut] = useState(false);
  usePageTitle(
    isSuccess
      ? `/c${String.fromCharCode(92)}vendors`
      : `/c${String.fromCharCode(92)}ave_good...`
  );
  const filteredVendors = (vendors || []).filter(
    (vendor) => vendor.is_agency === isAgency
  );

  useEffect(() => {
    if (isLoading) {
      setIsFadingOut(false);
    } else if (isSuccess) {
      setTimeout(() => {
        setIsFadingOut(true);
      }, 1000); // Adjust the delay as needed
    }
  }, [isLoading, isSuccess]);

  return (
    <>
      {isLoading && (
        <div
          className={`fixed inset-0 z-50 flex items-center justify-center bg-welcome bg-cover bg-center transition-opacity duration-1000 ${
            isFadingOut ? "opacity-0" : "opacity-100"
          }`}
          style={{
            backgroundImage:
              "url('/static/images/welcome_to_cave_loading.jpg')",
          }}
        >
          <div className="animate-pulse text-white text-sm mt-4">
            <br /> <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br /> <br />
            <br />
            /c\
          </div>
        </div>
      )}
      <div
        className={`transition-opacity duration-1000 ${
          isLoading && !isFadingOut ? "opacity-0" : "opacity-100"
        }`}
      ></div>

      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full flex justify-between items-center">
          <BreadCrumbs links={[{ text: "Vendors", url: "/vendors" }]} />
          <span>
            <Button onClick={() => navigate("/vendors/add")}>Add Vendor</Button>
          </span>
        </div>
        <div className="my-2 w-full overflow-x-auto shadow-md">
          <section className="flex justify-center items-center mt-4">
            <h3 className="text-lg font-semibold dark:text-white mr-[.7rem]">
              Vendors
            </h3>
            <ToggleSwitch
              text=""
              isToggled={isAgency}
              onToggle={() => setIsAgency(!isAgency)}
            />
            <h3 className="text-lg font-semibold dark:text-white ml-2">
              Agencies
            </h3>
          </section>
          <FilteredVendorsTable
            vendors={filteredVendors}
            headers={[
              { header: "AGENCY NAME", accessor: "name" },
              { header: "POC NAME", accessor: "poc_name" },
              { header: "POC EMAIL", accessor: "poc_email" },
              { header: "POC PHONE", accessor: "poc_phone" },
              { header: "FUTURE EVENTS", accessor: "future_events" },
            ]}
          />
        </div>
      </div>
    </>
  );
};
