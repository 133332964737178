// ToggleSwitch.js
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

const ToggleSwitch = ({ text, isOn, handleToggle, size = "md" }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(isOn);
  }, [isOn]);

  const handleClick = () => {
    setIsActive(!isActive);
    handleToggle && handleToggle(!isActive);
  };

  const containerClasses = classNames(
    'flex flex-row gap-x-2 items-center',
    {
      'scale-75': size === 'sm'
    }
  );

  const switchClasses = classNames(
    'w-10 h-6 flex items-center rounded-full p-1 cursor-pointer',
    {
      'bg-blue-400': isActive,
      'bg-gray-300': !isActive,
      'w-10 h-3': size === 'sm'
    }
  );

  const knobClasses = classNames(
    'bg-white w-[18px] h-[18px] rounded-full shadow-md',
    {
      'translate-x-[14px]': isActive && size === 'md',
//      'translate-x-[14px]': isActive && size === 'sm',
      'w-[14px] h-[14px]': size === 'sm'
    }
  );

  return (
    <div className={containerClasses}>
      <div className={switchClasses} onClick={handleClick}>
        <div className={knobClasses} />
      </div>
      {text && <p className='text-sm'>{text}</p>}
    </div>
  );
};

export { ToggleSwitch };
