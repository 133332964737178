import * as yup from "yup";
import Button from "@/components/Button";
import { useNavigate } from "react-router-dom";
import { FormBuilder, TextInput } from "@/components/Form";
import displayDateTime from "@/utils/datetime";

const Comment = (props) => {
  const { comment } = props;

  return (
    <div className="w-full border border-1 text-xl mt-4 rounded rounded-lg dark:bg-white">
      <div className="p-4">{comment.body}</div>
      <div className="p-2 text-sm bg-gray-200 text-right rounded-br-lg rounded-bl-lg">
        <span className="mr-4 font-semibold text-gray-600">
      {/*           {comment.user.first_name} {comment.user.last_name} */}
        </span>
        <span className="text-gray-500">
      {/* {displayDateTime(comment.created)} */}
        </span>
      </div>
    </div>
  );
};

const CommentDisplay = (props) => {
  const { comments, handleAddComment } = props;
  const navigate = useNavigate();

  // Comments
  const commentSchema = yup.object().shape({
    body: yup.string().required(),
  });

  return (
    <>
      <div className="mb-6 border-t-[1px] border-[#595959] pt-[1rem]">
        <div className="w-full flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold dark:text-gray-200">Notes</h3>
        </div>
        <div className="my-2">
          {comments.isLoading && (
            <div className="dark:text-white">Loading...</div>
          )}
          {comments.isSuccess &&
            comments.data.map((comment, index) => (
              <Comment key={index} comment={comment} />
            ))}
          {comments.isSuccess && !comments.data.length && (
            <div className="dark:text-white">No notes found</div>
          )}
        </div>
      </div>
      <div className="text-xl">
        <FormBuilder
          onSubmit={handleAddComment}
          schema={commentSchema}
          reset={true}
        >
          <TextInput
            name="body"
            label=""
            placeholder="Enter your note here..."
          />
          <div className="float-center">
            <Button type="submit">Add Note</Button>
          </div>
        </FormBuilder>
      </div>
    </>
  );
};

export default CommentDisplay;
