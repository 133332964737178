import { useEffect, useState } from "react"
import { useLocation } from 'react-router-dom';

import { SunIcon, MoonIcon } from '@heroicons/react/24/outline'

const isDark = () => {
  if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    return true
  } else {
    return false
  }
}

const determineIcon = (dark) => {
  return dark ? MoonIcon : SunIcon;
}

const applyTheme = (dark, location) => {
  const forceLightModePattern = /^\/events\/edit\/offer\/\d+$/; // Regular expression pattern

  if (forceLightModePattern.test(location.pathname)) {
    // Force light mode for the matching URL pattern
    document.documentElement.classList.remove('dark');
  } else {
    // Apply the theme based on the dark mode setting
    if (dark) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }
}

export const DarkToggle = () => {
  const [dark, setDark] = useState(isDark())
  const location = useLocation();

  let Icon = determineIcon(dark)

  useEffect(() => {
    const forceLightModePattern = /^\/events\/edit\/offer\/\d+$/;
    const shouldForceLightMode = forceLightModePattern.test(location.pathname);

    if (shouldForceLightMode) {
      setDark(false);
      applyTheme(false, location);
    } else {
      let isDark = localStorage.getItem('color-theme') === 'dark';
      setDark(isDark);
      applyTheme(isDark, location);
    }
  }, [location]);

  useEffect(() => {
    let isDark = localStorage.getItem('color-theme') === 'dark';
    setDark(isDark);
    applyTheme(isDark, location);
  }, [])

  const handleButtonPress = () => {
    const newDark = !dark;
    setDark(newDark);
    applyTheme(newDark, location);
    localStorage.setItem('color-theme', newDark ? 'dark' : 'light');
  }

  return (
    <>
      <button
        type="button"
        data-dropdown-toggle="apps-dropdown"
        className="p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700"
      >
        <span className="sr-only">View notifications</span>
        <Icon onClick={handleButtonPress} className="w-6 h-6 hover:text-blue-500" />
      </button>
    </>
  );
};
