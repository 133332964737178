import classnames from 'classnames'
import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import * as yup from "yup"
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useFormContext } from "react-hook-form";
import { FormBuilder, Input, TextInput, FormSelect, selectOptionToValue } from "@/components/Form"
import { BreadCrumbs } from '@/components/BreadCrumbs'
import Button from '@/components/Button'
import { getArtist, getArtistRoles, createArtistPeople } from "@/queries/artists"

const PeopleResult = (props) => {
  const { person, selected, setSelected } = props
  let personID = person.id
  personID = personID.replace("people-", "")

  const classNames = classnames("mt-2 border border-1 border-blue-200 p-2 cursor-pointer", {
    "hover:bg-blue-200": selected != personID,
    "hover:bg-green-300": selected == personID,
    "bg-green-200": selected == personID,
  })

  const handleClick = () => {
    if (selected == personID) {
      setSelected(null)
    } else {
      setSelected(personID)
    }
  }

  return (
    <div className={classNames} onClick={() => handleClick()}>
      <h1 className='dark:text-white'>{person.title}</h1>
      <h2 className="text-gray-500">{person.description}</h2>
    </div >
  )
}

const ExistingPeopleResults = (props) => {
  const { selected, setSelected } = props
  const [results, setResults] = useState(null)
  const [search, setSearch] = useState("")
  const { formState, watch } = useFormContext();

  useEffect(() => {
    const subscription = watch(async (values, { name, type }) => {
      let query = null

      if (values.search) {
        query = values.search
      }

      setSearch(query)
    })

    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (!search) {
      return
    }
    const url = new URL(`${window.location.origin}/api/search/people/`)
    url.searchParams.append("q", search)
    fetch(url)
      .then(response => response.json())
      .then(data => {
        setResults(data.hits)
      })
  }, [search])

  if (!results) {
    return (
      <div className="w-full">
        <span className="text-center dark:text-white">No similar people found</span>
      </div>
    )
  }

  return (
    <div>
      {results.map((person, index) => (
        <PeopleResult
          key={index}
          person={person}
          selected={selected}
          setSelected={setSelected}
        />
      ))}
    </div>
  )
}

export const ArtistPeopleAdd = () => {
  const { id } = useParams()
  const artist = useQuery(["artist-detail", id], () => getArtist(id))
  const roles = useQuery(["artist-roles"], () => getArtistRoles())
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [selected, setSelected] = useState(null)

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating person")
      console.dir(data)

      return createArtistPeople(id, data)
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["people"])
      queryClient.invalidateQueries(["artist", "people"])
      navigate(`/artists/${id}`)
    }
  })

  if (artist.isLoading || roles.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }
  const updateSchema = yup.object().shape({
    role_id: yup.object().required(),
    description: yup.string(),
    main_contact: yup.bool(),
  })

  const handleSubmit = (data) => {
    console.log("In submit")
    console.dir(data)
    data = selectOptionToValue("role_id", data)
    data["people_id"] = selected
    mutation.mutate(data)
  }

  const ArtistRoleChoices = roles.data.map((role) => {
    if (role.active == true) {
      return { value: role.id, label: role.name }
    }
  })

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs links={[{ text: "Artists", url: "/artists" }, { text: artist.data.name }]} />

        <h3 className="text-xl font-bold mb-4 dark:text-white">Add Person To Artist {artist.data.name}</h3>
        <FormBuilder onSubmit={(data) => handleSubmit(data)} schema={updateSchema}>
          <div className="flex">
            <div className="w-3/4">
              <div className="mb-8 bg-blue-100 dark:bg-gray-800 rounded-lg p-2">
                <Input
                  name="search"
                  label="Search"
                  placeholder="Enter name, company or email to find the person"
                  autoFocus={true}
                />
              </div>
              {selected && (
                <>
                  <p className="dark:text-white">Add the selected Person to Artist {artist.data.name}</p>
                  <FormSelect
                    name="role_id"
                    label="Role"
                    options={ArtistRoleChoices}
                  />
                  <TextInput
                    name="description"
                    label="Role Description"
                  />
                </>
              )}
              <div className="flex-inline float-right mb-12">
                <button
                  type="button"
                  onClick={() => navigate(`/artists/${artist.data.id}`)}
                  className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                  {!selected ? 'Return to Artist' : 'Cancel and return to Artist'}
                </button>
                {selected && (
                  <Button
                    type="submit"
                  >
                    Add Person
                  </Button>

                )}
              </div>
            </div>

            <div className="ml-4 mb-12 p-2 border border-1 border-blue-200 w-1/2">
              <h3 className="font-semibold text-gray-400 dark:text-gray-200">Possible Existing People</h3>
              <ExistingPeopleResults
                setSelected={setSelected}
                selected={selected}
              />
            </div>
          </div>
        </FormBuilder>
      </div>
    </>
  );
};
