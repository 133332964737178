import { Navigate, Outlet } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { getMe, getPerms, userQuerySettings } from "@/queries/auth"

export const ProtectedLayout = () => {
  const user = useQuery(["me"], getMe, userQuerySettings)
  const perms = useQuery(["perms"], getPerms, userQuerySettings)

  if (user.isLoading || perms.isLoading) {
    return <div>.cave.Loading...</div>
  }

  if (user.isError || perms.isError) {
    console.log("Authentication error", user.error)
    return <Navigate to="/login" />
  }

  return (
    <>
      <Outlet context={{ user, perms }} />
    </>
  )

}
