import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import * as yup from "yup"
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { FormBuilder, Input, TextInput, SaveCancel } from "@/components/Form"
import { getArtist, createArtistImage } from "@/queries/artists"
import { BreadCrumbs } from '@/components/BreadCrumbs'

export const ArtistImageAdd = () => {
  const { id } = useParams()  // Artist ID
  const artist = useQuery(["artist-detail", id], () => getArtist(id))
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [file, setFile] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating image")
      return createArtistImage(id, data)
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["artist", "images", id])
      navigate(`/artists/${id}`)
    },
    onSettled: () => setIsSubmitting(false),
  })

  const addSchema = yup.object().shape({
    name: yup.string(),
    file: yup.string(),
    description: yup.string(),
  })

  const handleSubmit = async (data) => {
    try {
      const formData = new FormData()
      formData.append("name", data.name)
      formData.append("file", file)
      formData.append("artist_id", id)
      formData.append("description", data.description)
      setIsSubmitting(true)
      mutation.mutate(formData)
    } catch (error) {
      console.error('form error: ', error);
    }
  }
      

  // Wait for Artist to load
  if (artist.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs links={[{ text: "Artists", url: "/artists" }, { text: `Edit: ${artist.data.name}`, url: `/artists/edit/${artist.data.id}` }, { text: 'Add Image' }]} />

        <h3 className="text-xl font-bold dark:text-white">Add New Image for {artist.data.name}</h3>
        <div className="py-6 min-w-full">
          <FormBuilder mode="onChange" onSubmit={(data) => handleSubmit(data)} schema={addSchema}>
            <Input
              name="name"
              label="Name"
              autoFocus={true}
              autoComplete="one-time-code"
            />
            <Input
              name="file"
              label="File"
              type="file"
              onChange={(artist) => {
                setFile(artist.target.files[0])
              }}
            />
            <TextInput
              name="description"
              label="Description"
              placeholder="Optional description"
            />
            <SaveCancel
              label="Add Image"
              cancelURL={`/artists/${id}`}
              isSubmitting={isSubmitting}
              submittingMessage="Uploading image..."
            />
          </FormBuilder>
        </div>
      </div>
    </>
  );
};
