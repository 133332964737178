import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import * as yup from "yup"
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useFormContext } from "react-hook-form";
import { FormBuilder, Input, TextInput, Checkbox } from "@/components/Form"
import { createVendor } from "@/queries/vendors"
import { BreadCrumbs } from '@/components/BreadCrumbs'
import Button from '@/components/Button'

export const VendorAdd = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating vendor")
      console.dir(data)

      return createVendor(data)
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["vendors"])
      navigate(`/vendors/`)
    }
  })

  const updateSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),
    email: yup.string().email(),
    phone: yup.string(),
    is_agency: yup.bool(),
    address_line_1: yup.string(),
    address_line_2: yup.string(),
    address_line_3: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipcode: yup.string(),
  })

  const handleSubmit = (data) => {
    console.log("In submit")
    console.dir(data)
    mutation.mutate(data)
  }

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs links={[{ text: "Vendors", url: "/vendors" }, { text: 'Add' }]} />

        <h3 className="text-xl font-bold mb-4 dark:text-white">Add New Vendor</h3>
        <div className="min-w-full">
          <FormBuilder onSubmit={(data) => handleSubmit(data)} schema={updateSchema}>
            <Input
              name="name"
              label="Name"
              autoFocus={true}
            />
            <Checkbox
              name="is_agency"
              label="Is this an Agency?"
            />
            <TextInput
              name="description"
              label="Description"
            />
            <Input
              name="email"
              label="Email"
            />
            <Input
              name="phone"
              label="Phone"
            />
            <Input
              name="address_line_1"
              label="Address Line 1"
            />
            <Input
              name="address_line_2"
              label="Address Line 2"
            />
            <Input
              name="address_line_3"
              label="Address Line 3"
            />
            <Input
              name="city"
              label="City"
            />
            <Input
              name="state"
              label="State"
            />
            <Input
              name="zipcode"
              label="Zipcode"
            />
            <div className="flex-inline float-right mb-12">
              <button
                type="button"
                onClick={() => navigate(`/vendors/`)}
                className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                Cancel
              </button>
              <Button type="submit">Add Vendor</Button>
            </div>
          </FormBuilder>
        </div>
      </div>
    </>
  );
};
