import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const FilterDropdown = ({ options, onFilterUpdate, parentFilters }) => {
  const [filters, setFilters] = useState(parentFilters);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);
  const [selected, setSelected] = useState(new Date());
  const [month, setMonth] = useState(new Date());
  const dropdownRef = useRef(null);
  const calendarRef = useRef(null);
  const inputRef = useRef(null);
  const buttonRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
        setIsCalendarOpen(false);
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isCalendarOpen, isDropdownOpen]);

  const handleFieldChange = (selectedOption, filterId) => {
    setFilters(filters.map(filter => filter.id === filterId
      ? { ...filter, field: selectedOption, type: '', value: '' }
      : filter
    ));
  };

  const handleTypeChange = (selectedOption, filterId) => {
    let updatedFilters = filters.map(filter => filter.id === filterId
      ? { ...filter, type: selectedOption.value }
      : filter
    )
    setFilters(updatedFilters);

    onFilterUpdate(updatedFilters);
  };

  const handleValueChange = (e, filterId) => {
    setFilters(filters.map(filter => filter.id === filterId
      ? { ...filter, value: e.target.value }
      : filter
    ));
  };

  const handleBlur = (filterId) => {
    onFilterUpdate(filters);
  };

  const handleDateSelect = (date) => {
    setSelected(date);
    setMonth(date);
    setIsCalendarOpen(false);
    const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    let updatedFilters = filters.map(filter => filter.id === activeFilter
      ? { ...filter, value: formattedDate }
      : filter
    )
    setFilters(updatedFilters);
    onFilterUpdate(updatedFilters);
  };

  const addFilter = () => {
    setFilters([...filters, { id: Date.now(), field: null, type: '', value: '' }]);
  };

  const removeFilter = (filterId) => {
    let updatedFilters = filters.filter(filter => filter.id !== filterId)
    setFilters(updatedFilters);
    onFilterUpdate(updatedFilters)  
  };

  const filterTypeOptions = (type) => {
    switch (type) {
      case 'string':
        return [
          { value: 'contains', label: 'Contains' },
          { value: 'does not contain', label: 'Does not contain' },
          { value: 'equals', label: 'Equals' },
        ];
      case 'number':
        return [
          { value: 'equals', label: 'Equals' },
          { value: 'greater_than', label: 'Greater than' },
          { value: 'less_than', label: 'Less than' },
        ];
      case 'date':
        return [
          { value: 'is_before', label: 'Is before' },
          { value: 'is_after', label: 'Is after' },
        ];
      default:
        return [];
    }
  };

  return (
    <div className="relative p-2 pt-0 pb-4">
      <button ref={buttonRef} onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="rounded-md px-2.5 py-1.5 text-sm font-normal shadow-sm bg-blue-500 text-white flex flex-row gap-2 items-center">
        {isDropdownOpen ? 'Close Filters' : 'Open Filters'}
        {filters.length > 0 && (
          <span className="mr-1 w-4 h-4 flex items-center justify-center bg-gray-700 text-white text-xs rounded-full">
            {filters.length}
          </span>
        )}
      </button>

      {isDropdownOpen && (
        <div ref={dropdownRef} className="absolute bg-white border shadow-md p-3 mt-2 rounded min-w-[600px] z-10">
          {filters.length === 0 && <p className="text-left text-gray-500 mb-2">No filters added</p>}
          {filters.map(filter => (
            <div key={filter.id} className="flex items-center space-x-2 mb-2">
              <Select
                className="w-1/3"
                value={filter.field}
                onChange={option => handleFieldChange(option, filter.id)}
                options={options.map(option => ({ value: option.header, label: option.header, type: option.type }))}
                placeholder="Field"
              />
              <Select
                className="w-1/3"
                value={filter.type ? { value: filter.type, label: filter.type.replace('_', ' ') } : null}
                onChange={option => handleTypeChange(option, filter.id)}
                options={filter.field ? filterTypeOptions(filter.field.type) : []}
                placeholder="Type"
              />
              {filter.field && filter.field.type !== 'date' && (
                <input
                  type={filter.field.type === 'number' ? 'number' : 'text'}
                  className="p-2 border border-gray-300 rounded w-1/3"
                  value={filter.value}
                  onChange={(e) => handleValueChange(e, filter.id)}
                  onBlur={() => handleBlur(filter.id)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.target.blur();
                    }
                  }}
                  placeholder="Value"
                />
              )}
              {filter.field && filter.field.type === 'date' && (
                <div className='relative'>
                  <input
                    type="text"
                    ref={inputRef}
                    className="p-2 border border-gray-300 rounded w-full"
                    value={filter.value}
                    readOnly
                    onClick={() => {
                      setActiveFilter(filter.id);
                      setIsCalendarOpen(true);
                    }}
                  />
                  {isCalendarOpen && (
                    <div ref={calendarRef} className="border border-gray-300 bg-white p-1 rounded shadow-lg inset-0 flex items-center justify-center z-50 absolute top-12 left-0 h-fit w-fit">
                      <DayPicker
                        defaultMonth={month}
                        selected={selected}
                        onMonthChange={(month) => setMonth(month)}
                        onSelect={handleDateSelect}
                        mode="single"
                      />
                    </div>
                  )}
                </div>
              )}
              <button onClick={() => removeFilter(filter.id)} className="rounded-md py-2.5 px-2 text-sm font-normal shadow-sm bg-red-600 text-white">
                Remove
              </button>
            </div>
          ))}
          <button onClick={addFilter} className="rounded-md px-2.5 py-1.5 text-sm font-normal shadow-sm bg-blue-600 text-white">
            Add Filter
          </button>
        </div>
      )}
    </div>
  );
};

export { FilterDropdown };
