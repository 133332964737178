import dayjs from 'dayjs'

import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import { TwitterHandle } from "@/components/TwitterHandle"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"

import {
  getLinkCode,
} from "@/queries/links"

import { useParams, useNavigate } from 'react-router-dom'

import { BreadCrumbs } from '@/components/BreadCrumbs'
import { usePageTitle } from '@/utils/pagetitle';

export const PublicLinkDetail = () => {
  const { code } = useParams()
  const navigate = useNavigate()
  const link = useQuery(["link-public", code], () => getLinkCode(code))
  usePageTitle(link.isSuccess ? `/c${String.fromCharCode(92)}mammoth_ticketing` : `/c${String.fromCharCode(92)}ave_good...`);

  const queryClient = useQueryClient()

  if (link.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }

  return (
    <div
      className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
    >
      <h1 className="text-2xl font-semibold my-6">Mammoth Ticket Details</h1>
      <div className="my-2 w-full overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Event
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Venue
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Today
              </th>
              <th scope="col" className="px-6 py-3">
                Previous
              </th>
              <th scope="col" className="px-6 py-3">
                TM
              </th>
              <th scope="col" className="px-6 py-3">
                etix
              </th>
              <th scope="col" className="px-6 py-3">
                Venue
              </th>
              <th scope="col" className="px-6 py-3">
                Fan Club
              </th>
              <th scope="col" className="px-6 py-3">
                Outlets
              </th>
              <th scope="col" className="px-6 py-3">
                VIP
              </th>
              <th scope="col" className="px-6 py-3">
                Comps
              </th>
              <th scope="col" className="px-6 py-3">
                Last Updated
              </th>
            </tr>
          </thead>
          <tbody>
            {link.data.manifests.map((link, index) => {
              return (
                <tr className="hover:bg-gray-300">
                  <td className="p-2">{link.event_name}</td>
                  <td>{dayjs(link.event_date).format('YYYY-MM-DD')}</td>
                  <td>{link.venue_name}</td>
                  <td>{link.name}</td>
                  <td>{link.today}</td>
                  <td>{link.previous}</td>
                  <td>{link.tm}</td>
                  <td>{link.etix}</td>
                  <td>{link.venue}</td>
                  <td>{link.fan_club}</td>
                  <td>{link.outlets}</td>
                  <td>{link.vip}</td>
                  <td>{link.comps}</td>
                  <td>{dayjs(link.last_updated).format('YYYY-MM-DD')}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
