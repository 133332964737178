import dayjs from 'dayjs'
import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import * as yup from "yup"
import { getEvent } from "@/queries/events"
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import {
  Datepicker,
  FormBuilder,
  Input,
  TextInput,
  FormSelect,
  FormMultiSelect,
  selectOptionToValue
} from "@/components/Form"
import { stateChoices } from "@/utils/states"
import {
  updateEvent, markContractReceived, markContractReturned, markContractFullyExecuted
} from "@/queries/events"
import { getEventCopros, createEventCopros, deactivateEventCopro } from "@/queries/accounting"
import { DocumentCheckIcon, TrashIcon } from '@heroicons/react/24/outline'
import { getVenues, getVenuesSettings } from "@/queries/venues"
import { getArtists, getArtistsSettings } from "@/queries/artists"
import { BreadCrumbs } from '@/components/BreadCrumbs'
import { FileUploader } from '@/components/Files/FileUploader'
import Button from '@/components/Button'
import { usePerms } from "@/components/Auth"
import { usePageTitle } from '@/utils/pagetitle';

const ContractBox = (props) => {
  const { label, eventID, verb, mutationQuery, done } = props
  const { perms } = usePerms()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (id) => {
      console.log("---> marking event")

      return mutationQuery(id)
    },
    onSuccess: async (data) => {
      console.dir(data)
      queryClient.invalidateQueries(["events"])
      queryClient.setQueryData(["event-detail", data.id.toString()], data)
    }
  })

  const doAction = () => {
    mutation.mutate(eventID)
  }

  return (
    <div className="border border-blue-300 p-4 flex flex-inline justify-between w-full bg-blue-100 mb-4">
      <span className="mt-2 align-middle">{label} {verb}</span>
      {done && <DocumentCheckIcon className="h-8 w-8 text-green-500" />}
      {!done && perms.edit_contracts &&
        <button
          className="ml-4 rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          onClick={() => doAction()}
        >Mark {label} {verb}
        </button>
      }
    </div>
  )
}

const AddCoPro = (props) => {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const [showForm, setShowForm] = useState(false)

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> updating expense")
      return createEventCopros(id, data)
    },
    onSuccess: async () => {
      setShowForm(false)
      queryClient.invalidateQueries(["event-detail"])
      queryClient.invalidateQueries(["event-copros"])
    }
  })

  const addSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),
    percentage: yup.string(),
  })

  const handleSubmit = (data) => {
    console.log("--> adding copro")
    console.dir(data)
    mutation.mutate(data)
  }

  if (showForm) {
    return (
      <div className="border border-blue-300 bg-blue-100 dark:bg-gray-800 p-4">
        <h3 className="font-semibold dark:text-white">Add New Copro</h3>
        <FormBuilder onSubmit={(data) => handleSubmit(data)} schema={addSchema}>
          <Input
            name="name"
            label="Name"
            autoFocus={true}
          />
          <TextInput
            name="description"
            label="Description"
          />
          <Input
            name="percentage"
            label="Percentage"
          />
          <div className="flex-inline float-right mb-4 mt-8">
            <button
              type="button"
              onClick={() => setShowForm(false)}
              className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
              Cancel
            </button>
            <Button type="submit">Add Copro</Button>
          </div>
        </FormBuilder>
      </div>
    )
  }

  return (
    <div className="w-full text-right">
      <button
        className="ml-4 rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        onClick={() => setShowForm(true)}
      >Add Copro
      </button>
    </div>
  )
}

export const EventEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const event = useQuery(["event-detail", id], () => getEvent(id))
  const venues = useQuery(["venues"], () => getVenues(), getVenuesSettings)
  const artists = useQuery(["artists"], getArtists, getArtistsSettings)
  const copros = useQuery(["event-copros", id], () => getEventCopros(id))
  const queryClient = useQueryClient()
  const getEventDate = `[${event?.data?.date ?? 'TBD'}]` || '';
  usePageTitle(event.isSuccess ? `/c${String.fromCharCode(92)}${event.data.name}` : `/c${String.fromCharCode(92)}ave_good...`);

  const fixTimes = (name, data) => {
    if (data[name] === "") {
      data[name] = null
    }
    return data
  }

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> updating event")
      data = selectOptionToValue("venue", data)
      data = selectOptionToValue("state", data)
      data = selectOptionToValue("venue_id", data)
      data = selectOptionToValue("age_requirement", data)

      data = fixTimes("door_time", data)
      data = fixTimes("opener_time", data)
      data = fixTimes("support_time", data)
      data = fixTimes("headliner_time", data)
      data = fixTimes("curfew_time", data)

      console.log("final event update data", data)

      return updateEvent(id, data)
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["events"])
      queryClient.setQueryData(["event-detail", data.id], data)
      navigate(`/events/${id}`)
    }
  })

  const deactivateMutation = useMutation({
    mutationFn: (id) => {
      console.log("---> deactivating copro")
      return deactivateEventCopro(id)
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["event-detail"])
      queryClient.invalidateQueries(["event-copros"])
    }
  })

  if (event.isLoading || venues.isLoading || artists.isLoading || copros.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }

  const venueOptions = venues.data.map(venue => {
    return { value: venue.id, label: venue.name }
  })
  const artistOptions = artists.data.map(artist => {
    return { value: artist.id, label: artist.name }
  })

  const ageOptions = [
    { value: "all-ages", label: "All Ages" },
    { value: "16-up", label: "16+" },
    { value: "18-up", label: "18+" },
    { value: "21-up", label: "21+" },
  ]

  const updateSchema = yup.object().shape({
    name: yup.string().required(),
    date: yup.string().required(),
    description: yup.string(),
    abbr: yup.string(),
    venue_id: yup.object(),
  })

  let venueObject = null
  if (event.data.venue) {
    venueObject = { value: event.data.venue.id, label: venueOptions.find(option => option.value === event.data.venue.id).label }
  }

  let stateObject = null
  if (event.data.state) {
    stateObject = { value: event.data.state, label: stateChoices.find(option => option.value === event.data.state).label }
  }

  let ageObject = null
  if (event.data.age_requirement) {
    ageObject = { value: event.data.age_requirement, label: ageOptions.find(option => option.value === event.data.age_requirement).label }
  }

  let values = {
    name: event.data.name || "",
    description: event.data.description || "",
    state: stateObject,
    active: event.data.active,
    date: dayjs(event.data.date).format('YYYY-MM-DD'),
    abbr: event.data.abbr || "",
    venue_id: venueObject,
    seating_chart_id: event.data.seating_chart,
    artists: event.data.artists.map(artist => {
      return { value: artist.id, label: artist.name }
    }) || [],
    age_requirement: ageObject,
    door_time: event.data.door_time || "",
    opener_time: event.data.opener_time || "",
    support_time: event.data.support_time || "",
    headliner_time: event.data.headliner_time || "",
    curfew_time: event.data.curfew_time || "",
    offer_notes: event.data.offer_notes || "",
    confirmation_notes: event.data.confirmation_notes || "",
    has_insurance: event.data.has_insurance,
    has_credit_card_fees: event.data.has_credit_card_fees,
    has_ticket_commission: event.data.has_ticket_commission,
    has_ascap: event.data.has_ascap,
    has_bmi: event.data.has_bmi,
    has_sesac: event.data.has_sesac,
    offer_has_rent: event.data.offer_has_rent,
    offer_has_comp_tax: event.data.offer_has_comp_tax,
    facility_fee: event.data.facility_fee,
  }

  console.log("Values", values)
  const handleSubmit = (data) => {
    console.log("In submit")
    console.dir(data.artists)
    data.artists = data.artists.map(artist => { return artist.value })

    console.dir(data)
    mutation.mutate(data)
  }

  const handleDeactivate = (id) => {
    deactivateMutation.mutate(id)
  }

  // Determine whether or not we show the based on event state
  let showContractBoxes = false

  if (event.data.state == 'offer' || event.data.state == 'confirmed') {
    showContractBoxes = true
  }

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs links={[{ text: "Events", url: `/events/` }, { text: `${event.data.name} ${getEventDate}`, url: `/events/${event.data.id}` }, { text: "Edit Details" } ]} />

        <h3 className="text-xl font-bold mb-4 dark:text-white">Edit - {event.data.name}</h3>
        <div className="py-6 min-w-full">
          <FormBuilder defaultValues={values} onSubmit={(data) => handleSubmit(data)} schema={updateSchema}>
            <Input
              name="name"
              label="Name"
            />
            <Datepicker name="date" label="Date" />
            <FormSelect
              name="venue_id"
              label="Venue"
              options={venueOptions}
              required={true}
            />
            <FormMultiSelect
              name="artists"
              label="Artists"
              options={artistOptions}
              required={true}
            />
            <FormSelect
              name="state"
              label="Status"
              options={stateChoices}
            />
            <FormSelect
              name="age_requirement"
              label="Ages"
              options={ageOptions}
            />
            <Input
              name="abbr"
              label="Abbreviation"
            />
            <TextInput
              name="description"
              label="Description"
            />
            <Input
              name="door_time"
              type="time"
              label="Door Time"
            />
            <Input
              name="opener_time"
              type="time"
              label="Opener Time"
            />
            <Input
              name="support_time"
              type="time"
              label="Support Time"
            />
            <Input
              name="headliner_time"
              type="time"
              label="Headliner Time"
            />
            <Input
              name="curfew_time"
              type="time"
              label="Curefew Time"
            />
            <TextInput
              name="offer_notes"
              label="Offer Notes"
            />
            <TextInput
              name="confirmation_notes"
              label="Confirm Notes"
            />
            <div className="flex-inline float-right mb-12 mt-8">
              <button
                type="button"
                onClick={() => navigate(`/events/${event.data.id}`)}
                className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                Cancel
              </button>
              <Button type="submit">Save</Button>
            </div>
          </FormBuilder>
        </div>

        <h3 className="my-4 font-semibold border-b border-gray-800 dark:border-gray-100 dark:text-white">Manage Copros</h3>
        <AddCoPro />

        <table className="border mt-4">
          <thead>
            <tr className="bg-gray-300 text-left">
              <th className="p-2">Name</th>
              <th className="p-2">Description</th>
              <th className="p-2">Percentage</th>
              <th className="p-2"></th>
            </tr>
          </thead>
          <tbody>
            {copros.data.map((copro) => (
              <tr className="hover:bg-gray-100 dark:bg-white">
                <td className="p-2">{copro.name}</td>
                <td className="p-2">{copro.description}</td>
                <td className="p-2">{copro.percentage} %</td>
                <td className="p-2">
                  <TrashIcon
                    className="w-6 h-6 float-right hover:text-red-500"
                    onClick={() => handleDeactivate(copro.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <h3 className="my-4 font-semibold border-b border-gray-800 dark:border-gray-100 dark:text-white">Contract Status</h3>
        <div className="my-4">
          <ContractBox
            label="Contract"
            eventID={event.data.id}
            verb="Received"
            mutationQuery={markContractReceived}
            done={event.data.contract_received}
          />
        </div>
        {event.data.contract_received && (
          <div className="my-4">
            <ContractBox
              label="Contract"
              eventID={event.data.id}
              verb="Returned"
              mutationQuery={markContractReturned}
              done={event.data.contract_returned}
            />
          </div>
        )}
        {event.data.contract_returned &&
          (
            <div className="my-4">
              <ContractBox
                label="Contract"
                eventID={event.data.id}
                state={event.data.state}
                verb="Fully Executed"
                mutationQuery={markContractFullyExecuted}
                done={event.data.contract_fully_executed}
              />
            </div>

          )
        }
        <div className="mb-8">&nbsp;</div>
      </div >
    </>
  );
};
