import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getStaff, getPeopleSettings } from "@/queries/people";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import Button from "@/components/Button";
import MammothIcon from "@/components/MammothIcon";
import { usePageTitle } from "@/utils/pagetitle";
import { SortedTable } from "../../components/SortedTable/SortedTable";
import { PeopleIcon } from "@/components/People/PeopleIcon";

const PeopleRow = (props) => {
  const { person } = props;
  const navigate = useNavigate();

  return (
    <tr
      onClick={() => {
        navigate(`/people/${person.id}`);
      }}
      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
    >
      <td
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {person.is_mammoth && (
          <MammothIcon className="w-6 h-6 text-indigo-400" />
        )}
      </td>
      <td
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {person.first_name}
      </td>
      <td
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {person.mammoth_role_name}
      </td>
      <td
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {person.city && <>{person.city},</>} {person.state}
      </td>
      <td
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        <a
          href={`mailto:${person.email}`}
          className="text-blue-500 underline hover:text-blue-300"
        >
          {person.email}
        </a>
      </td>
      <td
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        <a
          href="tel:{person.phone}"
          className="text-blue-500 underline hover:text-blue-300"
        >
          {person.phone}
        </a>
      </td>
    </tr>
  );
};

const FilteredPeopleTable = ({
  people,
  headers,
  total,
  canEdit,
  title,
  handleEditClick,
}) => {
  const navigate = useNavigate();

  const formatData = (people) => {
    // calculate total for each manifest
    return (
      people &&
      people.map((manifest) =>
        Object.assign(manifest, { total: manifest.price * manifest.qty })
      )
    );
  };

  const handleFormatCell = (accessor, rowData, rowIndex) => {
    if (accessor === "on_sale") {
      return rowData["on_sale"]
        ? dayjs(rowData["on_sale"]).format("MMM D, YYYY")
        : "No Date";
    }
    // return nothing for all other values
  };

  const handleSort = (key, item) => {
    if (key === "total") {
      return item["price"] * item["qty"];
    } else {
      return item[key];
    }
  };

  return (
    <SortedTable
      total={total}
      data={formatData(people)}
      headers={headers}
      customSort={handleSort}
      formatCell={handleFormatCell}
      onEditClick={canEdit ? handleEditClick : null}
      handleRowClick={(row, index) => navigate(`/people/${row.id}`)}
      hasSorting
      hasFilter
    />
  );
};

export const StaffDirectory = () => {
  const people = useQuery(["staff"], () => getStaff(), getPeopleSettings);
  const navigate = useNavigate();
  usePageTitle(
    people.isSuccess
      ? `/c${String.fromCharCode(92)}staff`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  if (people.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  const renderPeople = people.data
    .filter((person) => person.is_mammoth)
    .sort((a, b) => {
      const isBlankA = !a.last_name || a.last_name.trim() === "";
      const isBlankB = !b.last_name || b.last_name.trim() === "";

      if (isBlankA && !isBlankB) return 1;
      if (!isBlankA && isBlankB) return -1;
      return (a.last_name || "").localeCompare(b.last_name || "");
    });

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <div className="w-full flex justify-between items-center">
          <BreadCrumbs
            links={[{ text: "Mammoth Staff Directory", url: "/staff" }]}
          />
          <span></span>
        </div>
        <div className="my-2 w-full overflow-x-auto shadow-md sm:rounded-lg">
          <FilteredPeopleTable
            people={renderPeople}
            headers={[
              { header: "", accessor: "user.photo" },
              { header: "Last Name", accessor: "last_name" },
              { header: "First Name", accessor: "first_name" },
              { header: "Role", accessor: "mammoth_role_name" },
              { header: "Location", accessor: "location" },
              { header: "Email", accessor: "email" },
              { header: "Phone", accessor: "phone" },
            ]}
            handleRowClick={(row) => navigate(`/people/${row.id}`)}
          />
        </div>
      </div>
    </>
  );
};

//////////////

// import React, { useEffect, useState } from "react";
// import { Header } from "@/components/Header";
// import { useQuery } from "@tanstack/react-query";
// import { useNavigate } from "react-router-dom";
// import { getStaff, getPeopleSettings } from "@/queries/people";
// import { BreadCrumbs } from "@/components/BreadCrumbs";
// import Button from "@/components/Button";
// import MammothIcon from "@/components/MammothIcon";
// import { usePageTitle } from "@/utils/pagetitle";
// import { SortedTable } from "../../components/SortedTable/SortedTable";
// import { PeopleIcon } from "@/components/People/PeopleIcon";

// const PeopleRow = (props) => {
//   const { person } = props;
//   const navigate = useNavigate();

//   return (
//     <tr
//       onClick={() => {
//         navigate(`/people/${person.id}`);
//       }}
//       className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
//     >
//       <td
//         scope="row"
//         className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
//       >
//         {person.is_mammoth && (
//           <MammothIcon className="w-6 h-6 text-indigo-400" />
//         )}
//       </td>
//       <td
//         scope="row"
//         className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
//       >
//         {person.first_name}
//       </td>
//       <td
//         scope="row"
//         className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
//       >
//         {person.mammoth_role_name}
//       </td>
//       <td
//         scope="row"
//         className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
//       >
//         {person.city && <>{person.city},</>} {person.state}
//       </td>
//       <td
//         scope="row"
//         className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
//       >
//         <a
//           href={`mailto:${person.email}`}
//           className="text-blue-500 underline hover:text-blue-300"
//         >
//           {person.email}
//         </a>
//       </td>
//       <td
//         scope="row"
//         className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
//       >
//         <a
//           href="tel:{person.phone}"
//           className="text-blue-500 underline hover:text-blue-300"
//         >
//           {person.phone}
//         </a>
//       </td>
//     </tr>
//   );
// };

// const FilteredPeopleTable = ({
//   people,
//   headers,
//   total,
//   canEdit,
//   title,
//   handleEditClick,
// }) => {
//   const navigate = useNavigate();

//   const formatData = (people) => {
//     // calculate total for each manifest
//     return (
//       people &&
//       people.map((manifest) =>
//         Object.assign(manifest, { total: manifest.price * manifest.qty })
//       )
//     );
//   };

//   const handleFormatCell = (accessor, rowData, rowIndex) => {
//     if (accessor === "on_sale") {
//       return rowData["on_sale"]
//         ? dayjs(rowData["on_sale"]).format("MMM D, YYYY")
//         : "No Date";
//     }
//     // return nothing for all other values
//   };

//   const handleSort = (key, item) => {
//     if (key === "total") {
//       return item["price"] * item["qty"];
//     } else {
//       return item[key];
//     }
//   };

//   return (
//     <SortedTable
//       total={total}
//       data={formatData(people)}
//       headers={headers}
//       customSort={handleSort}
//       formatCell={handleFormatCell}
//       onEditClick={canEdit ? handleEditClick : null}
//       handleRowClick={(row, index) => navigate(`/people/${row.id}`)}
//       hasSorting
//       hasFilter
//     />
//   );
// };

// export const StaffDirectory = () => {
//   const people = useQuery(["staff"], () => getStaff(), getPeopleSettings);
//   const navigate = useNavigate();
//   usePageTitle(
//     people.isSuccess
//       ? `/c${String.fromCharCode(92)}staff`
//       : `/c${String.fromCharCode(92)}ave_good...`
//   );

//   if (people.isLoading) {
//     return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
//   }
//   //const formattedData = formatData(people.data);

//   const renderPeople = people.data
//     .filter((person) => person.is_mammoth)
//     .sort((a, b) => {
//       const isBlankA = !a.last_name || a.last_name.trim() === "";
//       const isBlankB = !b.last_name || b.last_name.trim() === "";

//       if (isBlankA && !isBlankB) return 1;
//       if (!isBlankA && isBlankB) return -1;
//       return (a.last_name || "").localeCompare(b.last_name || "");
//     });

//   return (
//     <>
//       <Header />
//       <div
//         className="
//         flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
//     "
//       >
//         <div className="w-full flex justify-between items-center">
//           <BreadCrumbs
//             links={[{ text: "Mammoth Staff Directory", url: "/staff" }]}
//           />
//           <span></span>
//         </div>
//         <div className="my-2 w-full overflow-x-auto shadow-md sm:rounded-lg">
//           <FilteredPeopleTable
//             people={renderPeople}
//             headers={[
//               { header: "", accessor: "user.photo" },
//               { header: "Last Name", accessor: "last_name" },
//               { header: "First Name", accessor: "first_name" },
//               { header: "Role", accessor: "mammoth_role_name" },
//               { header: "Location", accessor: "location" },
//               { header: "Email", accessor: "email" },
//               { header: "Phone", accessor: "phone" },
//             ]}
//             handleRowClick={(row) => navigate(`/people/${row.id}`)}
//           />
//         </div>
//       </div>
//     </>
//   );
// };
