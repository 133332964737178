import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import * as yup from "yup"
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useFormContext } from "react-hook-form";
import {
  FormBuilder,
  Input,
  TextInput,
  FormSelect,
  FormMultiSelect,
  selectOptionToValue
} from "@/components/Form"

import { createLink } from "@/queries/links"
import { getEvents, getEventsSettings } from "@/queries/events"
import { getVenues, getVenuesSettings } from "@/queries/venues"
import { getArtists, getArtistsSettings } from "@/queries/artists"
import { BreadCrumbs } from '@/components/BreadCrumbs'
import Button from '@/components/Button'

export const LinksAdd = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const events = useQuery(["events"], () => getEvents(), getEventsSettings)
  const venues = useQuery(["venues"], () => getVenues(), getVenuesSettings)
  const artists = useQuery(["artists"], getArtists, getArtistsSettings)

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating link")
      console.dir(data)

      return createLink(data)
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["links"])
      navigate(`/links/`)
    }
  })

  const updateSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),
  })

  const handleSubmit = (data) => {
    console.log("In submit")
    data.venues = data.venues.map(venue => { return venue.value })
    data.events = data.events.map(event => { return event.value })
    data.artists = data.artists.map(artist => { return artist.value })
    console.dir(data)
    mutation.mutate(data)
  }

  if (events.isLoading || venues.isLoading || artists.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }

  const eventOptions = events.data.map(event => {
    return { value: event.id, label: event.name }
  })

  const venueOptions = venues.data.map(venue => {
    return { value: venue.id, label: venue.name }
  })

  const artistOptions = artists.data.map(artist => {
    return { value: artist.id, label: artist.name }
  })

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs links={[{ text: "Links", url: "/links" }, { text: 'Add' }]} />

        <h3 className="text-xl font-bold mb-4 dark:text-white">Create TicketCount Link</h3>
        <div className="min-w-full">
          <FormBuilder onSubmit={(data) => handleSubmit(data)} schema={updateSchema}>
            <Input
              name="name"
              label="Name"
              autoFocus={true}
            />
            <TextInput
              name="description"
              label="Description"
            />
            <FormMultiSelect
              name="events"
              label="Event"
              options={eventOptions}
            />
            <FormMultiSelect
              name="venues"
              label="Venue"
              options={venueOptions}
            />
            <FormMultiSelect
              name="artists"
              label="Artist"
              options={artistOptions}
            />
            <div className="flex-inline float-right mb-12">
              <button
                type="button"
                onClick={() => navigate(`/links/`)}
                className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                Cancel
              </button>
              <Button type="submit">Add Link</Button>
            </div>
          </FormBuilder>
        </div>
      </div>
    </>
  );
};
