import classNames from "classnames";
import React from "react";

export const Button = (props) => {
  const { disabled, onClick, size, variant } = props;

  const buttonClasses = classNames(
    `w-fit inline-flex items-center rounded-md uppercase bg-blue-500 dark:bg-cave-yellow-200 font-semibold shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500`,
    {
      "cursor-not-allowed text-gray-400 border border-gray-300 hover:bg-white bg-white":
        disabled,
      "text-xs px-4 py-2": size === "sm",
      "text-sm px-7 py-2": !size || size === "md",
      "bg-blue-500 text-white dark:text-cave-gray-900 hover:bg-blue-400":
        (variant === "primary" || !variant) && !disabled,
      "bg-white bg-text-cave-gray-900 border border-gray-500 hover:bg-gray-100":
        variant === "secondary" && !disabled,
    }
  );

  return (
    <button
      onClick={onClick}
      className={buttonClasses}
      disabled={disabled}
      {...props}
    >
      {props.children}
    </button>
  );
};

export default Button;
