import React from "react";
import { useParams } from 'react-router-dom';
import { Header } from "@/components/Header";
import { usePerms } from "@/components/Auth";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { BreadCrumbs } from '@/components/BreadCrumbs';
import FileDisplay from "@/components/Files/FileDisplay";
import CommentDisplay from "@/components/Comments/CommentDisplay";
import { getReqDetails, getReqComments, getReqFiles, delReqFile, createReqComment } from '@/queries/reqs';

export const ReqDetail = () => {
  const { id } = useParams();
  const { perms } = usePerms();
  const queryClient = useQueryClient();

  const reqDetail = useQuery(["req", "detail", id], () => getReqDetails(id));
  const reqComments = useQuery(["req", "comments", id], () => getReqComments(id));
  const reqFiles = useQuery(["req", "files", id], () => getReqFiles(id));

  const commentMutation = useMutation({
    mutationFn: (data) => createReqComment(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["req", "comments", id]);
    },
  });

  const handleAddComment = (values) => {
    commentMutation.mutate(values);
  };

  if (reqDetail.isLoading || reqComments.isLoading || reqFiles.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  if (reqDetail.isError || reqComments.isError || reqFiles.isError) {
    return <div>Error loading data. Please try again.</div>;
  }

  return (
    <>
      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0">
        <BreadCrumbs links={[{ text: "IT Requests", url: "/reqs" }, { text: `${reqDetail.data.title}`}]} />

        <div className="py-6 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">
              {reqDetail.data.title}
            </h2>
            <p className="dark:text-gray-400">{reqDetail.data.description}</p>
            
            <div className="py-6">
              <CommentDisplay 
                comments={{
                  isLoading: false,
                  isSuccess: true,
                  data: reqComments.data || []
                }}
                handleAddComment={handleAddComment}
              />
            </div>

            <FileDisplay
              files={reqFiles || []}
              addURL={`/reqs/file/add/${id}`}
              deleteQuery={delReqFile}
              editable={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
