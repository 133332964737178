import React from "react"
import classnames from 'classnames'
import dayjs from "dayjs"
import { useParams, useNavigate } from "react-router-dom"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { amountDisplay, formatDollarsToCents, formatCentsToDollars } from "@/utils/money"
import { getEvent, getEventPeople, getEventSettlementInfo } from "@/queries/events"
import { getCoProEventRollups, getExpenseGroups, getExpenseGroupSettings, getCoProEventExpenses, getEventExpensesSettings } from "@/queries/accounting"
import { usePageTitle } from '@/utils/pagetitle';


const Header = ({ title, colspan = 1, center = false, light = false, right = false, bold = false }) => {
  const headerClasses = classnames("p-1 bg-gray-300 text-sm border border-y-2 border-slate-900", {
    "text-center": center,
    "bg-gray-100": light,
    "text-right": right,
    "font-semibold": !bold,
    "font-bold": bold,
  })
  return (
    <th className={headerClasses} scope="col" colSpan={colspan}>{title}</th>
  )
}

const Data = ({ title, bold = false, center = false, left = false, right }) => {
  const dataClasses = classnames("p-1 text-sm border border-slate-900", {
    "text-center": center,
    "text-left": left,
    "font-bold": bold,
    "text-right": right,
  })
  return (
    <td className={dataClasses}>{title}</td>
  )
}

const HeaderInfo = (props) => {
  const { event, settlement } = props

  let headlinerNames = settlement.headliners.map(he => he.name).join(", ")
  let supportingNames = settlement.supporting_acts.map(he => he.name).join(", ")

  return (
    <div className="w-2/6">
      <h1 className="pl-2 text-2xl font-bold">Mammoth</h1>
      <p className="pl-2 my-2 text-sm">
        1520 Haskell Ave | Lawrence, KS 66044<br />
        785.749.7475 (P) 785.865.4110 (F)
      </p>
      <div className="border-r border-t border-b border-slate-900">
        <table className="w-full">
          <tbody>
            <tr>
              <Data title="HEADLINERS:" bold />
              <Data title={headlinerNames} center />
            </tr>
            <tr>
              <Data title="SUPPORT:" bold />
              <Data title={supportingNames} center />
            </tr>
            <tr>
              <Data title="DATE:" bold />
              <Data title={dayjs(event.date).format("dddd MMM D, YYYY")} center />
            </tr>
            <tr>
              <Data title="VENUE:" bold />
              <Data title={event.venue.name} center />
            </tr>
            <tr>
              <Data title="BUYER:" bold />
              <Data title={settlement.buyer.name} center />
            </tr>
            <tr>
              <Data title="PROMOTER SETTLE:" bold />
              <Data title={settlement.promoter_settle?.name} center />
            </tr>
            <tr>
              <Data title="ARTIST SETTLE:" bold />
              <Data title={settlement.artist_settle?.name} center />
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  )
}

const Tickets = (props) => {
  const { manifests } = props

  let totalQty = 0
  let totalAvailable = 0
  let totalComps = 0
  let totalPaid = 0
  let totalGross = 0

  manifests.map((manifest) => {
    totalQty += manifest.qty
    totalAvailable += manifest.available
    totalComps += manifest.comps
    totalPaid += manifest.paid
    totalGross += (manifest.paid * manifest.price)
  })

  return (
    <div className="w-4/6">
      <div className="border border-slate-900">
        <table className="w-full">
          <tbody>
            <tr>
              <Header title="MANIFEST" bold />
              <Header title="PRICE" bold />
              <Header title="AVAILABLE" bold />
              <Header title="COMPS" bold />
              <Header title="PAID" bold />
              <Header title="GROSS" bold />
              <Header title="Ticket Type" bold />
            </tr>
            {manifests.map(manifest => (
              <tr key={`manifest-${manifest.id}`}>
                <Data title={manifest.qty} center />
                <Data title={amountDisplay(manifest.price)} center />
                <Data title={manifest.available} center />
                <Data title={manifest.comps} center />
                <Data title={manifest.paid} center />
                <Data title={amountDisplay(manifest.paid * manifest.price)} right />
                <Data title={manifest.name} />
              </tr>
            ))}
            <tr>
              <Header title={totalQty} center light />
              <Header title="" center light />
              <Header title={totalAvailable} center light />
              <Header title={totalComps} center light />
              <Header title={totalPaid} center light />
              <Header title={amountDisplay(totalGross)} right light />
              <Header title="" center light />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

const groupSubExpenses = (expenses) => {
  const result = expenses.reduce((result, expense) => {
    result[expense.subgroup] = result[expense.subgroup] || []
    result[expense.subgroup].push(expense)
    return result
  }, Object.create(null))
  return result
}

const ExpenseRow = (props) => {
  const { checkNumber, name, cashPayout, checkPayout, officePayout, buildingPayout, coproPayout, total } = props

  return (
    <tr className="divide-x divide-y divide-slate-500">
      <td>{checkNumber}</td>
      <td className="text-left">{name}</td>
      <td className="text-right">{cashPayout !== 0 && amountDisplay(cashPayout)}</td>
      <td className="text-right">{checkPayout !== 0 && amountDisplay(checkPayout)}</td>
      <td className="text-right">{officePayout !== 0 && amountDisplay(officePayout)}</td>
      <td className="text-right">{buildingPayout !== 0 && amountDisplay(buildingPayout)}</td>
      <td className="text-right">{coproPayout !== 0 && amountDisplay(coproPayout)}</td>
      <td className="text-right">{amountDisplay(total)}</td>
    </tr>
  )
}

const sumPayouts = (expenses) => {
  let cashPayout = 0
  let checkPayout = 0
  let officePayout = 0
  let buildingPayout = 0
  let coproPayout = 0

  expenses.map((expense) => {
    switch (expense.payment_method) {
      case "cash":
        cashPayout += expense.amount
        break;
      case "check":
        checkPayout += expense.amount
        break;
      case "building":
        buildingPayout += expense.amount
        break;
      case "copro":
        coproPayout += expense.amount
        break;
      case "office":
        officePayout += expense.amount
        break;
      case "wire":
        officePayout += expense.amount
        break;
      case "ach":
        officePayout += expense.amount
        break;
      case "internal":
        officePayout += expense.amount
        break;
    }
  })

  return {
    cashPayout,
    checkPayout,
    officePayout,
    buildingPayout,
    coproPayout,
  }
}

const ExpenseSubGroupRow = (props) => {
  const { subgroup, expenses } = props
  const subGroupExpenses = expenses.filter(expense => expense.subgroup === subgroup.id)
  const payout = sumPayouts(subGroupExpenses)
  let total = 0
  subGroupExpenses.map((expense) => { total += expense.amount })

  console.log(`=== ${subgroup.name} ===`)
  console.dir(subgroup)
  console.dir(expenses)
  console.dir(subGroupExpenses)
  console.dir(payout)

  return (
    <ExpenseRow
      key={`expense-subgroup-${subgroup.id}`}
      checkNumber=""
      name={subgroup.name}
      total={total}
      {...payout}
    />
  )
}

const ExpenseGroup = (props) => {
  const { group, expenses } = props
  const subGroupIDs = group.subgroups.map(subgroup => subgroup.id)
  const subGroupExpenses = expenses.filter(expense => subGroupIDs.includes(expense.subgroup))

  let isArtist = group.name == "Artist"
  let header = null
  let total = 0
  subGroupExpenses.map((expense) => { total += expense.amount })

  if (isArtist) {

    return (
      <>
        {subGroupExpenses.map((expense) => {
          const payout = sumPayouts([expense])
          return (
            <ExpenseRow
              key={`expense-${expense.id}`}
              checkNumber={expense.check_number}
              name={expense.description}
              total={expense.amount}
              {...payout}
            />
          )
        })}
      </>
    )

  } else {

    return (
      <>
        <tr className="border-2 border-slate-900 text-sm bg-gray-300">
          <td></td>
          <td className="font-bold">{group.name}</td>
          <td colSpan={5}></td>
          <td className="text-right">{amountDisplay(total)}</td>
        </tr>
        {group.subgroups.map((subgroup) => (
          <ExpenseSubGroupRow
            key={`expense-subgroup-${subgroup.id}`}
            subgroup={subgroup}
            expenses={subGroupExpenses}
          />
        ))}
      </>
    )

  }
}

const Expenses = (props) => {
  const { id, event, rollups } = props
  const groups = useQuery(["expense-groups"], () => getExpenseGroups(), getExpenseGroupSettings)
  const expenses = useQuery(["expenses", "copro", id], () => getCoProEventExpenses(id), getEventExpensesSettings)

  if (groups.isLoading || expenses.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }

  return (
    <div className="w-3/4">
      <div className="border border-slate-900">
        <table className="w-full">
          <tbody>
            <tr>
              <Header title="CHECK #" />
              <Header title="EXPENSE" />
              <Header title="CASH PAYOUT" />
              <Header title="CHECK PAYOUT" />
              <Header title="OFFICE PAYOUT" />
              <Header title="BUILDING PAYOUT" />
              <Header title="COPRO PAYOUT" />
              <Header title="TOTAL" />
            </tr>
            {groups.data.map(group => (
              <ExpenseGroup
                key={`expense-group-${group.id}`}
                group={group}
                expenses={expenses.data}
              />
            ))}
            <tr className="border-2 border-slate-900 text-sm bg-gray-300">
              <td></td>
              <td className="font-bold">Variable Expenses</td>
              <td colSpan={5}></td>
              <td className="text-right">{amountDisplay(rollups.variable_expenses)}</td>
            </tr>
            <ExpenseRow
              key={`vexpense-cc`}
              checkNumber=""
              name="Credit Cards"
              cashPayout={0}
              checkPayout={0}
              officePayout={event.credit_card_fees || 0}
              buildingPayout={0}
              coproPayout={0}
              total={event.credit_card_fees || 0}
            />
            <ExpenseRow
              key={`vexpense-ticket-commission`}
              checkNumber=""
              name="Ticket Commission"
              cashPayout={0}
              checkPayout={0}
              officePayout={event.ticket_commission || 0}
              buildingPayout={0}
              coproPayout={0}
              total={event.ticket_commission || 0}
            />
            <ExpenseRow
              key={`vexpense-ascap`}
              checkNumber=""
              name="ASCAP"
              cashPayout={0}
              checkPayout={0}
              officePayout={rollups.ascap_total || 0}
              buildingPayout={0}
              coproPayout={0}
              total={rollups.ascap_total || 0}
            />
            <ExpenseRow
              key={`vexpense-bmi`}
              checkNumber=""
              name="BMI"
              cashPayout={0}
              checkPayout={0}
              officePayout={rollups.bmi_total || 0}
              buildingPayout={0}
              coproPayout={0}
              total={rollups.bmi_total || 0}
            />
            <ExpenseRow
              key={`vexpense-sesac`}
              checkNumber=""
              name="SESAC"
              cashPayout={0}
              checkPayout={0}
              officePayout={rollups.sesac_total || 0}
              buildingPayout={0}
              coproPayout={0}
              total={rollups.sesac_total || 0}
            />
            <ExpenseRow
              key={`vexpense-insurance`}
              checkNumber=""
              name="Insurance"
              cashPayout={0}
              checkPayout={0}
              officePayout={event.insurance_amount || 0}
              buildingPayout={0}
              coproPayout={0}
              total={event.insurance_amount || 0}
            />
            <tr className="border-2 border-slate-900 text-sm bg-gray-300">
              <td></td>
              <td className="font-bold">Total Expenses</td>
              <td colSpan={5}></td>
              <td className="text-right font-bold">{amountDisplay(rollups.total_expenses + rollups.variable_expenses)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

const FinalFigures = (props) => {
  const { rollups, event } = props

  return (
    <div className="border border-slate-900">
      <table className="w-full">
        <tbody>
          <tr>
            <Header title="TOTAL SHOW EXPENSE" center colspan={2} />
          </tr>
          <tr>
            <Data title="Expenses" />
            <Data title={amountDisplay(rollups.total_expenses)} right />
          </tr>
          <tr>
            <Data title="Variables" />
            <Data title={amountDisplay(rollups.variable_expenses)} right />
          </tr>
          <tr>
            <Data title="Total Expenses" />
            <Data title={amountDisplay(rollups.total_expenses + rollups.variable_expenses)} right />
          </tr>
          <tr>
            <Data title="Net Expenses" />
            <Data title={amountDisplay(rollups.net_expenses)} right />
          </tr>

          <tr>
            <Header title="ATTENDANCE" center colspan={2} />
          </tr>
          <tr>
            <Data title="Paid Attendance" />
            <Data title={rollups.paid_tickets} right />
          </tr>
          <tr>
            <Data title="Comps" />
            <Data title={rollups.comps} right />
          </tr>
          <tr>
            <Data title="Total Attendance" />
            <Data title={rollups.total_attendance} right />
          </tr>
          <tr>
            <Data title="Capacity" />
            <Data title={rollups.capacity} right />
          </tr>
          <tr>
            <Data title="Drop Count" />
            <Data title={rollups.drop_count} right />
          </tr>

          <tr>
            <Header title="FINAL FIGURES" center colspan={2} />
          </tr>
          <tr>
            <Data title="Gross" bold />
            <Data title={amountDisplay(rollups.tickets_gross)} right bold />
          </tr>
          <tr>
            <Data title="Less Facility Fee" />
            <Data title={amountDisplay(rollups.total_facility_fee)} right />
          </tr>
          <tr>
            <Data title="Subtotal" bold />
            <Data title={amountDisplay(rollups.tickets_gross - rollups.total_facility_fee)} right bold />
          </tr>
          <tr>
            <Data title="Less Charity" />
            <Data title={amountDisplay(rollups.charity, true)} right />
          </tr>
          <tr>
            <Data title="Less VIP" />
            <Data title={amountDisplay(event.vip_lift, true)} right />
          </tr>
          <tr>
            <Data title="Plus Platinum" />
            <Data title={amountDisplay(rollups.platinum_lift)} right />
          </tr>
          <tr>
            <Data title="Adjusted Gross" bold />
            <Data title={amountDisplay(rollups.adjusted_gross)} right bold />
          </tr>
          <tr>
            <Data title="Tax Percent" />
            <Data title={`${rollups.tax_percent} %`} right />
          </tr>
          <tr>
            <Data title="Total Tax" />
            <Data title={amountDisplay(rollups.tax_amount)} right />
          </tr>
          <tr>
            <Data title="NET" bold />
            <Data title={amountDisplay(rollups.net_income)} right bold />
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const PromoterSettlement = (props) => {
  const { rollups } = props

  return (
    <div className="border border-slate-900 mt-6">
      <table className="w-full">
        <tbody>
          <tr>
            <Header title="PROMOTER SETTLEMENT" center colspan={2} />
          </tr>
          <tr>
            <Data title="Profit/Loss" />
            <Data title={amountDisplay(rollups.profit_loss)} right />
          </tr>
          <tr>
            <Data title="Total Revenue" />
            <Data title={amountDisplay(rollups.profit_loss)} right />
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export const EventCoProSettlement = (props) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const event = useQuery(["event-detail", id], () => getEvent(id))
  const settlement = useQuery(["event-settlement-info", id], () => getEventSettlementInfo(id))
  const people = useQuery(["event", "people", id], () => getEventPeople(id))
  const rollups = useQuery(["event-rollups", "copro", id], () => getCoProEventRollups(id))

  usePageTitle(event.isSuccess ? `/c${String.fromCharCode(92)}CoPro Settle` : `/c${String.fromCharCode(92)}ave_good...`);

  if (event.isLoading || people.isLoading || rollups.isLoading || settlement.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }

  return (
    <>
      <div className="w-full flex p-1">
        <Tickets
          manifests={event.data.manifests}
        />
        <HeaderInfo
          event={event.data}
          settlement={settlement.data}
        />
      </div>
      <div className="w-full flex p-1">
        <Expenses
          id={id}
          event={event.data}
          rollups={rollups.data}
        />
        <div className="w-1/4 pl-1">
          <FinalFigures
            rollups={rollups.data}
            event={event.data}
          />
          <PromoterSettlement
            rollups={rollups.data}
          />
        </div>
      </div>
    </>
  )
}

export default EventCoProSettlement
