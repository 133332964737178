import React, { useEffect, useState } from "react"
import dayjs from 'dayjs'
import { Header } from "@/components/Header"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import { BreadCrumbs } from '@/components/BreadCrumbs'
import { getOnSaleEvents, recordTickets } from '@/queries/events'
import Button from '@/components/Button'
import { Datepicker, FormBuilder, Input, TextInput, FormSelect } from "@/components/Form"
import { amountDisplay } from "@/utils/money"
import classnames from 'classnames'
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePageTitle } from '@/utils/pagetitle';

const columnClasses = classnames('w-24 mr-4')

export const SectionTickets = (props) => {
  const methods = useForm()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [showSave, setShowSave] = useState(false)

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating new ticket entry")
      console.dir(data)

      for (const key in data) {
        if (data[key] === '') {
          data[key] = 0
        }
      }
      console.dir(data)
      return recordTickets(props.eventID, props.id, data)
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["events-on-sale"])
      methods.reset()

      if (props.navigateOnSave) {
        navigate(props.navigateOnSave)
      }
    }
  })

  const onSubmit = (data) => {
    mutation.mutate(data)
  }

  useEffect(() => {
    if (methods.formState.isDirty) {
      setShowSave(methods.formState.isDirty)
      console.log(`${props.id} is dirty`)
    }
  }, [methods.formState.isDirty])

  let lastUpdated = "never"
  if (props.last_updated) {
    lastUpdated = dayjs(props.last_updated).format('MMMM Do YYYY')
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="w-full mb-4">
          <div className="text-xl font-semibold w-full border-b-2 pl-2 py-2">
            <span className="mr-2 dark:text-white">{props.name} {props.platinum_lift && (<span className="text-gray-400">(platinum)</span>)}</span>
            <span className="dark:text-white">{amountDisplay(props.price)}</span>
            <span className="ml-2 text-sm font-normal dark:text-white"><em>Last updated: {lastUpdated}</em></span>
          </div>
          <div className="w-full flex ml-6 align-bottom">
            <span className={columnClasses}>
              <Input
                name="tm"
                label="TM"
                autoComplete="one-time-code"
              />
            </span>
            <span className={columnClasses}>
              <Input
                name="etix"
                label="Etix"
                autoComplete="one-time-code"
              />
            </span>
            <span className={columnClasses}>
              <Input
                name="venue"
                label="Venue"
                autoComplete="one-time-code"
              />
            </span>
            <span className={columnClasses}>
              <Input
                name="fan_club"
                label="Fan Club"
                autoComplete="one-time-code"
              />
            </span>
            <span className={columnClasses}>
              <Input
                name="outlets"
                label="Outlets"
                autoComplete="one-time-code"
              />
            </span>
            <span className={columnClasses}>
              <Input
                name="vip"
                label="VIP"
                autoComplete="one-time-code"
              />
            </span>
            {showSave && (
              <span className="ml-6 pt-10">
                <Button>&nbsp; Save &nbsp;</Button>
              </span>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

const Event = (props) => {
  const { event, sections } = props
  const [open, setOpen] = useState(false)
  const [showSave, setShowSave] = useState(false)
  const methods = useForm()

  useEffect(() => {
    setShowSave(methods.formState.isDirty)
  }, [methods.formState])

  const headerClasses = classnames('text-xl font-semibold w-full p-4', {
    'bg-blue-100': !showSave,
    'bg-yellow-200': open && showSave,
    'hover:bg-blue-300': !open,
  })

  return (
    <div className="w-full mb-4 border border-1 border-gray-400">
      <h4
        className={headerClasses}
        onClick={() => setOpen(!open)}
      >{event.name} : {event.date} : {event.venue.name}</h4>
      {open && (
        <div className="p-2">
          {event.manifests.map((manifest, index) => (
            <SectionTickets
              key={index}
              eventID={event.id}
              {...manifest}
            />
          ))}
        </div>
      )}
      {showSave && (
        <div className="w-full my-4 pr-2 text-right">
          <Button>Save Changes</Button>
        </div>
      )}

    </div>
  )
}

export const TicketsHome = () => {
  const events = useQuery(["events-on-sale"], () => getOnSaleEvents())
  usePageTitle(events.isSuccess ? `/c${String.fromCharCode(92)}ticketing` : `/c${String.fromCharCode(92)}ave_good...`);

  if (events.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }

  return (
    <>
      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0 mb-12">
        <div className="w-full flex justify-between items-center">
          <BreadCrumbs links={[{ text: "Tickets", url: "/tickets" }]} />
        </div>
        <div className="my-4">
          <h2 className="text-xl font-bold dark:text-white">All On-Sale Events</h2>
        </div>
        {events.data.map((event, index) => (
          <Event key={index} event={event} />
        ))}
        {events.data.length == 0 && (
          <div className="my-4 w-full text-center">
            <span className=""><em>No events found</em></span>
          </div>
        )}
        <div className="py-8"><p></p></div>
      </div>
    </>
  );
};
