import { useNavigate } from "react-router-dom"

const Crumb = (props) => {
  const { text, url, last } = props
  const navigate = useNavigate()

  return (
    <li>
      <div className="flex items-center">
        <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
        {url && (
          <a
            onClick={() => navigate(url)}
            className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
          >
            {text}
          </a>
        )}
        {!url && (
          <span
            className="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-600 dark:hover:text-white"
          >
            {text}
          </span>
        )}
      </div>
    </li>
  )
}

export const BreadCrumbs = (props) => {
  const { links } = props
  const navigate = useNavigate()

  const linksLength = links.length

  return (
    <nav className="cursor-pointer flex my-4" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        <li className="inline-flex items-center">
          <a onClick={() => navigate('/dashboard')} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
            <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
            Home
          </a>
        </li>
        {links.map((link, index) => (
          <Crumb
            key={index}
            text={link.text}
            url={link.url}
            last={(index === linksLength - 1) && linksLength > 1}
          />
        ))}
      </ol>
    </nav>

  );
};
