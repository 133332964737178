import React, { useState, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Select from "react-select";
import { Table, TableRow } from "./AppTable2";
import {
  createEventExpense,
  getExpenseGroups,
  getEventExpenses,
  updateEventExpense,
} from "../queries/accounting";

type Book = "offer" | "actual" | "artist" | "copro" | "final";

interface ExpenseTableProps {
  id: string;
  book?: Book;
}

const ExpenseTable: React.FC<ExpenseTableProps> = ({ id, book = "offer" }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [newExpenseData, setNewExpenseData] = useState({
    description: "",
    amount: "",
    paymentMethod: "",
    checkNumber: "",
    notes: "",
  });
  const queryClient = useQueryClient();

  const groupsQuery = useQuery(["expense-groups"], getExpenseGroups);
  const expensesQuery = useQuery(["event-expenses", id, book], () =>
    getEventExpenses(id, book)
  );

  const createExpenseMutation = useMutation({
    mutationFn: async (data: any) => createEventExpense(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["event-expenses", id, book]);
    },
  });

  const updateExpenseMutation = useMutation({
    mutationFn: async (data: any) => updateEventExpense(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["event-expenses", id, book]);
    },
  });

  const data = useMemo(() => {
    if (!expensesQuery.data) return [];

    return expensesQuery.data.map((expense) => ({
      id: expense.id,
      description: expense.description || "UNKNOWN",
      amount: expense.amount,
      paymentMethod: expense.payment_method,
      checkNumber: expense.check_number,
      notes: expense.notes,
      subgroup: expense.subgroup,
    }));
  }, [expensesQuery.data]);

  const handleFieldUpdate = (field: string, value: any, expenseId: number) => {
    const exclude_artist = book !== "artist";
    const exclude_copro = book !== "copro";
    const exclude_final = book !== "final";
    const entitiesToStayDefault = (): ("artist" | "copro" | "final")[] => {
      if (book === "offer") return [];
      if (exclude_artist && exclude_copro && exclude_final) return [];
      if (!exclude_artist && !exclude_copro && !exclude_final) return [];
      if (!exclude_artist && exclude_artist && exclude_copro)
        return ["copro", "final"];
      if (!exclude_copro && exclude_artist && exclude_final)
        return ["artist", "final"];
      if (!exclude_final && exclude_artist && exclude_copro)
        return ["artist", "copro"];
      return [];
    };

    console.log(
      "exclude_artist, exclude_copro, exclude_final",
      exclude_artist,
      exclude_copro,
      exclude_final
    );
    const expenseToUpdate = data.find((expense) => expense.id === expenseId);
    if (!expenseToUpdate) return;
    // const entitiesToUpdateToDefaults = entitiesToStayDefault();
    updateExpenseMutation.mutate({
      expense_id: expenseId,
      payment_method:
        field === "paymentMethod" ? value : expenseToUpdate.paymentMethod,
      check_number:
        field === "checkNumber" ? value : expenseToUpdate.checkNumber,
      description:
        field === "description" ? value : expenseToUpdate.description,
      amount: field === "amount" ? value : expenseToUpdate.amount,
      notes: field === "notes" ? value : expenseToUpdate.notes,
      exclude_artist,
      exclude_copro,
      exclude_final,
    });
  };

  const handleAddExpense = () => {
    // TODO: update this to use subgroup id
    createExpenseMutation.mutate({
      subgroup: 1,
      payment_method: newExpenseData.paymentMethod,
      check_number: newExpenseData.checkNumber,
      description: newExpenseData.description,
      amount: newExpenseData.amount || "0", // Use input value or default to "0"
      notes: newExpenseData.notes,
      is_offer: true,
      exclude_artist: false,
      exclude_copro: false,
      exclude_final: false,
    });

    // Reset form
    setIsAdding(false);
    setSelectedGroup(null);
    setNewExpenseData({
      description: "",
      amount: "",
      paymentMethod: "",
      checkNumber: "",
      notes: "",
    });
  };

  const customRows = isAdding
    ? [
        {
          render: () => (
            <>
              <TableRow
                onChange={(field: string, value: any) => {
                  setNewExpenseData((prev) => ({
                    ...prev,
                    [field]: value,
                  }));
                }}
                columns={[
                  {
                    title: "Description",
                    accessor: "description",
                    textAlign: "start",
                    editable: true,
                  },
                  {
                    title: "Amount",
                    accessor: "amount",
                    textAlign: "end",
                    editable: true,
                  },
                  {
                    title: "Payment Method",
                    accessor: "paymentMethod",
                    textAlign: "center",
                    editable: true,
                  },
                  {
                    title: "Check Number",
                    accessor: "checkNumber",
                    textAlign: "center",
                    editable: true,
                  },
                  {
                    title: "Notes",
                    accessor: "notes",
                    textAlign: "start",
                    editable: true,
                  },
                ]}
                rowData={newExpenseData}
                onBlur={(field, value) => {
                  setNewExpenseData((prev) => ({
                    ...prev,
                    [field]: value,
                  }));
                }}
              />
              <Select
                options={groupsQuery.data?.map((group) => ({
                  label: group.name,
                  value:
                    group.name.toLowerCase().replace(/\s+/g, "-") +
                    ";id:" +
                    group.id,
                }))}
                value={selectedGroup}
                onChange={(value) => setSelectedGroup(value)}
                placeholder="Expense Subgroup"
                isDisabled={groupsQuery.isLoading || groupsQuery.isError}
              />
            </>
          ),
        },
      ]
    : [];

  return (
    <div className="w-full mt-6">
      <Table
        className="w-full"
        tableTitle="Expenses"
        grid
        textCenter
        columns={[
          {
            title: "Description",
            accessor: "description",
            textAlign: "start",
            editable: true,
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("description", value, data[rowIndex].id),
          },
          {
            title: "Amount",
            accessor: "amount",
            textAlign: "end",
            editable: true,
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("amount", value, data[rowIndex].id),
          },
          {
            title: "Payment Method",
            accessor: "paymentMethod",
            textAlign: "center",
            editable: true,
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("paymentMethod", value, data[rowIndex].id),
          },
          {
            title: "Check Number",
            accessor: "checkNumber",
            textAlign: "center",
            editable: true,
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("checkNumber", value, data[rowIndex].id),
          },
          {
            title: "Notes",
            accessor: "notes",
            textAlign: "start",
            editable: true,
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("notes", value, data[rowIndex].id),
          },
        ]}
        data={data}
        customRows={customRows}
        hideHeaders={false}
      />
      <div className="w-full flex justify-end gap-2">
        {!isAdding && (
          <button
            onClick={() => setIsAdding(true)}
            className="bg-gray-400 disabled:bg-gray-200 rounded px-4 py-1 text-white font-sans text-sm mt-2"
          >
            Add Expense
          </button>
        )}
        {isAdding && (
          <>
            <button
              onClick={handleAddExpense}
              className="bg-green-500 text-white px-2 py-1 rounded"
            >
              Save
            </button>
            <button
              onClick={() => setIsAdding(false)}
              className="bg-red-500 text-white px-2 py-1 rounded"
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ExpenseTable;
