import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import * as yup from "yup"
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { FormBuilder, Input, TextInput, SaveCancel } from "@/components/Form"
import { getEvent, createEventImage } from "@/queries/events"
import { BreadCrumbs } from '@/components/BreadCrumbs'
import Button from '@/components/Button'

export const EventImageAdd = () => {
  const { id } = useParams()  // Event ID
  const event = useQuery(["event-detail", id], () => getEvent(id))
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [file, setFile] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating image")
      //console.dir(data)
      return createEventImage(id, data)
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["event", "images", id])
      navigate(`/events/${id}`)
    },
    onSettled: () => setIsSubmitting(false),
  })

  const addSchema = yup.object().shape({
    name: yup.string(),
    file: yup.string(),
    artist_id: yup.number(),
    description: yup.string(),
  })

  const handleSubmit = (data) => {
    console.log("In submit")
    addSchema.validate(data, { abortEarly: false })
      .then((validatedData) => {
        console.log('Validation succeeded:', validatedData);
      })
    .catch((err) => {
      console.error('Validation errors:', err.inner);
    });


    const formData = new FormData()
    formData.append("name", data.name)
    formData.append("event_id", data.id)
    formData.append("file", file)
    formData.append("description", data.description)
    setIsSubmitting(true)
    mutation.mutate(formData)
  }

  // Wait for Event to load
  if (event.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs links={[{ text: "Events", url: "/events" }, { text: `Edit: ${event.data.name}`, url: `/events/edit/${event.data.id}` }, { text: 'Add Image' }]} />

        <h3 className="text-xl font-bold dark:text-white">Add New Image for {event.data.name}</h3>
        <div className="py-6 min-w-full">
          <FormBuilder onSubmit={(data) => handleSubmit(data)} schema={addSchema}>
            <Input
              name="name"
              label="Name"
              autoFocus={true}
              autoComplete="one-time-code"
            />
            <Input
              name="file"
              label="File"
              type="file"
              onChange={(event) => {
                setFile(event.target.files[0])
              }}
            />
            <TextInput
              name="description"
              label="Description"
              placeholder="Optional description"
            />
            <SaveCancel
              label="Add Image"
              cancelURL={`/events/${id}`}
              isSubmitting={isSubmitting}
              submittingMessage="Uploading image..."
            />
          </FormBuilder>
        </div>
      </div>
    </>
  );
};
