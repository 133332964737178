import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import * as yup from "yup"
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useFormContext } from "react-hook-form";
import {
  FormBuilder,
  FormMultiSelect,
} from "@/components/Form"

import { getEvents, getEventsSettings } from "@/queries/events"
import { getVenues, getVenuesSettings } from "@/queries/venues"
import { getArtists, getArtistsSettings } from "@/queries/artists"
import { createVendorArtist, getVendor, getVendorsSettings } from "@/queries/vendors"
import { BreadCrumbs } from '@/components/BreadCrumbs'
import Button from '@/components/Button'

export const VendorArtistsAdd = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const artists = useQuery(["artists"], getArtists, getArtistsSettings)
  const vendor = useQuery(["vendor-detail", id], () => getVendor(id))

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating artist for vendor")
      return createVendorArtist(id, data)
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["vendors"])
      navigate(`/vendors/${id}`)
    }
  })

  const updateSchema = yup.object().shape({
      artists_id: yup.object(),
      vendor_id: yup.number(),
      description: yup.string(),
  })

  const handleSubmit = (data) => {
    console.log("In submit")
    console.log(data)
    data.artists_id = data.artists.map(artist => { return artist.value })
    data.vendor_id = id

    mutation.mutate(data)
  }

  if (artists.isLoading || vendor.isLoading ) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }

  const artistOptions = artists.data.map(artist => {
    return { value: artist.id, label: artist.name }
  })

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs links={[{ text: "Vendors", url: `/vendors/${vendor.data.id}` }, { text: 'Add Artist to '+vendor.data.name }]} />

        <h3 className="text-xl font-bold mb-4">Add Artist to Vendor</h3>
        <div className="min-w-full">
          <FormBuilder onSubmit={(data) => handleSubmit(data)} schema={updateSchema}>
            <FormMultiSelect
              name="artists"
              label="Artist"
              options={artistOptions}
            />
            <div className="flex-inline float-right mb-12">
              <button
                type="button"
                onClick={() => navigate(`/vendors/${vendor.data.id}`) }
                className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                Cancel
              </button>
              <Button type="submit">Done</Button>
            </div>
          </FormBuilder>
        </div>
      </div>
    </>
  );
};
