import dayjs from 'dayjs'
import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import { Alert } from "@/components/Alert"
import * as yup from "yup"
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useFormContext } from "react-hook-form";
import { Datepicker, FormBuilder, Input, TextInput, FormSelect } from "@/components/Form"
import { getArtist, updateArtist } from "@/queries/artists"
import { BreadCrumbs } from '@/components/BreadCrumbs'
import { usePageTitle } from '@/utils/pagetitle';

export const ArtistEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const artist = useQuery(["artist-detail", id], () => getArtist(id))
  usePageTitle(artist.isSuccess ? `/c${String.fromCharCode(92)}${artist.data.name}` : `/c${String.fromCharCode(92)}ave_good...`);

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> updating artist")
      console.dir(data)

      return updateArtist(id, data)
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["artists"])
      queryClient.invalidateQueries(["artist-detail"])
      console.log("Moving!")
      navigate(`/artists/${id}`)
    }
  })

  if (artist.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }

  const updateSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),
    abbr: yup.string(),
    url: yup.string().url(),
    email: yup.string().email(),
    phone: yup.string(),
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipcode: yup.string(),
    twitter: yup.string(),
    instagram: yup.string(),
    facebook: yup.string(),
  })

  let values = {
    name: artist.data.name || "",
    abbr: artist.data.abbr || "",
    description: artist.data.description || "",
    url: artist.data.url || "",
    email: artist.data.email || "",
    phone: artist.data.phone || "",
    address: artist.data.address || "",
    city: artist.data.city || "",
    state: artist.data.state || "",
    zipcode: artist.data.zipcode || "",
    twitter: artist.data.twitter || "",
    instagram: artist.data.instagram || "",
    facebook: artist.data.facebook || "",
  }

  const handleSubmit = (data) => {
    console.log("In submit")
    console.dir(data)
    mutation.mutate(data)
  }

  console.dir(values)
  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs links={[{ text: "Artists", url: "/artists" }, { text: artist.data.name }]} />

        <h3 className="text-xl font-bold mb-4 dark:text-white">Edit - {artist.data.name}</h3>
        <div className="py-6 min-w-full">
          <FormBuilder defaultValues={values} onSubmit={(data) => handleSubmit(data)} schema={updateSchema}>
            <Input
              name="name"
              label="Name"
            />
            <Input
              name="abbr"
              label="Abbreviation"
            />
            <Input
              name="description"
              label="Description"
            />
            <Input
              name="url"
              label="URL"
            />
            <Input
              name="email"
              label="Email"
            />
            <Input
              name="phone"
              label="Phone"
            />
            <Input
              name="address"
              label="Address"
            />
            <Input
              name="city"
              label="City"
            />
            <Input
              name="state"
              label="State"
            />
            <Input
              name="zipcode"
              label="Zipcode"
            />
            <Input
              name="twitter"
              label="Twitter"
            />
            <Input
              name="instagram"
              label="Instagram"
            />
            <Input
              name="facebook"
              label="Facebook"
            />

            <div className="flex-inline">
              <button
                type="button"
                onClick={() => navigate(`/artists/${artist.data.id}`)}
                className="ml-4 mr-4 float-right rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                Cancel
              </button>
              <button type="submit" className="rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white">Save</button>
            </div>
          </FormBuilder>
        </div>
      </div>
    </>
  );
};
