import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import { Alert } from "@/components/Alert"
import * as yup from "yup"
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { FormBuilder, Input, TextInput, FormSelect, selectOptionToValue } from "@/components/Form"
import { getVenue, updateVenue } from "@/queries/venues"
import { BreadCrumbs } from '@/components/BreadCrumbs'
import Button from '@/components/Button'
import { usePageTitle } from '@/utils/pagetitle';


export const VenueEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const venue = useQuery(["venue-detail", id], () => getVenue(id))
  usePageTitle(venue.isSuccess ? `/c${String.fromCharCode(92)}${venue.data.name}` : `/c${String.fromCharCode(92)}ave_good...`);

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> updating venue")
      if (data.witholding_percentage == "") {
        data.witholding_percentage = null
      }

      if (data.capacity == "") {
        data.capacity = null
      }

      if (data.rent_rate == "") {
        data.rent_rate = "0.00"
      }

      if (data.sales_tax == "") {
        data.sales_tax = "0.00"
      }

      data = selectOptionToValue("region", data)
      data = selectOptionToValue("ticketing", data)
      data = selectOptionToValue("partner", data)

      console.dir(data)
      return updateVenue(id, data)
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["venues"])
      queryClient.invalidateQueries(["venue-detail"])
      console.log("Moving!")
      navigate(`/venues/${id}`)
    }
  })

  if (venue.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }

  const updateSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),
    abbr: yup.string(),
    url: yup.string().url(),
    email: yup.string().email(),
    phone: yup.string(),
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipcode: yup.string(),
    twitter: yup.string(),
    instagram: yup.string(),
    facebook: yup.string(),
    witholding_percentage: yup.string(),
    sales_tax: yup.string(),
    capacity: yup.string(),
    rent_rate: yup.string(),
    the_deal: yup.string(),
    market: yup.string(),
    region: yup.mixed().nullable().notRequired(),
    partner: yup.mixed().nullable().notRequired(),
    ticketing: yup.mixed().nullable().notRequired(),
  })

  const regionSelectOptions = [
      { value: "midwest",   label: "Midwest"},
      { value: "southeast", label: "Southeast"},
      { value: "northeast", label: "Northeast"},
      { value: "southwest", label: "Southwest"},
      { value: "northwest", label: "Northwest"},
  ]
 
  let regionObject = null
  if (venue.data.region) {
    regionObject = { value: venue.data.region, label: regionSelectOptions.find(option => option.value === venue.data.region).label }
  }

  const ticketingSelectOptions = [
      { value: "ticketmaster", label: "Ticketmaster"},
      { value: "evenue", label: "eVenue"},
      { value: "seatgeek", label: "Seat Geek"},
      { value: "etix", label: "eTix"},
      { value: "ticketweb", label: "Ticketweb"},
      { value: "ticketswest", label: "TicketsWest"},
      { value: "axs", label: "Axs"},
      { value: "eventbrite", label: "Eventbrite"},
      { value: "seetickets", label: "See Tickets"},
      { value: "ovationtix", label: "OvationTix"},
      { value: "ticketomaha", label: "Ticket Omaha"},
      { value: "selectaseat", label: "Select-A-Seat"},
      { value: "ticketstar", label: "Ticket Star"},
      { value: "venue", label: "Venue"},
  ]
 
  let ticketingObject = null
  if (venue.data.ticketing) {
    ticketingObject = { value: venue.data.ticketing, label: ticketingSelectOptions.find(option => option.value === venue.data.ticketing).label }
  }

  const partnerSelectOptions = [
        { value: "asm", label: "ASM"},
        { value: "rental", label: "Rental"},
        { value: "copro", label: "Co-Pro"},
        { value: "copro_newwest", label: "Co-Pro/New West"},
        { value: "copro_ea", label: "Co-Pro/EA"},
        { value: "direct", label: "Direct"},
        { value: "venuecoalition", label: "VenueCoalition"},
        { value: "outback", label: "Outback"},
        { value: "confirmedent", label: "Confirmed Ent."},
        { value: "mammoth", label: "MAMMOTH"},
        { value: "copro_billy", label: "Co-Pro/Billy Hardison"},
        { value: "copro_livenation", label: "Co-Pro/LiveNation"},
        { value: "rental_tommy", label: "Rental/Tommy Ginoza"},
        { value: "ea", label: "EA"},
  ]
 
  let partnerObject = null
  if (venue.data.partner) {
    partnerObject = { value: venue.data.partner, label: partnerSelectOptions.find(option => option.value === venue.data.partner).label }
  }



  let values = {
    name: venue.data.name || "",
    abbr: venue.data.abbr || "",
    description: venue.data.description || "",
    url: venue.data.url || "",
    email: venue.data.email || "",
    phone: venue.data.phone || "",
    address: venue.data.address || "",
    city: venue.data.city || "",
    state: venue.data.state || "",
    zipcode: venue.data.zipcode || "",
    twitter: venue.data.twitter || "",
    instagram: venue.data.instagram || "",
    facebook: venue.data.facebook || "",
    witholding_percentage: venue.data.witholding_percentage || "",
    sales_tax: venue.data.sales_tax || "",
    capacity: venue.data.capacity || "",
    rent_rate: venue.data.rent_rate || "",
    the_deal: venue.data.the_deal || "",
    market: venue.data.market || "",
    region: regionObject || "",
    partner: partnerObject || "",
    ticketing: ticketingObject || "",
  }

  const handleSubmit = (data) => {
    console.log("In submit")
    mutation.mutate(data)
  }

  
  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs links={[{ text: "Venues", url: "/venues" }, { text: venue.data.name }]} />

        <h3 className="text-xl font-bold mb-4 dark:text-white">Edit - {venue.data.name}</h3>
        <div className="py-6 min-w-full">
          <FormBuilder defaultValues={values} onSubmit={(data) => handleSubmit(data)} schema={updateSchema}>
            <Input
              name="name"
              label="Name"
            />
            <Input
              name="abbr"
              label="Abbreviation"
            />
            <TextInput
              name="description"
              label="Description"
            />
            <Input
              name="url"
              label="URL"
            />
            <Input
              name="email"
              label="Email"
            />
            <Input
              name="phone"
              label="Phone"
            />
            <Input
              name="address"
              label="Address"
            />
            <Input
              name="city"
              label="City"
            />
            <Input
              name="state"
              label="State"
            />
            <Input
              name="zipcode"
              label="Zipcode"
            />
            <Input
              name="twitter"
              label="Twitter"
            />
            <Input
              name="instagram"
              label="Instagram"
            />
            <Input
              name="facebook"
              label="Facebook"
            />
            <Input
              name="witholding_percentage"
              label="State Witholding Percentage"
            />
            <Input
              name="sales_tax"
              label="Sales Tax"
            />
            <Input
              name="capacity"
              label="Capacity"
            />
            <TextInput
              name="rent_rate"
              label="Rent Rate"
            />
            <TextInput
              name="the_deal"
              label="The Deal"
            />
            <TextInput
              name="market"
              label="market"
            />
            <FormSelect
              name="region"
              label="Region"
              options={regionSelectOptions}
            />
            <FormSelect
              name="partner"
              label="Partner"
              options={partnerSelectOptions}
            />
            <FormSelect
              name="ticketing"
              label="Ticketing"
              options={ticketingSelectOptions}
            />
            <div className="flex-inline float-right mb-12">
              <button
                type="button"
                onClick={() => navigate(`/venues/${venue.data.id}`)}
                className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                Cancel
              </button>
              <Button type="submit">Save</Button>
            </div>
          </FormBuilder>
        </div>
      </div>
    </>
  );
};
