import React, { Fragment, useState, useEffect } from 'react';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import {
  ExclamationCircleIcon,
  CalendarDaysIcon,
  HomeModernIcon,
  MusicalNoteIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  BuildingOfficeIcon,
  DocumentIcon
} from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Icon = ({ item }) => {
  const iconClasses = "h-6 w-6 text-white";
  switch (item.type) {
    case 'venue':
      return <HomeModernIcon className={iconClasses} aria-hidden="true" />;
    case 'event':
      return <CalendarDaysIcon className={iconClasses} aria-hidden="true" />;
    case 'artist':
      return <MusicalNoteIcon className={iconClasses} aria-hidden="true" />;
    case 'people':
      return <UserIcon className={iconClasses} aria-hidden="true" />;
    case 'vendor':
      return <BuildingOfficeIcon className={iconClasses} aria-hidden="true" />;
    case 'file':
      return <DocumentIcon className={iconClasses} aria-hidden="true" />;
    default:
      return <QuestionMarkCircleIcon className={iconClasses} aria-hidden="true" />;
  }
};

const highlightMatch = (text, searchTerm) => {
  if (!text || !searchTerm) return text || '';
  const parts = text.toString().split(new RegExp(`(${searchTerm})`, 'gi'));
  return parts.map((part, index) => 
    part.toLowerCase() === searchTerm.toLowerCase() ? 
      <span key={index} className="bg-yellow-200 font-semibold">{part}</span> : part
  );
};

const ResultText = ({ item, active, searchQuery }) => {
  const searchTerm = searchQuery.toLowerCase().replace(/^(file:|venue:|people:)/, '').trim();

  let display = '';
  switch (item.type) {
    case 'venue':
      display = <span>{highlightMatch(item.venue_name, searchTerm)} - {highlightMatch(item.city, searchTerm)}, {highlightMatch(item.state, searchTerm)}</span>;
      break;
    case 'event':
      display = (
        <div className="grid grid-cols-1 gap-1">
          <span className="font-semibold">{item.event_date_display}</span>
          <span>{highlightMatch(item.venue_name, searchTerm)} - {highlightMatch(item.city, searchTerm)}, {highlightMatch(item.state, searchTerm)}</span>
          {item.matched_file && (
            <div className="text-xs text-gray-600">
              file: {highlightMatch(item.matched_file, searchTerm)}
            </div>
          )}
        </div>
      );
      break;
    case 'people':
      display = <span>{highlightMatch(item.title, searchTerm)} - {highlightMatch(item.city, searchTerm)}, {highlightMatch(item.state, searchTerm)}</span>;
      break;
    case 'artist':
      display = <span>{highlightMatch(item.artist_name, searchTerm)}</span>;
      break;
    case 'file':
      display = <span>{highlightMatch(item.filename, searchTerm)}</span>;
      break;
    default:
      display = <span>{highlightMatch(item.description, searchTerm)}</span>;
      break;
  }

  return (
    <div className={classNames('text-sm w-full', active ? 'text-gray-700' : 'text-gray-500')}>
      {display}
    </div>
  );
};

const ResultItem = ({ active, item, searchQuery }) => {
  return (
    <>
      <div
        className={classNames(
          'flex h-10 w-10 flex-none items-center justify-center rounded-lg',
          item.type === 'venue' ? 'bg-sky-500' : 'bg-gray-300',
          (item.type === 'event' && !item.event_past) ? 'bg-green-500' : 'bg-gray-300',
          item.type === 'artist' ? 'bg-red-500' : 'bg-gray-300',
          item.type === 'vendor' ? 'bg-yellow-500' : 'bg-gray-300',
          item.type === 'file' ? 'bg-purple-500' : 'bg-gray-300',
          item.type === 'people' ? 'bg-orange-500' : 'bg-gray-300',
          item.event_past ? 'bg-green-300' : 'bg-gray-300',
        )}
      > 
        <Icon item={item} />
      </div>
      <div className="ml-4 flex-auto">
        <p
          className={classNames(
            'text-sm font-medium',
            active ? 'text-gray-900' : 'text-gray-700'
          )}
        >
          {highlightMatch(item.title || item.venue_name || item.artist_name || item.filename, searchQuery.replace(/^(file:|venue:|people:)/, '').trim())}
        </p>
        <ResultText item={item} active={active} searchQuery={searchQuery} />
      </div>
    </>
  );
};

const SearchModal = ({ isOpen, setOpen }) => {
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!search) {
      setResults([]);
      return;
    }

    let url = new URL(`${window.location.origin}/api/search/`);
    const lowerSearch = search.toLowerCase();
    let searchTerm = lowerSearch;
    let filterType = '';

    if (lowerSearch.startsWith('file:')) {
      searchTerm = lowerSearch.replace('file:', '').trim();
      filterType = 'file';
    } else if (lowerSearch.startsWith('venue:')) {
      searchTerm = lowerSearch.replace('venue:', '').trim();
      filterType = 'venue';
    } else if (lowerSearch.startsWith('people:')) {
      searchTerm = lowerSearch.replace('people:', '').trim();
      filterType = 'people';
    }

    url.searchParams.append("q", searchTerm);

    fetch(url)
      .then(response => response.json())
      .then(data => {
        let processedResults = data.hits;

        if (filterType === 'file') {
          processedResults = processedResults.flatMap(item => {
            if (item.file_names) {
              return item.file_names
                .filter(fileName => fileName.toLowerCase().includes(searchTerm))
                .map(fileName => ({
                  id: `${item.id}-${fileName}`,
                  type: 'file',
                  filename: fileName,
                  title: fileName,
                  url: item.url,
                }));
            }
            return [];
          });
        } else if (filterType) {
          // Client-side filtering for 'venue:' and 'people:' searches
          processedResults = processedResults.filter(item => item.type === filterType);
        }

        // For non-file searches, add matched_file property
        if (filterType !== 'file') {
          processedResults = processedResults.map(item => {
            if (item.file_names) {
              const matchedFile = item.file_names.find(fileName => 
                fileName.toLowerCase().includes(searchTerm)
              );
              return matchedFile ? { ...item, matched_file: matchedFile } : item;
            }
            return item;
          });
        }

        setResults(processedResults);
      })
      .catch(error => console.error('Search failed:', error));
  }, [search]);

  return (
    <Transition.Root show={isOpen} as={Fragment} afterLeave={() => setSearch('')} appear>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700 bg-opacity-60 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black transition-all">
              <Combobox onChange={(item) => (navigate(item.url))}>
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-1 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:outline-0 focus:ring-1 focus:ring-black sm:text-sm"
                    placeholder="Search... (Use 'file:', 'venue:', or 'people:' for specific searches)"
                    onChange={(event) => setSearch(event.target.value)}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                  />
                </div>

                {results.length > 0 && (
                  <Combobox.Options static className="max-h-96 scroll-py-3 overflow-y-auto p-3">
                    {results.map((item) => (
                      <Combobox.Option
                        key={item.id}
                        value={item}
                        className={({ active }) =>
                          classNames('flex cursor-default select-none rounded-xl p-3', active && 'bg-gray-200')
                        }
                      >
                        {({ active }) => <ResultItem item={item} active={active} searchQuery={search} />}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}

                {search !== '' && results.length === 0 && (
                  <div className="px-6 py-14 text-center text-sm sm:px-14">
                    <ExclamationCircleIcon
                      className="mx-auto h-6 w-6 text-gray-400"
                    />
                    <p className="mt-4 font-semibold text-gray-900">No results found</p>
                    <p className="mt-2 text-gray-500">No matches found for this search term. Please try again.</p>
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SearchModal;
