import React, { useState, useEffect } from "react"
import { FormBuilder, Input } from "./Form"
import * as yup from "yup"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import { Logo } from "@/components/Header/TopHeader/Logo"
import { loginUser } from "@/queries/auth"
import { getMe, userQuerySettings } from "@/queries/auth"
import "./login.css"

const Login = (props) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [error, setError] = useState(false)
  const [running, setRunning] = useState(false)
  const user = useQuery(["me"], getMe, userQuerySettings)

  useEffect(() => {
    if (user.isSuccess) {
      console.log("---> Sending logged in user to logincheck")
      navigate("/logincheck")
    }
  }, [user])

  const loginMutation = useMutation({
    mutationFn: (data) => {
      return loginUser(data)
    },
    onMutate: (variables) => {
      setRunning(true)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("me");
      setError(false)
      setRunning(false)
      navigate("/logincheck");
    },
    onError: (error, variables, context) => {
      setError(error.data.message)
      setRunning(false)
    },
  })

  const onSubmit = (data) => {
    setError(false)
    loginMutation.mutate(data)
  };

  const loginSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  });

  return (
    <section className="bg-blue-300 dark:bg-blue-900 background-image">
      <img className="bg-hux" src="/static/images/tusk_login_bg.jpg" alt="" />
      <div className="flex relative flex-col items-center justify-center px-6 py-1 mx-auto md:h-screen lg:py-0">
        <div className="mb-6">
          <Logo />
        </div>
        <div className="mt-[-4] w-full bg-transparent rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 bg-transparent dark:bg-gray-800 dark:border-gray-700">
          <div className="p-1 space-y-4 md:space-y-1 sm:p-3">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            </h1>
            {error && <p className="text-sm text-red-600">{error}</p>}
            <FormBuilder onSubmit={onSubmit} schema={loginSchema}>
              <Input
                name="email"
                type="email"
                placeholder="email@mammothlive.com"
                label=""
                autoFocus
              />
              <Input
                name="password"
                type="password"
                placeholder="••••••••"
                label=""
              />
              <div className="flex justify-end shadow text-white">
                <a
                  href="/password/reset"
                  className="text-sm font-medium text-white-900 hover:underline dark:text-blue-500 mb-4"
                >
                  Forgot your password?
                </a>
              </div>
              <button
                type="submit"
                disabled={running}
                className="mt-2 w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {running ? "Logging in" : "Sign in"}
              </button>
            </FormBuilder>
          </div>
        </div>
      </div >
    </section >
  );
};

export default Login;
