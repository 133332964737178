import { useState } from "react";
import Button from "@/components/Button";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TrashIcon } from "@heroicons/react/24/outline";

const Image = (props) => {
  const { image, deleteQuery, editable } = props;
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: (id) => {
      console.log("---> delete image");
      console.dir(id);
      return deleteQuery(id);
    },
    onSuccess: async (data) => {
      // Invalidate them all as we're not sure which one is calling us
      queryClient.invalidateQueries(["artist", "images"]);
      queryClient.invalidateQueries(["venue", "images"]);
      queryClient.invalidateQueries(["vendor", "images"]);
      queryClient.invalidateQueries(["event", "images"]);
    },
  });

  let secondField = (
    <td className="cursor-pointer p-4 ">
      {image.description}
      <TrashIcon
        className="h-6 w-6 float-right text-gray-400 hover:text-red-400 cursor-pointer"
        onClick={() => setDeleteConfirm(true)}
      />
    </td>
  );

  if (!editable) {
    secondField = null;
  }

  if (deleteConfirm) {
    secondField = (
      <td className="p-4 border border-gray-200 text-gray-800 bg-red-300">
        <span>Are you sure you want to delete this image?</span>
        <div className="float-right">
          <button
            className="mr-2 rounded bg-red-800 hover:bg-red-700 px-4 py-1 text-white"
            onClick={() => deleteMutation.mutate(image.id)}
          >
            Yes
          </button>
          <button
            className="rounded bg-gray-500 hover:bg-gray-400 px-4 py-1 text-white"
            onClick={() => setDeleteConfirm(false)}
          >
            No
          </button>
        </div>
      </td>
    );
  }

  return (
    <tr className="hover:bg-gray-100 dark:hover:bg-gray-800">
      <td
        className="cursor-pointer p-4 hover:bg-blue-100 hover:border hover:border-blue-200 w-full sm:w-1/2 flex items-center"
        onClick={() => window.open(image.image_url, "_blank")}
      >
        <img
          src={image.image_url}
          alt={image.name}
          className="w-24 h-24 object-cover mr-4 rounded"
        />
        <span className="text-sm text-blue-500 hover:text-blue-700">
          {image.name}
        </span>
      </td>
      {secondField}
    </tr>
  );
};

const ImageDisplay = (props) => {
  const { images, addURL, deleteQuery, editable } = props;
  const navigate = useNavigate();

  return (
    <div className="flex flex-col mb-6 rounded-lg shadow-md border-t-[1px] border-[#595959] pt-[1rem]">
      {" "}
      <div className="w-full flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold dark:text-gray-200">Images</h3>
        <span>
          {editable && (
            <Button onClick={() => navigate(addURL)}>Add Image</Button>
          )}
        </span>
      </div>
      <div className="overflow-x-auto">
        {" "}
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-4 py-3">
                {" "}
                Image
              </th>
              <th scope="col" className="px-4 py-3">
                {" "}
                Description
              </th>
            </tr>
          </thead>
          <tbody>
            {images.isLoading && (
              <tr>
                <td
                  colSpan="2"
                  className="px-6 py-3 text-center text-sm text-gray-500 dark:text-gray-400"
                >
                  Loading...
                </td>
              </tr>
            )}
            {images.isSuccess &&
              images.data.map((image, index) => (
                <Image
                  key={image.id}
                  image={image}
                  deleteQuery={deleteQuery}
                  editable={editable}
                />
              ))}
            {images.isSuccess && !images.data.length && (
              <tr>
                <td
                  colSpan="2"
                  className="px-6 py-3 text-center text-sm text-gray-500 dark:text-gray-400"
                >
                  <em>No images found</em>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ImageDisplay;
