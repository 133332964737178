

const MammothIcon = (props) => {
  const { className } = props;
  return (
    <svg className={className} fill="currentColor" stroke="currentColor" width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m458.4 828c14.398-22.801 6-38.398 0-38.398-2.3984 0-4.8008 1.1992-6 4.8008-4.8008 14.398-16.801 32.398-16.801 32.398-14.398-7.1992-22.801-9.6016-28.801-9.6016-10.801 0-7.1992 9.6016-7.1992 9.6016s52.801 38.398 0 56.398c-3.6016 1.1992-6 1.1992-9.6016 1.1992-34.801 0-49.199-72-49.199-129.6-19.199 12-37.199 15.602-51.602 15.602h-9.6016v6c2.3984 98.398 58.801 147.6 108 147.6 10.801 0 20.398-2.3984 28.801-7.1992 50.406-24 42.004-88.801 42.004-88.801z" />
        <path d="m410.4 548.4c0-6-2.3984-12-7.1992-14.398-4.8008-3.6016-12-6-19.199-6-2.3984 0-6 0-8.3984 2.3984-14.398 7.1992-7.1992 193.2-96 193.2-10.801 0-22.801-2.3984-36-8.3984-68.398-30-105.6-38.398-122.4-38.398-14.398 0-14.398 6-3.6016 9.6016 21.602 8.3984 63.602 19.199 118.8 52.801 15.602 9.6016 33.602 15.602 52.801 15.602 50.398-0.003907 105.6-44.406 121.2-206.41z" />
        <path d="m279.6 706.8c49.199 0 62.398-91.199 70.801-140.4 4.8008-32.398 7.1992-45.602 18-51.602 4.8008-2.3984 10.801-3.6016 16.801-3.6016 8.3984 0 18 2.3984 27.602 8.3984 10.801 6 16.801 18 15.602 30-3.6016 32.398-8.3984 61.199-14.398 85.199h2.3984c9.6016 0 19.199-9.6016 20.398-25.199 1.1992-26.398 61.199-66 61.199-66l42-64.801c-24-18-20.398-62.398-20.398-62.398s10.801 32.398 20.398 36h1.1992c10.801 0 44.398-33.602 61.199-48 18-14.398-14.398-32.398-32.398-44.398s-30-12-30-12l39.602-6c-21.602-68.398-62.398-93.602-142.8-110.4-9.6016-2.3984-18-2.3984-26.398-2.3984-66 0-110.4 54-105.6 80.398 6 30 8.3984 32.398-24 142.8-26.398 86.398-12 166.8-4.8008 254.4h3.5977zm102-301.2c9.6016 0 18 8.3984 18 18 0 9.6016-8.3984 18-18 18-9.6016 0-18-8.3984-18-18 0-10.801 8.3984-18 18-18z" />
        <path d="m538.8 810c0-20.398-19.199-66-39.602-110.4 1.1992 24 2.3984 45.602 2.3984 61.199 2.3984 49.199-15.602 106.8-15.602 135.6 0 0-24 2.3984-27.602 22.801-3.6016 15.602 70.801 10.801 100.8 7.1992 2.3984-4.8008 6-8.3984 9.6016-12-10.797-20.402-29.996-62.402-29.996-104.4z" />
        <path d="m988.8 806.4c-9.6016-18-16.801-32.398-22.801-37.199-1.1992-1.1992-3.6016-1.1992-7.1992-1.1992-7.1992 0-18 2.3984-31.199 4.8008-12 2.3984-27.602 4.8008-44.398 7.1992 3.6016 8.3984 7.1992 16.801 10.801 22.801 12 21.602 24 92.398 14.398 98.398-9.6016 6-31.199 21.602-31.199 31.199 0 9.6016 109.2 15.602 112.8 0 1.1953-12-2.4023-74.398-1.2031-126z" />
        <path d="m1140 920.4c0-8.3984-8.3984-169.2-12-249.6-3.6016-80.398-42-183.6-42-183.6l42 68.398s-6-74.398-42-139.2-183.6-68.398-237.6-82.801c-52.801-14.398-109.2-68.398-189.6-70.801h-19.199c-42 0-68.398 6-84 9.6016 15.602 16.801 27.602 37.199 36 66l6 19.199-7.1992 1.1992c14.398 9.6016 27.602 21.602 28.801 37.199 0 4.8008-1.1992 14.398-10.801 24-3.6016 3.6016-9.6016 8.3984-15.602 13.199-21.602 19.199-36 31.199-46.801 36l10.801 8.3984-4.7969 9.6094-42 64.801-4.7969 4.7969c-14.398 9.6016-28.801 21.602-38.398 31.199 24 58.801 88.801 178.8 88.801 220.8 0 52.801 36 110.4 36 110.4-12 3.6016-20.398 18-24 28.801-3.6016 8.3984 2.3984 19.199 12 20.398 8.3984 1.1992 18 2.3984 27.602 2.3984 26.398 0 57.602-3.6016 79.199-7.1992 38.398-6 12-52.801 0-80.398-12.008-27.594-26.406-138-26.406-138 66 16.801 118.8 21.602 160.8 21.602 72 0 114-15.602 138-15.602 7.1992 0 13.199 1.1992 18 6 26.398 24 76.801 163.2 76.801 163.2-9.6016 4.8008-15.602 13.199-20.398 21.602-4.8008 10.801 2.3984 22.801 14.398 22.801h20.398c80.398-3.6016 72-36 72-44.402z" />
        <path d="m252 628.8c-1.1992-13.199-2.3984-27.602-2.3984-40.801-30 19.199-76.801 34.801-139.2 14.398 0 0-30-8.3984-33.602-32.398-3.6016-24-24 0-12 24 9.5977 21.602 56.398 63.602 187.2 34.801z" />
      </g>
    </svg>
  )
}

export default MammothIcon
