import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavigatorMenuItem } from "./NavigatorMenuItem";
import { usePerms } from "@/components/Auth";

const MenuItems = [
  {
    id: 1,
    title: "Dashboard",
    url: "/dashboard",
  },
  {
    id: 2,
    title: "Events",
    url: "/events",
  },
  {
    id: 3,
    title: "Venues",
    url: "/venues",
  },
  {
    id: 4,
    title: "Artists",
    url: "/artists",
  },
  {
    id: 5,
    title: "People",
    url: "/people",
  },
  {
    id: 6,
    title: "Calendar",
    url: "/calendar",
  },
  {
    id: 7,
    title: "Ticketing",
    url: "/tickets",
  },
  {
    id: 8,
    title: "Ticket Links",
    url: "/links",
  },
  {
    id: 9,
    title: "Staff",
    url: "/staff",
  },
  {
    id: 10,
    title: "Vendors",
    url: "/vendors",
  },
  {
    id: 11,
    title: "IT Requests",
    url: "/reqs",
  },
  {
    id: 12,
    title: "Admin Requests",
    url: "/reqs/admin",
  },
];

const getMenuItems = (perms) => {
  let items = [...MenuItems];

  if (!perms.edit_tickets) {
    items = items.filter((item) => item.id != 7);
    items = items.filter((item) => item.id != 8);
  } else {
    items = items.filter((item) => item.id != 8);
  }

  if (!perms.view_reqs) {
    items = items.filter((item) => item.id !== 11);
  }

  if (!perms.admin_reqs) {
    items = items.filter((item) => item.id !== 12);
  }

  // most viewers can see all menu items
  if (perms.view) {
    return items;
  }

  // if they can't view most things, they can view the calendar only
  if (perms.view_calendar) {
    return items.filter((item) => item.id == 6 || item.id == 1);
  }
};

export const BotHeader = (props) => {
  const { mobileMenuOpen } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(1);
  const { perms } = usePerms();

  const UserMenuItems = getMenuItems(perms.data);

  useEffect(() => {
    UserMenuItems.forEach((item) => {
      if (location.pathname.includes(item.url)) {
        setSelected(item.id);
      }
    });
  }, [UserMenuItems, location]);

  return (
    <nav
      id="toggleMobileMenu"
      className={` ${
        mobileMenuOpen ? "" : "hidden"
      } bg-white border-b border-gray-200 shadow-sm dark:bg-[#181818] lg:block dark:border-gray-800`}
    >
      <div className="px-0 lg:px-6">
        <div className="flex items-center">
          <ul className="flex flex-col mt-0 w-full text-sm font-medium lg:flex-row">
            {UserMenuItems.map((item) => {
              return (
                <NavigatorMenuItem
                  key={item.id}
                  id={item.id}
                  selected={item.id === selected}
                  onClick={() => {
                    setSelected(item.id);
                    navigate(item.url);
                  }}
                >
                  {item.title}
                </NavigatorMenuItem>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};
