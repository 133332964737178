import React, { useState, useMemo } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import Button from "@/components/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TrashIcon, PlusIcon } from "@heroicons/react/24/outline";
import { createEventFile, deleteEventFile } from "@/queries/events";
import { FormBuilder, Input, TextInput, SaveCancel, Checkbox } from "@/components/Form";
import * as yup from "yup";

const File = ({ file, deleteQuery, editable, canEditMarketing, onDeleteClick, onDeleteComplete }) => {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: (id) => {
      return deleteQuery(id);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["event", "files"]);
      onDeleteComplete(); // Call this when delete is complete
    },
  });

  const canEditFile = editable && (!file.marketing || canEditMarketing);

  const handleDeleteClick = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up to parent
    onDeleteClick(); // Call the parent's onDeleteClick function
    setDeleteConfirm(true);
  };

  const handleConfirmDelete = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up to parent
    deleteMutation.mutate(file.id);
  };

  const handleCancelDelete = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up to parent
    setDeleteConfirm(false);
    onDeleteComplete(); // Call this when delete is cancelled
  };

  let secondField = (
    <td className="p-4 border border-gray-200">
      {file.description}
      {canEditFile && (
        <TrashIcon
          className="h-6 w-6 float-right text-gray-400 hover:text-red-400 cursor-pointer"
          onClick={handleDeleteClick}
        />
      )}
    </td>
  );

  if (deleteConfirm) {
    secondField = (
      <td className="p-4 border border-gray-200 text-gray-800 bg-red-300">
        <span>Are you sure you want to delete this file?</span>
        <div className="float-right">
          <button
            className="mr-2 rounded bg-red-800 hover:bg-red-700 px-4 py-1 text-white"
            onClick={handleConfirmDelete}
          >
            Yes
          </button>
          <button
            className="rounded bg-gray-500 hover:bg-gray-400 px-4 py-1 text-white"
            onClick={handleCancelDelete}
          >
            No
          </button>
        </div>
      </td>
    );
  }

  return (
    <tr className="hover:bg-gray-100 dark:hover:bg-gray-800">
      <td
        className="cursor-pointer p-4 hover:bg-blue-100 border border-gray-200 hover:border-blue-200 w-full sm:w-1/2"
        onClick={() => window.open(file.file_url, "_blank")}
      >
        <span className="text-sm text-blue-500 hover:text-blue-700">
          {file.name}
        </span>
        {file.marketing && (
          <span className="ml-2 px-2 py-1 bg-green-100 text-green-800 rounded-full text-xs">
            Marketing
          </span>
        )}
      </td>
      {secondField}
    </tr>
  );
};

const FileUploadForm = ({ eventId, onSuccess, canEditMarketing, onCancel, marketingOnly }) => {
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("Creating file for event:", eventId);
      console.log("Form data:", data);
      return createEventFile(eventId, data);
    },
    onSuccess: async (data) => {
      console.log("File created successfully:", data);
      queryClient.invalidateQueries(["event", "files", eventId]);
      onSuccess();
    },
    onError: (error) => {
      console.error("Error creating file:", error);
    },
    onSettled: () => setIsSubmitting(false),
  });

  const addSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string(),
    marketing: yup.boolean(),
  });

  const handleSubmit = (formData) => {
    console.log("Submitting form for event:", eventId);
    console.log("Form data:", formData);

    if (!file) {
      console.error("No file selected");
      return;
    }

    const data = new FormData();
    data.append("name", formData.name);
    data.append("file", file);
    data.append("description", formData.description || "");
    data.append("marketing", marketingOnly ? true : formData.marketing || false);

    console.log("Final form data:");
    for (let [key, value] of data.entries()) {
      console.log(key, value);
    }

    setIsSubmitting(true);
    mutation.mutate(data);
  };

  return (
    <div className="py-6 min-w-full">
      <FormBuilder
        onSubmit={handleSubmit}
        schema={addSchema}
        defaultValues={{ marketing: marketingOnly }}
      >
        <Input
          name="name"
          label="Name"
          autoFocus={true}
          autoComplete="off"
        />
        <Input
          name="file"
          label="File"
          type="file"
          onChange={(event) => {
            setFile(event.target.files[0]);
          }}
        />
        <TextInput
          name="description"
          label="Description"
          placeholder="Optional description"
        />
        {!marketingOnly && (
          <Checkbox
            name="marketing"
            label="Marketing File"
          />
        )}
        <SaveCancel
          label={`Add ${marketingOnly ? 'Marketing ' : ''}File`}
          cancelAction={onCancel}
          isSubmitting={isSubmitting}
          submittingMessage="Uploading file..."
        />
      </FormBuilder>
    </div>
  );
};

const FileDisplay = ({ files, id, addURL, deleteQuery, editable, perms }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const toggleExpand = () => {
    if (!isDeleting) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleDeleteClick = () => {
    setIsDeleting(true);
  };

  const handleDeleteComplete = () => {
    setIsDeleting(false);
  };

  const canViewOnlyMarketing = perms && perms.data ? perms.data.edit_marketing : false;
  const canEditMarketing = Boolean(perms?.data?.edit_marketing && editable);

  const filteredFiles = useMemo(() => {
    if (files.isSuccess && Array.isArray(files.data)) {
      return files.data.filter(file => 
        canViewOnlyMarketing ? file.marketing : true
      );
    }
    return [];
  }, [files.isSuccess, files.data, canViewOnlyMarketing]);

  const fileCount = filteredFiles.length;

  return (
    <div className="flex flex-col mb-6">
      <div className="w-full flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold dark:text-gray-200">
          {canViewOnlyMarketing ? "Marketing Files" : "All Files"}
        </h3>
        <div className="flex items-center space-x-4">
          {editable && !showUploadForm && (
            <Button onClick={() => setShowUploadForm(true)}>
              <PlusIcon className="h-5 w-5 mr-2" />
              Add {canViewOnlyMarketing ? "Marketing " : ""}File
            </Button>
          )}
        </div>
      </div>
      
      {showUploadForm && (
        <FileUploadForm 
          eventId={id}
          onSuccess={() => {
            setShowUploadForm(false);
            setIsExpanded(true);
          }}
          onCancel={() => setShowUploadForm(false)}
          canEditMarketing={canEditMarketing}
          marketingOnly={canViewOnlyMarketing}
        />
      )}

      {!showUploadForm && (
        <div className="overflow-x-auto mt-4">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 cursor-pointer" onClick={toggleExpand}>
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-4 py-3">{isExpanded ? "Files" : ""}</th>
                <th scope="col" className="px-4 py-3 flex justify-between items-center">
                  {isExpanded ? "Description" : `${fileCount} file${fileCount !== 1 ? 's' : ''} hidden`}
                  <button onClick={(e) => { e.stopPropagation(); toggleExpand(); }}>
                    {isExpanded ? (
                      <ChevronUpIcon className="h-5 w-5 text-white" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5 text-white" />
                    )}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody className="dark:bg-white">
              {isExpanded && (
                <>
                  {files.isLoading && (
                    <tr>
                      <td colSpan="2" className="px-6 py-3 text-center text-sm text-gray-500 dark:text-gray-400">
                        Loading...
                      </td>
                    </tr>
                  )}
                  {files.isError && (
                    <tr>
                      <td colSpan="2" className="px-6 py-3 text-center text-sm text-red-500 dark:text-red-400">
                        Error loading files. Please try again later.
                      </td>
                    </tr>
                  )}
                  {files.isSuccess &&
                    filteredFiles.map((file) => (
                      <File
                        key={file.id}
                        file={file}
                        deleteQuery={deleteQuery}
                        editable={editable}
                        canEditMarketing={canEditMarketing}
                        onDeleteClick={handleDeleteClick}
                        onDeleteComplete={handleDeleteComplete}
                      />
                    ))}
                  {files.isSuccess && !filteredFiles.length && (
                    <tr>
                      <td colSpan="2" className="px-6 py-3 text-center text-sm text-gray-500 dark:text-gray-400">
                        <em>No files found</em>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
export default FileDisplay;
