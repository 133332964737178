import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { PublicLayout } from "@/components/Auth/PublicLayout";
import { ProtectedLayout } from "@/components/Auth/ProtectedLayout";
import { Dashboard } from "@/components/Dashboard";
import { EventsHome } from "@/views/events/EventsHome";
import { EventsList } from "@/views/events/EventsList";
import { EventDetail } from "@/views/events/EventDetail";
import { EventEdit } from "@/views/events/EventEdit";
import { EventEditAccounting } from "@/views/events/EventEditAccounting";
import { EventEditOffer } from "@/views/events/EventEditOffer";
import { EventAdd } from "@/views/events/EventAdd";
import { EventPeopleAdd } from "@/views/events/EventPeopleAdd";
import { ManifestAdd } from "@/views/events/ManifestAdd";
import { ManifestEdit } from "@/views/events/ManifestEdit";
import { EventLinkAdd } from "@/views/events/LinkAdd";
import { EventFileAdd } from "@/views/events/FileAdd";
import { EventImageAdd } from "@/views/events/ImageAdd";
import { EventsCalendar } from "@/views/events/EventsCalendar";
import { EventArtistSettlement } from "@/views/events/EventArtistSettlement";
import { EventCoProSettlement } from "@/views/events/EventCoProSettlement";
import { EventFinalSettlement } from "@/views/events/EventFinalSettlement";
import { EventOfferPDF } from "@/views/events/EventOfferPDF";
import { VenuesHome } from "@/views/venues/VenuesHome";
import { VenueDetail } from "@/views/venues/VenueDetail";
import { VenueEdit } from "@/views/venues/VenueEdit";
import { VenueAdd } from "@/views/venues/VenueAdd";
import { VenuePeopleAdd } from "@/views/venues/VenuePeopleAdd";
import { VenueLinkAdd } from "@/views/venues/LinkAdd";
import { VenueFileAdd } from "@/views/venues/FileAdd";
import { SeatingChartManager } from "@/views/venues/SeatingChart";
import { ArtistsHome } from "@/views/artists/ArtistsHome";
import { ArtistDetail } from "@/views/artists/ArtistDetail";
import { ArtistEdit } from "@/views/artists/ArtistEdit";
import { ArtistAdd } from "@/views/artists/ArtistAdd";
import { ArtistPeopleAdd } from "@/views/artists/ArtistPeopleAdd";
import { ArtistLinkAdd } from "@/views/artists/LinkAdd";
import { ArtistFileAdd } from "@/views/artists/FileAdd";
import { ArtistImageAdd } from "@/views/artists/ImageAdd";
import { VendorsHome } from "@/views/vendors/VendorsHome";
import { VendorDetail } from "@/views/vendors/VendorDetail";
import { VendorEdit } from "@/views/vendors/VendorEdit";
import { VendorAdd } from "@/views/vendors/VendorAdd";
import { VendorPeopleAdd } from "@/views/vendors/VendorPeopleAdd";
import { VendorLinkAdd } from "@/views/vendors/LinkAdd";
import { VendorFileAdd } from "@/views/vendors/FileAdd";
import { VendorWireInfoAdd } from "@/views/vendors/WireInfoAdd";
import { VendorArtistsAdd } from "@/views/vendors/VendorArtistsAdd";
import { PeopleHome } from "@/views/people/PeopleHome";
import { PeopleDetail } from "@/views/people/PeopleDetail";
import { PeopleAdd } from "@/views/people/PeopleAdd";
import { PeopleEdit } from "@/views/people/PeopleEdit";
import { StaffDirectory } from "@/views/people/StaffDirectory";
import { TicketsHome } from "@/views/tickets/TicketsHome";
import { LinksHome } from "@/views/links/LinksHome";
import { LinksAdd } from "@/views/links/LinksAdd";
import { LinkDetail } from "@/views/links/LinkDetail";
import { PublicLinkDetail } from "@/views/links/PublicLinkDetail";
import { MiscUI } from "@/views/misc";
import { AccountingDemo } from "@/views/AccountingDemo";
import { ReqHome } from "@/views/reqs/ReqHome";
import { ReqAdd } from "@/views/reqs/ReqAdd";
import { ReqDetail } from "@/views/reqs/ReqDetail";
import { ReqFileAdd } from "@/views/reqs/FileAdd";
import { ReqAdmin } from "@/views/reqs/ReqAdmin";
import Login from "@/components/Login";
import PasswordReset from "@/components/PasswordReset";
import PasswordResetConfirm from "@/components/PasswordResetConfirm";
import Register from "@/components/Register";
import { EventEditSettlement } from "./views/events/EventEditSettlement";
import { FTUE } from "@/components/FTUE";
import { LoginCheck } from "@/components/LoginCheck";
import { ActiveUsersDashboard } from "@/components/Dashboard/ActiveUsers";
import { EventEditAdvance } from "@/views/events/EventEditAdvance";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<PublicLayout />}>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register/:token" element={<Register />} />
        <Route
          path="/password/reset/confirm/:uidb64/:token"
          element={<PasswordResetConfirm />}
        />
        <Route path="/password/reset" element={<PasswordReset />} />
        <Route path="/view/tickets/:code" element={<PublicLinkDetail />} />
      </Route>
      <Route element={<ProtectedLayout />}>
        <Route path="/logincheck" element={<LoginCheck />} />
        <Route path="/active-users" element={<ActiveUsersDashboard />} />
        <Route path="/ftue" element={<FTUE />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/events" element={<EventsHome />} />
        <Route path="/events/list" element={<EventsList />} />
        <Route path="/events/:id" element={<EventDetail />} />
        <Route path="/events/edit/:id" element={<EventEdit />} />
        <Route
          path="/events/edit/accounting/:id"
          element={<EventEditAccounting />}
        />
        <Route path="/events/edit/offer/:id" element={<EventEditOffer />} />
        <Route
          path="/events/edit/settlement/:id"
          element={<EventEditSettlement />}
        />
        <Route path="/events/add" element={<EventAdd />} />
        <Route path="/events/manifest/add/:id" element={<ManifestAdd />} />
        <Route path="/events/manifest/edit/:id" element={<ManifestEdit />} />
        <Route path="/events/people/add/:id" element={<EventPeopleAdd />} />
        <Route path="/events/link/add/:id" element={<EventLinkAdd />} />
        <Route path="/events/file/add/:id" element={<EventFileAdd />} />
        <Route path="/events/images/add/:id" element={<EventImageAdd />} />
        <Route path="/events/advance/edit/:id" element={<EventEditAdvance />} />
        <Route path="/events/offer-pdf/:id" element={<EventOfferPDF />} />
        <Route
          path="/events/settlement/:id"
          element={<EventArtistSettlement />}
        />
        <Route
          path="/events/settlement/artist/:id"
          element={<EventArtistSettlement />}
        />
        <Route
          path="/events/settlement/copro/:id"
          element={<EventCoProSettlement />}
        />
        <Route
          path="/events/settlement/final/:id"
          element={<EventFinalSettlement />}
        />
        <Route path="/venues" element={<VenuesHome />} />
        <Route path="/venues/:id" element={<VenueDetail />} />
        <Route path="/venues/edit/:id" element={<VenueEdit />} />
        <Route path="/venues/add" element={<VenueAdd />} />
        <Route path="/venues/people/add/:id" element={<VenuePeopleAdd />} />
        <Route path="/venues/link/add/:id" element={<VenueLinkAdd />} />
        <Route path="/venues/file/add/:id" element={<VenueFileAdd />} />
        <Route path="/venues/seatingcharts" element={<SeatingChartManager />} />
        <Route path="/artists" element={<ArtistsHome />} />
        <Route path="/artists/:id" element={<ArtistDetail />} />
        <Route path="/artists/edit/:id" element={<ArtistEdit />} />
        <Route path="/artists/add" element={<ArtistAdd />} />
        <Route path="/artists/people/add/:id" element={<ArtistPeopleAdd />} />
        <Route path="/artists/link/add/:id" element={<ArtistLinkAdd />} />
        <Route path="/artists/file/add/:id" element={<ArtistFileAdd />} />
        <Route path="/artists/images/add/:id" element={<ArtistImageAdd />} />
        <Route path="/vendors" element={<VendorsHome />} />
        <Route path="/vendors/:id" element={<VendorDetail />} />
        <Route path="/vendors/edit/:id" element={<VendorEdit />} />
        <Route path="/vendors/add" element={<VendorAdd />} />
        <Route path="/vendors/people/add/:id" element={<VendorPeopleAdd />} />
        <Route path="/vendors/link/add/:id" element={<VendorLinkAdd />} />
        <Route path="/vendors/file/add/:id" element={<VendorFileAdd />} />
        <Route
          path="/vendors/wireinfo/add/:id"
          element={<VendorWireInfoAdd />}
        />
        <Route path="/vendors/artists/add/:id" element={<VendorArtistsAdd />} />
        <Route path="/people" element={<PeopleHome />} />
        <Route path="/staff" element={<StaffDirectory />} />
        <Route path="/people/:id" element={<PeopleDetail />} />
        <Route path="/people/edit/:id" element={<PeopleEdit />} />
        <Route path="/people/add" element={<PeopleAdd />} />
        <Route path="/calendar" element={<EventsCalendar />} />
        <Route path="/tickets" element={<TicketsHome />} />
        <Route path="/links" element={<LinksHome />} />
        <Route path="/links/add" element={<LinksAdd />} />
        <Route path="/links/:id" element={<LinkDetail />} />
        <Route path="/misc" element={<MiscUI />} />
        <Route path="/accounting-demo" element={<AccountingDemo />} />
        <Route path="/reqs" element={<ReqHome />} />
        <Route path="/reqs/add" element={<ReqAdd />} />
        <Route path="/reqs/:id" element={<ReqDetail />} />
        <Route path="/reqs/file/add/:id" element={<ReqFileAdd />} />
        <Route path="/reqs/admin" element={<ReqAdmin />} />
      </Route>
    </>
  )
);

export const AppRouter = () => <RouterProvider router={router} />;
