import React, { useEffect, useState, useMemo } from "react";
import { Header } from "@/components/Header";
import { TwitterHandle } from "@/components/TwitterHandle";
import { useQuery } from "@tanstack/react-query";
import { useUser, usePerms } from "@/components/Auth";
import { getPerson } from "@/queries/people";
import { getBuyerEvents } from "@/queries/events";
import { useRecordVisit } from "@/queries/visit";
import { useParams, useNavigate } from "react-router-dom";
import { PersonImageDisplay } from "@/components/People/PersonImageDisplay";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { usePageTitle } from "@/utils/pagetitle";
//import { EventTable } from "@/components/EventDisplay/EventTable";
//
import { format, isPast as isDatePast, parseISO } from 'date-fns';

import { ArrowsUpDownIcon } from '@heroicons/react/24/outline';

import { getPersonEventsRoles, } from "@/queries/people";


const EventTable = ({ events, defaultSortColumn = 'event_date', defaultSortDirection = 'asc', isPast = false }) => {
  const [sortColumn, setSortColumn] = useState(defaultSortColumn);
  const [sortDirection, setSortDirection] = useState(defaultSortDirection);
  const [filter, setFilter] = useState('');

  const isDatePast = (date) => {
    const today = new Date();
    return new Date(date).setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0);
  };

  const sortedAndFilteredEvents = useMemo(() => {
    let filteredEvents = events.filter((event) => {
      const eventDate = parseISO(event.event_date);
      // Filter based on past or future events
      return isPast ? isDatePast(eventDate) : !isDatePast(eventDate);
    });

    // Filter by search term
    filteredEvents = filteredEvents.filter((event) =>
      Object.values(event).some((value) =>
        value.toString().toLowerCase().includes(filter.toLowerCase())
      )
    );

    // Sort by the specified column and direction
    if (sortColumn) {
      filteredEvents.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (sortColumn === 'event_date') {
          // Ensure proper date sorting for the 'event_date' column
          const aDate = new Date(aValue);
          const bDate = new Date(bValue);
          return sortDirection === 'asc' ? aDate - bDate : bDate - aDate;
        }

        if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
    }

    return filteredEvents;
  }, [events, sortColumn, sortDirection, filter, isPast]);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  if (!events || events.length === 0) {
    return <p className="text-center text-gray-500 dark:text-gray-400">No events found.</p>;
  }

  return (
    <div className="overflow-x-auto">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Filter events..."
          className="w-full p-2 border rounded"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
        <thead className="bg-gray-50 dark:bg-gray-800">
          <tr>
            <th
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
              onClick={() => handleSort('event_name')}
            >
              Event {sortColumn === 'event_name' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
              onClick={() => handleSort('event_date')}
            >
              Date {sortColumn === 'event_date' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
              onClick={() => handleSort('role_name')}
            >
              Role {sortColumn === 'role_name' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700">
          {sortedAndFilteredEvents.map((event) => (
            <tr key={event.event_id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                <a className="hover:underline" target="_blank" href={`/events/${event.event_id}`}>
                  {event.event_name}
                </a>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                {format(parseISO(event.event_date), 'MMM d, yyyy')}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                {event.role_name || ''}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


export const PersonEventsSection = ({ id, isPast }) => {
  const { data: staffData, isLoading: isStaffLoading, isError: isStaffError, error: staffError } = useQuery(
    ["person", "events", id, isPast],
    () => getPersonEventsRoles(id, !isPast)
  );

  const { data: buyerData, isLoading: isBuyerLoading, isError: isBuyerError, error: buyerError } = useQuery(
    ["buyer", "events", id, isPast],
    () => getBuyerEvents(id, !isPast)
  );

  const filteredStaffEvents = useMemo(() => {
    if (!staffData || !staffData.events_roles) return [];
    return staffData.events_roles.map(item => ({
      event_id: item.event_id,
      event_name: item.event_name,
      event_date: item.event_date,
      role_name: item.role_name,
      role_id: item.role_id
    }));
  }, [staffData]);

  const filteredBuyerEvents = useMemo(() => {
    if (!buyerData || !buyerData.events) return [];
    return buyerData.events.map(item => ({
      event_id: item.event_id,
      event_name: item.event_name,
      event_date: item.date
    }));
  }, [buyerData]);

  if (isStaffLoading || isBuyerLoading) return <div className="text-center">Loading...</div>;
  if (isStaffError || isBuyerError) return <div className="text-center text-red-500">Error loading events: {staffError?.message || buyerError?.message}</div>;

  return (
    <section className="border-t border-gray-200 dark:border-gray-700">
      <div className="py-6">
        <h3 className="text-xl font-semibold mb-6 dark:text-white">
          {isPast ? 'Past Events' : 'Future Events'}:
        </h3>

        {filteredStaffEvents.length > 0 && (
          <>
            <h4 className="text-lg font-semibold mb-4 dark:text-white">Staffed:</h4>
            <EventTable
              events={filteredStaffEvents}
              defaultSortColumn="event_date"
              defaultSortDirection={isPast ? "desc" : "asc"}
              isPast={isPast}
            />
          </>
        )}

        {filteredBuyerEvents.length > 0 && (
          <>
            <h4 className="text-lg font-semibold my-4 dark:text-white">Buyer:</h4>
            <EventTable
              events={filteredBuyerEvents}
              defaultSortColumn="event_date"
              defaultSortDirection={isPast ? "desc" : "asc"}
              isPast={isPast}
            />
          </>
        )}

        {filteredStaffEvents.length === 0 && filteredBuyerEvents.length === 0 && (
          <p className="text-center text-gray-500 dark:text-gray-400">No events found.</p>
        )}
      </div>
    </section>
  );
};


export const PeopleDetail = (props) => {
  const { id } = useParams();
  const { user } = useUser();
  const { perms } = usePerms();
  const navigate = useNavigate();
  const person = useQuery(["people-detail", id], () => getPerson(id));
  const visitMutation = useRecordVisit();
  usePageTitle(
    person.isSuccess
      ? `/c${String.fromCharCode(92)}${person.data.last_name}, ${
          person.data.first_name
        }`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  // Temp section just to get a table up and going remove
//  const { data:past_events } = useQuery(["person", "events", "past", id], () =>
//    getPersonEventsRoles(id)
//  );
  //////////

  useEffect(() => {
    if (person.isSuccess) {
      visitMutation.mutate({
        itemID: person.data.id,
        itemType: "person",
        itemURL: `/people/${person.data.id}`,
      });
    }
  }, [person.isSuccess]);

  if (person.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  // console.log("LOOK HERE:", person.data);

  const fullName = `${person.data.first_name} ${person.data.last_name}`;
  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs
          links={[{ text: "People", url: "/people" }, { text: fullName }]}
        />
        <div className="py-6 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <PersonImageDisplay person={person} />
            <h2 className="text-2xl font-bold leading-7 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">
              {fullName}
            </h2>

            {perms.data.view_people_pii ? (
              <>
                {person.data.address_line_1 ||
                person.data.city ||
                person.data.state ||
                person.data.zipcode ? (
                  <>
                    {person.data.address_line_1 && (
                      <h3 className="my-2 text-gray-600">
                        {person.data.address_line_1}
                      </h3>
                    )}
                    <h3 className="my-2 text-gray-600">
                      {[
                        person.data.city,
                        person.data.state,
                        person.data.zipcode,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </h3>
                  </>
                ) : (
                  <div className="my-2 text-gray-600">No Address available</div>
                )}
              </>
            ) : (
              <div className="my-2 text-gray-600">No Address available</div>
            )}
          </div>
          <div className="mt-4 flex md:ml-4 md:mt-0 justify-start align-top">
            <button
              type="button"
              onClick={() => {
                navigate(`/people/edit/${person.data.id}`);
              }}
              className="ml-3 inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
            >
              Edit Person
            </button>
          </div>
        </div>
        <div className="py-6">
          <table>
            <tbody>
              <tr className="py-8">
                <th className="text-left mr-8 dark:text-white">Email</th>
                <td className="pl-4 dark:text-white">{person.data.email}</td>
              </tr>
              <tr>
                <th className="text-left mr-8 dark:text-white">Description</th>
                <td className="mb-4 ml-8 dark:text-white">
                  &nbsp;&nbsp;&nbsp;{person.data.description}
                </td>
              </tr>
              {perms.data.view_people_pii && (
                <>
                  <tr>
                    <th className="text-left mr-8 dark:text-white">Phone</th>
                    <td className="pl-4 dark:text-white">
                      {person.data.phone}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-left mr-8 dark:text-white">URL</th>
                    <td className="pl-4 dark:text-white">
                      <a
                        href="{person.data.website_url}"
                        className="text-blue-500 underline hover:text-blue-700"
                      >
                        {person.data.website_url}
                      </a>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      <PersonEventsSection id={id} isPast={false} />
      <PersonEventsSection id={id} isPast={true} />
      </div>
    </>
  );
};
