import { useState } from "react";
import Button from "@/components/Button";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TrashIcon } from "@heroicons/react/24/outline";

const Link = (props) => {
  const { link, deleteQuery, editable } = props;
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: (id) => {
      console.log("---> delete link");
      console.dir(id);
      return deleteQuery(id);
    },
    onSuccess: async (data) => {
      // Invalidate them all as we're not sure which one is calling us
      queryClient.invalidateQueries(["links"]);
      queryClient.invalidateQueries(["artist", "links"]);
      queryClient.invalidateQueries(["vendor", "links"]);
      queryClient.invalidateQueries(["venue", "links"]);
      queryClient.invalidateQueries(["event", "links"]);
      setDeleteConfirm(false);
    },
  });
  let secondField = (
    <td className="p-4 border border-1">
      {link.description}
      <TrashIcon
        className="h-6 w-6 float-right text-gray-400 hover:text-red-400 cursor-pointer"
        onClick={() => setDeleteConfirm(true)}
      />
    </td>
  );

  if (deleteConfirm) {
    secondField = (
      <td className="p-4 border border-1 text-gray-800 bg-red-300">
        <span>Are you sure you want to delete this link?</span>
        <div className="float-right">
          <button
            className="mr-2 rounded bg-red-800 hover:bg-red-700 px-4 py-1 text-white"
            onClick={() => deleteMutation.mutate(link.id)}
          >
            Yes
          </button>
          <button
            className="rounded bg-gray-500 hover:bg-gray-400 px-4 py-1 text-white"
            onClick={() => setDeleteConfirm(false)}
          >
            No
          </button>
        </div>
      </td>
    );
  }

  return (
    <tr>
      <td
        className="p-4 cursor-pointer hover:bg-blue-100 border border-gray-200 hover:border-blue-200 w-full sm:w-1/2"
        onClick={() => window.open(link.url, "_blank")}
      >
        <span className="text-sm text-blue-500 hover:text-blue-700">
          {link.name}
        </span>
      </td>
      {secondField}
    </tr>
  );
};

const LinkDisplay = (props) => {
  const { links, addURL, deleteQuery, editable } = props;
  const navigate = useNavigate();

  return (
    <div className="flex flex-col mb-6 rounded-lg shadow-md">
      <div className="w-full flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold dark:text-gray-200">Links</h3>
        <span>
          {editable && (
            <Button onClick={() => navigate(addURL)}>Add Link</Button>
          )}
        </span>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-4 py-3">
                Link
              </th>
              <th scope="col" className="px-4 py-3">
                Description
              </th>
            </tr>
          </thead>
          <tbody className="dark:bg-white">
            {links.isLoading && (
              <tr>
                <td
                  colSpan="2"
                  className="px-6 py-3 text-center text-sm text-gray-500 dark:text-gray-400"
                >
                  Loading...
                </td>
              </tr>
            )}
            {links.isSuccess &&
              links.data.map((link, index) => (
                <Link
                  key={index}
                  link={link}
                  deleteQuery={deleteQuery}
                  editable={editable}
                />
              ))}
            {links.isSuccess && !links.data.length && (
              <tr>
                <td
                  colSpan="2"
                  className="px-6 py-3 text-center text-sm text-gray-500 dark:text-gray-400"
                >
                  <em>No links found</em>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LinkDisplay;
