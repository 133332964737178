import dayjs from 'dayjs'

import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import { TwitterHandle } from "@/components/TwitterHandle"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { EventTable } from "@/components/EventDisplay/EventTable"
import { useRecordVisit } from "@/queries/visit"
import LinkDisplay from "@/components/Links/LinkDisplay"
import CommentDisplay from "@/components/Comments/CommentDisplay"
import FileDisplay from "@/components/Files/FileDisplay"

import {
  getLink,
} from "@/queries/links"

import { useParams, useNavigate } from 'react-router-dom'

import { BreadCrumbs } from '@/components/BreadCrumbs'
import { usePageTitle } from '@/utils/pagetitle';

export const LinkDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const link = useQuery(["link-detail", id], () => getLink(id))
  const queryClient = useQueryClient()
  const visitMutation = useRecordVisit()
  usePageTitle(link.isSuccess ? `/c${String.fromCharCode(92)}${link.data.name}` : `/c${String.fromCharCode(92)}ave_good...`);



  if (link.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs links={[{ text: "Links", url: "/links" }, { text: link.data.name }]} />

        <div className="py-6 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">
              {link.data.name}
            </h2>
          </div>
          <div className="mt-4 flex md:ml-4 md:mt-0 justify-start align-top">
            <button
              type="button"
              onClick={() => { navigate(`/links/edit/${link.data.id}`) }}
              className="ml-3 inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
            >
              Edit Link
            </button>
          </div>
        </div>

        <div className="my-4">
          <p className="my-2">{link.data.type}</p>
          <p className="my-2">
            {link.data.description}
          </p>
          <p className="my-2">
            Link to share with user: &nbsp;
            <a
              className="text-blue-400 underline hover:text-blue-500"
              href={link.data.url}
              target="_blank">
              {link.data.url}
            </a>
          </p>
        </div>
      </div>
    </>
  );
};
